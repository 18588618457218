/* eslint max-len: 0 */

import React, { useCallback, useState } from 'react';
import { useRegisterContext } from 'pages';
import { FormattedMessage } from 'react-intl';
import { AuthPageLayout } from 'components/layout';
import { ContentBox } from 'components/content';
import { Checkbox } from '@clariness/checkbox';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { Services } from 'services';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

export const RegisterUserAgreement = () => {
  const { token, email, password } = useRegisterContext();
  const { login } = Services.Auth.useLogin();

  const [hasReadAgreement, setHasReadAgreement] = useState(false);
  const [hasAgreedToAgreement, setHasAgreedToAgreement] = useState(false);
  const [shouldShowPromptMessage, setShouldShowPromptMessage] = useState(false);

  const onScrollBottomOfAgreement = useCallback(() => {
    setHasReadAgreement(true);
    setShouldShowPromptMessage(false);
  }, []);

  const { finishRegistration, isLoading } =
    Services.SiteEmployees.useFinishRegistration({
      onSuccess: (data) => {
        captureEventV2(
          Events.InvestigatorService.InvestigatorInvitationAccepted({
            InvestigatorID: data?.id ?? '',
          })
        );
        login(email, password);
      },
      onError: () => setHasAgreedToAgreement(false),
    });

  return (
    <React.Fragment>
      <AuthPageLayout.Heading>
        <FormattedMessage
          id="is.register.end_user_agreement_heading"
          defaultMessage="End User Agreement"
        />
      </AuthPageLayout.Heading>

      <ContentBox onScrollBottom={onScrollBottomOfAgreement}>
        <div style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage
            id="is.register.end_user_agreement"
            defaultMessage={`I hereby confirm that I only have access to and process that data for those studies in which I act as the PI (or as a medical assistant, such as a study nurse) and for which I have been approved to act in by the ethics committee.

I hereby confirm that all data I have access to complies with all relevant statutory data protection regulations as well as all other relevant legal and ethical guidelines.

All data forwarded to me here is at all times subjected to the obligation to maintain confidentiality.

I am aware that all personal data from interested volunteers forwarded to me here can be accepted and recorded solely for the purpose of patient recruitment, and only in order for the study to make contact with those interested volunteers.

Data from interested volunteers made available here is not to be seen or used as Source Data.`}
          />
        </div>
      </ContentBox>

      <Flex
        sx={{
          alignItems: 'center',
          gap: '5px',
          mt: '39px',
        }}
      >
        <Checkbox
          checked={hasAgreedToAgreement}
          onChange={(e) => {
            if (e.target.checked && !hasReadAgreement) {
              setShouldShowPromptMessage(true);
            } else if (e.target.checked && hasReadAgreement) {
              setShouldShowPromptMessage(false);
              setHasAgreedToAgreement(e.target.checked);
              finishRegistration({ token, email, password });
            } else {
              setHasAgreedToAgreement(e.target.checked);
            }
          }}
          data-test-id="register-agreement-checkbox"
        />

        <Text>
          <FormattedMessage
            id="is.register_end_user_agreement_checkbox"
            defaultMessage="I agree to the terms of use."
          />
        </Text>
      </Flex>

      <ShouldRender when={shouldShowPromptMessage}>
        <Text variant="error" sx={{ mt: '15px' }}>
          <FormattedMessage
            id="is.register_end_user_agreement_prompt_message"
            defaultMessage="Please scroll through the End of User Agreement first."
          />
        </Text>
      </ShouldRender>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};
