import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services/types';
import { setSiteEmployeeProductUpdatesReadAPI } from './api';

const SET_SITE_EMPLOYEE_PRODUCT_UPDATES_READ_KEY =
  '@product-updates/set-site-employee-product-updates-read';
export function useSetSiteEmployeeProductUpdatesRead(
  options?: InferMutationOptions<typeof setSiteEmployeeProductUpdatesReadAPI>
) {
  const { mutate, isLoading } = ReactQuery.useMutation(
    SET_SITE_EMPLOYEE_PRODUCT_UPDATES_READ_KEY,
    setSiteEmployeeProductUpdatesReadAPI,
    {
      ...options,
      onError: (error, data, context) => {
        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
      onSuccess: (data, variables, context) => {
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );

  return {
    setSiteEmployeeProductUpdatesRead: (siteEmployeeID: string) =>
      mutate(siteEmployeeID),
    isLoading,
  };
}
