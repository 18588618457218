import React, { PropsWithChildren, useMemo, useState } from 'react';
import { generateContext } from 'utils/context';
import { mapChildrenDeep } from 'utils/children';
import { Step } from './components';

type MultiStepProps = {
  initialActiveStep?: number;
};

type MultiStepContext = {
  activeStep: number;
  numberOfSteps: number;
  canNextStep: boolean;
  canPrevStep: boolean;
  nextStep: () => void;
  prevStep: () => void;
  gotoStep: (step: number) => void;
};

export const [useMultiStep, MultiStepProvider] =
  generateContext<MultiStepContext>();

export const MultiStep = ({
  initialActiveStep = 1,
  children,
}: PropsWithChildren<MultiStepProps>) => {
  const [activeStep, setActiveStep] = useState(initialActiveStep);

  const { visibleChildren, numberOfSteps } = useMemo(() => {
    let numberOfSteps = 0;

    const visibleChildren = mapChildrenDeep(children, (child) => {
      if (
        React.isValidElement(child) &&
        child.props.__MULTI_STEP_TYPE === 'Step'
      ) {
        numberOfSteps++;
        const shouldRender = numberOfSteps === activeStep;
        return shouldRender ? child : null;
      }

      return child;
    });

    return {
      visibleChildren,
      numberOfSteps,
    };
  }, [children, activeStep]);

  const nextStep = () => {
    setActiveStep((currentActiveStep) =>
      currentActiveStep === numberOfSteps
        ? currentActiveStep
        : currentActiveStep + 1
    );
  };

  const prevStep = () => {
    setActiveStep((currentActiveStep) =>
      currentActiveStep === 1 ? 1 : currentActiveStep - 1
    );
  };

  const gotoStep = (step: number) => {
    if (step < 1 || step > numberOfSteps) {
      throw new Error(
        `Invalid argument. You passed ${step}, but step must be between 1 and ${numberOfSteps}`
      );
    }

    setActiveStep(step);
  };

  return (
    <MultiStepProvider
      value={{
        activeStep,
        numberOfSteps,
        canNextStep: activeStep < numberOfSteps,
        canPrevStep: activeStep > 1,
        nextStep,
        prevStep,
        gotoStep,
      }}
    >
      {visibleChildren}
    </MultiStepProvider>
  );
};

MultiStep.Step = Step;
