import { serviceRequest } from 'utils/http';
import { Service } from 'common/api';

type CanRegisterBody = {
  email: string;
  token: string;
};

export function canRegisterAPI({ email, ...canRegisterBody }: CanRegisterBody) {
  return serviceRequest(() =>
    Service.siteEmployeeControllerCanRegisterEmail({
      email: email.toLowerCase(),
      ...canRegisterBody,
    })
  );
}
