import { IntlShape } from 'react-intl';
import { validateRequiredField } from 'utils/validation';

export const email = (intl: IntlShape) => ({
  ...validateRequiredField(intl),
  pattern: {
    value:
      /[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gm,
    message: intl.formatMessage({
      id: 'is.email.valid_address_message',
      defaultMessage: 'Please enter a valid email address',
    }),
  },
});
