import { calculateDistance } from 'common/units';
import { LabeledContent, LabeledRow } from 'components/content';
import { FormattedMessage, useIntl } from 'react-intl';
import { CallbackTimeslotDto, ReferralDto } from 'common/api';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from '@clariness/button';
import { CopyToClipboard, EmailIcon } from '@clariness/icon';
import { Flex } from '@clariness/flex';
import {
  displayCallbackTime,
  displayContentValueFormatted,
} from 'utils/format';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';
import { Tag } from '@clariness/tag';
import { useFeatureFlagEnabled } from 'posthog-js/react';

type PatientDetailsContactProps = {
  referral?: ReferralDto;
};

export const PatientDetailsContact = ({
  referral,
}: PatientDetailsContactProps) => {
  const callbackTimeslotSiteSelectedEnabled = useFeatureFlagEnabled(
    'CALLBACK_TIMESLOT_SITE_SELECTED_ENABLED'
  );
  const intl = useIntl();
  const { user } = Store.useUser();
  const address =
    referral?.postalCode &&
    referral.locationBasedOnPostalCode?.includes(referral.postalCode)
      ? referral.locationBasedOnPostalCode
      : `${referral?.postalCode || ''} ${referral?.locationBasedOnPostalCode || ''}`;

  return (
    <LabeledContent
      label={intl.formatMessage({
        id: 'is.patient_details_modal.contact_details_title',
        defaultMessage: 'Contact Details',
      })}
    >
      <LabeledContent.Table>
        <LabeledRow
          label={intl.formatMessage({
            id: 'is.appointment.title',
            defaultMessage: 'Scheduled Appointment Time',
          })}
        >
          <ShouldRender when={Boolean(callbackTimeslotSiteSelectedEnabled)}>
            <Flex alignItems="center" gap={3}>
              {referral?.siteCallbackTime?.systemSelected ? (
                <>
                  <FormattedMessage
                    id="is.patient_details_modal.contact_details.availability.anytime"
                    defaultMessage="anytime"
                  />
                  <Tag hideDiscardButton>
                    {intl.formatMessage({
                      id: 'is.patient_details_modal.system_selected_time',
                      defaultMessage: 'SYSTEM SELECTED',
                    })}
                  </Tag>
                </>
              ) : (
                referral?.siteCallbackTime &&
                referral?.siteCallbackTime?.status !==
                  CallbackTimeslotDto.status.PENDING &&
                displayCallbackTime(referral?.siteCallbackTime, intl)
              )}
            </Flex>
          </ShouldRender>
          <ShouldRender when={!callbackTimeslotSiteSelectedEnabled}>
            <Flex alignItems="center" gap={3}>
              {displayContentValueFormatted(
                referral?.appointmentDateTime,
                intl
              )}
            </Flex>
          </ShouldRender>
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.contact_details.patient_availability',
            defaultMessage: 'Availability',
          })}
        >
          <Flex alignItems="center" gap={3}>
            {referral?.availability}
          </Flex>
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.contact_details.telephone',
            defaultMessage: 'Telephone',
          })}
        >
          <ShouldRender
            when={Boolean(referral?.patient.phoneNumbers[0]?.phone)}
          >
            <Flex
              sx={{
                alignItems: 'center',
                gap: '11px',
              }}
            >
              <span>{referral?.patient.phoneNumbers[0]?.phone}</span>

              <CopyToClipboard
                position="top left"
                value={referral?.patient.phoneNumbers[0]?.phone || ''}
              >
                <FormattedMessage
                  id="is.patient_details_modal.contact_details.telephone_copy_to_clipboard"
                  defaultMessage="Copy telephone"
                />
              </CopyToClipboard>
            </Flex>
          </ShouldRender>
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.contact_details.email',
            defaultMessage: 'Email',
          })}
        >
          <ShouldRender when={Boolean(referral?.patient.email)}>
            <Flex
              sx={{
                alignItems: 'center',
                gap: '11px',
              }}
            >
              <span>{referral?.patient.email}</span>

              <CopyToClipboard
                position="top left"
                value={referral?.patient.email || ''}
              >
                <FormattedMessage
                  id="is.patient_details_modal.contact_details.email_address_copy_to_clipboard"
                  defaultMessage="Copy email"
                />
              </CopyToClipboard>

              <Button
                variant="secondary-exception"
                size="small"
                icon={<EmailIcon />}
                onClick={() => {
                  captureEventV2(
                    Events.InvestigatorService.InvestigatorEmailPatient({
                      InvestigatorID: user?.id ?? '',
                      PatientID: referral?.id ?? '',
                    })
                  );
                  window.location.href = `mailto:${referral?.patient.email}`;
                }}
              >
                <FormattedMessage
                  id="is.patient_details_modal.contact_details.email_address_button"
                  defaultMessage="Email"
                />
              </Button>
            </Flex>
          </ShouldRender>
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.contact_details.address',
            defaultMessage: 'Address',
          })}
        >
          {address}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.contact_details.distance',
            defaultMessage: 'Distance',
          })}
        >
          {calculateDistance(
            referral?.distanceToPatientInKM,
            referral?.language.code
          )}
        </LabeledRow>
      </LabeledContent.Table>
    </LabeledContent>
  );
};
