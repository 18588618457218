/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SiteEmployeeUpdateDto = {
    id?: string;
    firstName: string;
    lastName: string;
    position?: string;
    email: string;
    phone?: string;
    phone2?: string;
    warmTransferPhone?: string;
    speciality?: string;
    notifications?: string;
    languageCode: string;
    siteIds?: Array<string>;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    title?: SiteEmployeeUpdateDto.title;
    lastLogin?: string;
    numberOfLogins?: number;
}

export namespace SiteEmployeeUpdateDto {

    export enum title {
        PROF = 'PROF',
        PROF_DR = 'PROF_DR',
        DR = 'DR',
        MRS = 'MRS',
        MR = 'MR',
        MS = 'MS',
    }


}
