import { Auth0Error } from 'auth0-js';
import { IntlShape } from 'react-intl';

export function handleLoginError(error: Auth0Error, intl: IntlShape) {
  let errorMessage = {
    title: intl.formatMessage({
      id: 'is.login.generic_error_title',
      defaultMessage: 'Something went wrong',
    }),
    description: intl.formatMessage({
      id: 'is.login.generic_error_description',
      defaultMessage: 'Please try again later',
    }),
  };

  if (error.description === 'Wrong email or password.') {
    errorMessage = {
      title: intl.formatMessage({
        id: 'is.login.wrong_email_or_password_title',
        defaultMessage: 'Wrong credentials',
      }),
      description: intl.formatMessage({
        id: 'is.login.wrong_email_or_password_description',
        defaultMessage: 'Invalid username or password',
      }),
    };
  }

  return errorMessage;
}
