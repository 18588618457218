/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CallLogDto = {
    studySiteId?: number;
    referralId?: string;
    to?: string;
    callType?: CallLogDto.callType;
    callStatus?: CallLogDto.callStatus;
}

export namespace CallLogDto {

    export enum callType {
        OPT_IN = 'OPT_IN',
        TRAINING = 'TRAINING',
        FOLLOW_UP = 'FOLLOW_UP',
    }

    export enum callStatus {
        OUTBOUND_CALL = 'OUTBOUND_CALL',
        NOT_REACHED = 'NOT_REACHED',
    }


}
