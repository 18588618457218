import { ReferralInfoDto } from 'common/api';
import { formatDate, FormatReferralStatus } from 'utils/format';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Button } from '@clariness/button';
import { Checkbox } from '@clariness/checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import * as ReactTable from 'react-table';
import { Table } from 'components/Table';
import { Store } from 'store';
import { ReferralStatusType } from 'global/referralStatus';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { ShouldRender } from 'components/ShouldRender';
import { useFeatureFlagEnabled } from 'posthog-js/react';
// Columns accessors are related to data keys
export const referralColumns = [
  {
    id: 'selection',
    Header: ({
      getToggleAllPageRowsSelectedProps,
      onClick,
    }: ReactTable.TableInstance) => {
      const props = getToggleAllPageRowsSelectedProps();
      const { indeterminate } = props;
      return (
        <Checkbox
          {...props}
          sx={{ cursor: 'pointer' }}
          indeterminate={indeterminate ? indeterminate.toString() : undefined}
          onClick={(e) => {
            if (onClick) onClick(e);
          }}
        />
      );
    },
    Cell: ({ row, onClick }: ReactTable.TableInstance) => {
      const props = row.getToggleRowSelectedProps();
      const { indeterminate } = props;
      return (
        <Checkbox
          {...props}
          key={row.original.patientInternalId}
          sx={{ cursor: 'pointer' }}
          indeterminate={indeterminate ? indeterminate.toString() : undefined}
          onClick={() => onClick(row)}
          data-test-id="referral-table-checkbox"
        />
      );
    },
  },
  {
    id: 'study',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.study',
              defaultMessage: 'Study',
            }) as any
          }
        >
          <FormattedMessage
            id="is.patient_details_modal.basic_details.study"
            defaultMessage="Study"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = `${row?.original.trackingId} ${row.original?.studyName}`;
      return (
        <Table.CellTooltip
          trigger={value.substring(0, 15)}
          triggerStyles={{
            display: 'initial',
          }}
        >
          {value}
        </Table.CellTooltip>
      );
    },
  },
  {
    id: 'patientInternalId',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.referrals_table.patient_id_column',
              defaultMessage: 'Patient ID',
            }) as any
          }
        >
          <FormattedMessage
            id="is.referrals_table.patient_id_column"
            defaultMessage="Patient ID"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = row?.original?.patientInternalId;
      return <Box>{value}</Box>;
    },
  },
  {
    id: 'patientName',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.patient_name.title',
              defaultMessage: 'Patient Name',
            }) as any
          }
        >
          <FormattedMessage
            id="is.patient_name.title"
            defaultMessage="Patient Name"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = `${row?.original.firstName} ${row.original?.lastName}`;
      const recontactEnabled = useFeatureFlagEnabled(
        'REACT_APP_MARK_PATIENT_ENABLED'
      );
      return (
        <Flex>
          {value}

          <ShouldRender
            when={Boolean(row?.original.recontactMark) && recontactEnabled}
          >
            <Box marginLeft="24px">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.1672 4.75C7.87988 4.75 7.60433 4.86414 
              7.40116 5.0673C7.198 5.27046 7.08386 5.54602
               7.08386 5.83333V22.071L13.274 17.6495C13.7086
                17.3391 14.2925 17.3391 14.7271 17.6495L20.9172 
                22.071V5.83333C20.9172 5.54602 20.8031 5.27047 
                20.5999 5.0673C20.3967 4.86414 20.1212 4.75 19.8339 
                4.75H8.1672ZM5.6334 3.29953C6.3054 2.62753 7.21684 
                2.25 8.1672 2.25H19.8339C20.7842 2.25 21.6957 
                2.62753 22.3677 3.29953C23.0397 3.97154 23.4172 
                4.88297 23.4172 5.83333V24.5C23.4172 24.9682 23.1555
                 25.3972 22.7392 25.6115C22.3228 25.8257 21.8217 
                 25.7893 21.4407 25.5172L14.0005 20.2028L6.56041 
                 25.5172C6.17939 25.7893 5.67822 25.8257 5.26189 
                 25.6115C4.84555 25.3972 4.58386 24.9682 4.58386 
                 24.5V5.83333C4.58386 4.88297 4.96139 3.97154 
                 5.6334 3.29953Z"
                  fill="#3E3E3E"
                />
              </svg>
            </Box>
          </ShouldRender>
        </Flex>
      );
    },
  },
  {
    id: 'referralDate',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.referrals_table.referral_date_column',
              defaultMessage: 'Referral date',
            }) as any
          }
        >
          <FormattedMessage
            id="is.referrals_table.referral_date_column"
            defaultMessage="Referral date"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const intl = useIntl();
      const value = row?.original.referralDate;
      return <Box>{formatDate(intl.formatDate(new Date(value)))}</Box>;
    },
  },
  {
    id: 'lastUpdate',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.last_update.title',
              defaultMessage: 'Last Update',
            }) as any
          }
        >
          <FormattedMessage
            id="is.last_update.title"
            defaultMessage="Last Update"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const intl = useIntl();
      const value = row?.original.lastUpdate;
      return <Box>{formatDate(intl.formatDate(new Date(value)))}</Box>;
    },
  },
  {
    id: 'patientDetails',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.referrals_table.patient_details_column',
              defaultMessage: 'Patient details',
            }) as any
          }
        >
          <FormattedMessage
            id="is.referrals_table.patient_details_column"
            defaultMessage="Patient details"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const referral = row.original;

      const { redirect } = useRedirect();

      const { user } = Store.useUser();

      return (
        <Button
          variant="tertiary"
          size="medium"
          sx={{
            textDecoration: 'underline',
            fontSize: '1.125rem',
            fontFamily: 'Noto Sans',
            fontWeight: '400',
            width: '164px',
          }}
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorViewedPatientProfile({
                InvestigatorID: user?.id ?? '',
              })
            );

            redirect(Routing.REFERRAL_DETAILS.getPath(referral.id), {
              referralId: referral.id,
            });
          }}
          data-test-id="patient-details-button"
        >
          <FormattedMessage
            id="is.contact_center.table.button_details"
            defaultMessage="Details"
          />
        </Button>
      );
    },
  },
  {
    id: 'status',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={
            intl.formatMessage({
              id: 'is.referrals_table.status_column',
              defaultMessage: 'Status',
            }) as any
          }
        >
          <FormattedMessage
            id="is.referrals_table.status_column"
            defaultMessage="Status"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.Cell) => {
      const { openModal } = Store.useModal();
      const {
        id,
        status,
        appointmentDateTime,
        consentedDate,
        isVisited,
        referralDate,
      } = row.original as {
        id: string;
        status: ReferralStatusType;
        appointmentDateTime?: string;
        consentedDate?: string;
        isVisited: boolean;
        referralDate: string;
      };

      return (
        <Box
          onClick={() =>
            openModal({
              modalType: 'UpdatePatientStatus',
              modalProps: {
                id,
                status,
                appointmentDateTime,
                currentConsentedDate: consentedDate,
                isVisited,
                referralDate,
              },
            })
          }
          sx={{
            cursor: 'pointer',
          }}
        >
          {FormatReferralStatus(status, appointmentDateTime)}
        </Box>
      );
    },
  },
];

export const mapReferralsToAccessor = (referral: ReferralInfoDto) => ({
  id: referral.id,
  studyName: referral.studyName,
  trackingId: referral.trackingId,
  studyId: referral.studyId,
  appointmentDateTime: referral.appointmentDateTime,
  patientInternalId: referral.patient.internalId,
  firstName: referral.patient.firstName,
  lastName: referral.patient.lastName,
  referralDate: referral.referralDate,
  status: referral.status,
  patientLanguage: referral.patient.languageCode,
  consentedDate: referral.consentedDate,
  isVisited: referral.isVisited,
  lastUpdate: referral.lastUpdate,
  recontactMark: referral.recontactMark,
});

export type Referral = ReturnType<typeof mapReferralsToAccessor>;
