import React from 'react';
import { Text, TextProps } from '@clariness/text';

type AccountPageLayoutHeadingProps = TextProps;

export const AccountPageLayoutHeading = ({
  children,
  sx,
  ...props
}: AccountPageLayoutHeadingProps) => {
  return (
    <Text
      as="h1"
      variant="h1"
      sx={{
        mb: '20px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};
