import { useState, useEffect } from 'react';
import { Text } from '@clariness/clarikit-react';
import * as ReactIntl from 'react-intl';
import { Button } from '@clariness/button';
import { Box } from '@clariness/box';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import ics from 'ics';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Svg } from './svg';
import { Checkbox } from '@clariness/checkbox';
import { StudySitePerformanceDto } from 'common/api/models/StudySitePerformanceDto';
import { ReferralDto } from 'common/api';
import { downloadIcsFile } from 'utils/document';
import { Services } from 'services';
import { Store } from 'store';
import {themes} from '@clariness/clarikit-tokens';

interface Props {
  performanceData: StudySitePerformanceDto;
  referral?: ReferralDto
  appointmentScheduleDate?: Date,
}

export const AppointmentScheduledModal = ({ performanceData, referral, appointmentScheduleDate }: Props) => {

  const intl = ReactIntl.useIntl();
  const { user } = Store.useUser();
  const { showNotification } = Store.useNotifications();

  const { updateShowNotification } =
    Services.SiteEmployees.useUpdateShowNotification(
      {
        onSuccess: (data) => {
          setShowNotifications(data.showNotification as boolean)
        }
      },
    );

  const [showNotifications, setShowNotifications] = useState(true);
  const {color, space} = themes.light;

  useEffect(() => {
    if (user?.id) {
      captureEventV2(
        Events.InvestigatorService.InvestigatorViewedPerformanceNotification({
          InvestigatorID: user?.id,
          NotificationType: 'APPOINTMENT_SCHEDULED',
        })
      );
    }
  }, []);

  const handleDownloadICSfile = () => {
    if (referral && appointmentScheduleDate) {
      const appointmentHour = +appointmentScheduleDate
        .toLocaleTimeString(referral?.patient?.languageCode, {
          timeZone: referral?.site?.timeZone?.zoneName,
          hour: '2-digit',
          hour12: false,
        })
        .replace(/\D/g, '');

      const appointmentMinute = +appointmentScheduleDate
        .toLocaleTimeString(referral?.patient?.languageCode, {
          timeZone: referral?.site?.timeZone?.zoneName,
          minute: '2-digit',
          hour12: false,
        })
        .replace(/\D/g, '');

      const appointmentYear = appointmentScheduleDate.getFullYear();
      const appointmentMonth = appointmentScheduleDate.getMonth() + 1;
      const appointmentDay = appointmentScheduleDate.getDate();

      const event = {
        start: [
          appointmentYear,
          appointmentMonth,
          appointmentDay,
          appointmentHour,
          appointmentMinute,
        ] as ics.DateArray,
        title: `${referral.study.name}, ${referral.site?.name}`,
        location: referral.site?.address,
        organizer: { name: referral.site?.name, email: referral.site?.email },
        attendees: [
          {
            name: `${referral.patient.firstName} ${referral.patient.lastName}`,
            email: referral.patient.email,
          },
        ],
        startOutputType: 'local' as 'local' | 'utc',
        startInputType: 'local' as 'local' | 'utc',
        productId: '-//Google Inc//Google Calendar 70.9054//EN',
        duration: { hours: 1, minutes: 0 },
      };
      downloadIcsFile(event, 'calendar')
        .then(() => {
          if (user?.id) {
            captureEventV2(
              Events.InvestigatorService.InvestigatorDownloadedCalendarFile({
                InvestigatorID: user?.id,
                AddToCalendarButtonClicked: true,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          showNotification({
            variant: 'error',
            description: intl.formatMessage({
              id: 'is.contact_center.table.details_navigate_error',
              defaultMessage:
                'Something went wrong. Please contact support.',
            }),
          })
        });
    }
  };

  const handleUpdateShowNotification = (value: boolean) => {
    if (user?.id) {
      updateShowNotification({
        siteEmployeeId: user?.id,
        showNotification: value,
      });
    };
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        width: '360px',
        marginTop: '16px',
      }}
    >
      <Svg />

      <Box marginTop="16px" marginBottom="16px">
        <Text as='h1' variant='headingXs' fontWeight='bold'>
          <FormattedMessage
            id="is.update_referral_status.contacted_appointment_scheduled.title"
            defaultMessage="Appointment Scheduled"
          />!
        </Text>
      </Box>
      

      <Text as='p'>
        <FormattedMessage
          id="is.update_referral_status.appointment_scheduled_modal.title"
          defaultMessage="You have successfully scheduled an appointment!"
        />
      </Text>

      <Box 
        sx={{
          width: '100%',
          margin: '16px 0'
        }}
      >
        <Button
          variant="tertiary"
          size="medium"
          sx={{
            textDecoration: 'underline',
            color: `${color['color-green-quaternary']}`
          }}
          onClick={handleDownloadICSfile}
        >
          <FormattedMessage
            id="is.update_referral_status.appointment_scheduled_modal.add_to_calendar"
            defaultMessage="Add it to your calendar"
          />
        </Button>
      </Box>

      <Text
        as="p"
      >
        <Text as="span" fontWeight='bold' color="color-green-primary">
          <FormattedMessage
            id="is.update_referral_status.appointment_scheduled_modal.scheduling_rate"
            defaultMessage={`The current scheduling rate for your site is {schedulingRate}%.`}
            values={{
              schedulingRate: ((performanceData.totalScheduled / performanceData.totalReferred) * 100).toFixed(1)
            }}
          />
        </Text>
        <FormattedMessage
          id="is.update_referral_status.appointment_scheduled_modal.description"
          defaultMessage=" You are one step closer to supporting the patient's journey. Thank you for your dedication!"
        />
        <br />
      </Text>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: `${space['space-24']}`
        }}
      >
        <Checkbox
          checked={!showNotifications}
          onChange={(e) => handleUpdateShowNotification(!e.target.checked)}
          data-test-id="clinlife-notification-checkbox"
          sx={{
            margin: '4px 16px 0 0'
          }}
        />
        <Text as='span' variant='bodySm'>
          <FormattedMessage
            id="is.update_referral_status.modal.notification_checkbox"
            defaultMessage="I do not want to see any notifications from ClinLife anymore."
          />
        </Text>
      </Box>
    </Flex>
  );
};
