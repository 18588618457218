import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { downloadFile } from 'utils/document';
import { getPdf } from './api';

const GET_PDF_KEY = '@referrals/get-pdf';

export function useGetPdf() {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading } = ReactQuery.useMutation(GET_PDF_KEY, getPdf, {
    onError: () => {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.get_referral_pdf',
          defaultMessage:
            'Unfortunately, something failed when exporting the PDF. Please, try again.',
        }),
      });
    },
    onSuccess: (data: any, variables) => {
      const blobUrl = URL.createObjectURL(data);
      downloadFile(
        blobUrl,
        `${variables?.firstName}-${variables?.lastName}.pdf`
      );
    },
  });

  return {
    getPdf: mutate,
    isLoading,
  };
}
