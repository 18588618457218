import { InferMutationOptions } from 'services';
import { sendInvitationLinkEmailAPI } from 'services/site-employees/send-invitation-link-email/api';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import * as ReactQuery from 'react-query';

const SEND_INVITATION_LINK_EMAIL_KEY =
  '@site-employees/send-invitation-link-email';

export function useSendInvitationLinkEmail(
  options?: InferMutationOptions<typeof sendInvitationLinkEmailAPI>
) {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading, isSuccess } = ReactQuery.useMutation(
    SEND_INVITATION_LINK_EMAIL_KEY,
    sendInvitationLinkEmailAPI,
    {
      ...options,
      onError: (error, variables, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.send_invitation_link_email_error',
            defaultMessage:
              'Unfortunately, an error occurred while we tried to send you a new invitation link email.',
          }),
        });

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },

      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.send_invitation_link_email.success_notification_message',
            defaultMessage:
              "We've sent a new invitation link, please check your email.",
          }),
        });

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );

  return {
    sendInvitationLinkEmail: (registrationToken: string) =>
      mutate({ registrationToken }),
    isLoading,
    isSuccess,
  };
}
