import React from 'react';
import { TabMenu } from '@clariness/tab-menu';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { useMultiStep } from 'components/MultiStep';
import { FormattedMessage } from 'react-intl';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';

interface Props {
  patientName: string;
  patientID?: number;
  studyID?: string;
  siteID?: string;
}

export const PatientDetailsTabMenu: React.FC<Props> = ({
  patientName,
  patientID,
  siteID,
  studyID,
}: Props) => {
  const { activeStep, gotoStep } = useMultiStep();

  const { user } = Store.useUser();
  return (
    <Flex justifyContent="space-between" marginBottom="16px">
      <Text variant="h3">
        {patientName} ({patientID})
      </Text>
      <TabMenu>
        <TabMenu.Tab
          isActive={activeStep === 1}
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorViewedPatientProfile({
                InvestigatorID: user?.id ?? '',
              })
            );
            gotoStep(1);
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.profile_tab"
            defaultMessage="Profile"
          />
        </TabMenu.Tab>

        <TabMenu.Tab
          isActive={activeStep === 2}
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorCheckedScreener({
                InvestigatorID: user?.id ?? '',
                SiteID: siteID || '',
                StudyID: studyID || '',
              })
            );
            gotoStep(2);
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.screener_tab"
            defaultMessage="Screener"
          />
        </TabMenu.Tab>

        <TabMenu.Tab
          isActive={activeStep === 3}
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorViewedPatientActivity({
                InvestigatorID: user?.id ?? '',
              })
            );
            gotoStep(3);
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.activity_tab"
            defaultMessage="Activity"
          />
        </TabMenu.Tab>
      </TabMenu>
    </Flex>
  );
};
