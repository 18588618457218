import React, { useEffect, useMemo } from 'react';
import * as ReactIntl from 'react-intl';
import { Services } from 'services';
import { Store } from 'store';
import { fallbackLocale, SupportedCountries } from 'global/supportedCountries';
import { changeUrlLocale, getUrlLocale } from 'utils/routing';

export const IntlProvider: React.FC = ({ children }) => {
  const { translations } = Services.Translations.useGetTranslations();

  const { user } = Store.useUser();

  const userLocale = useMemo(() => {
    const defaultLocale = getUrlLocale() || fallbackLocale;
    if (user?.languageCode) {
      return (
        SupportedCountries.find((locale) =>
          locale.includes(user.languageCode || '')
        ) || defaultLocale
      );
    }

    return defaultLocale;
  }, [user?.languageCode]);

  useEffect(() => {
    const currentLocale = getUrlLocale();
    if (currentLocale && currentLocale !== userLocale) {
      changeUrlLocale(userLocale);
    }
  }, [userLocale]);

  useEffect(() => {
    document.documentElement.lang = userLocale;
  }, [userLocale]);

  return (
    <ReactIntl.IntlProvider
      locale={userLocale}
      messages={translations ? translations[userLocale] : {}}
      onError={(err) => {
        // For Xhosa number-format throws MISSING_DATA error, fallback to en
        if (err.code === 'MISSING_TRANSLATION' || err.code === 'MISSING_DATA') {
          return;
        }
        throw err;
      }}
    >
      {children}
    </ReactIntl.IntlProvider>
  );
};
