import { serviceRequest } from 'utils/http';
import { Service } from 'common/api';

type FinishRegistrationAPIBody = {
  token: string;
  email: string;
  password: string;
};

export function finishRegistrationAPI(requestBody: FinishRegistrationAPIBody) {
  return serviceRequest(() =>
    Service.siteEmployeeControllerFinishRegistration(requestBody)
  );
}
