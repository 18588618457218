import { LabeledContent, LabeledRow } from 'components/content';
import { formatDate, FormatReferralStatus } from 'utils/format';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReferralDto } from 'common/api';
import { useFeatureFlagEnabled } from 'posthog-js/react';

type PatientDetailsBasicProps = {
  referral: ReferralDto;
};

export const PatientDetailsBasic = ({ referral }: PatientDetailsBasicProps) => {
  const intl = useIntl();
  const siterxOptinEnabled = useFeatureFlagEnabled('SITERX_OPTIN_ENABLED');

  return (
    <LabeledContent
      label={intl.formatMessage({
        id: 'is.patient_details_modal.basic_details_title',
        defaultMessage: 'Basic Details',
      })}
    >
      <LabeledContent.Table>
        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.patient_id',
            defaultMessage: 'Patient ID',
          })}
        >
          {referral?.patient.internalId}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.first_name',
            defaultMessage: 'First Name',
          })}
        >
          {referral?.patient.firstName}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.last_name',
            defaultMessage: 'Last Name',
          })}
        >
          {referral?.patient.lastName}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.gender',
            defaultMessage: 'Gender',
          })}
        >
          {referral?.gender === ReferralDto.gender.M
            ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.male',
                defaultMessage: 'Male',
              })
            : ''}
          {referral?.gender === ReferralDto.gender.F
            ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.female',
                defaultMessage: 'Female',
              })
            : ''}
          {referral?.gender === ReferralDto.gender.U
            ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.other',
                defaultMessage: 'Other',
              })
            : ''}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_status.title',
            defaultMessage: 'Patient Status',
          })}
        >
          {referral?.status &&
            FormatReferralStatus(referral.status, referral.appointmentDateTime)}
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.age',
            defaultMessage: 'Age',
          })}
        >
          <ShouldRender when={Boolean(referral?.ageAtTimeOfRegister)}>
            <FormattedMessage
              id="is.patient_details_modal.basic_details.age_clarification"
              defaultMessage="{ age } (at point of registration)"
              values={{ age: referral?.ageAtTimeOfRegister }}
            />
          </ShouldRender>
        </LabeledRow>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.registration_date',
            defaultMessage: 'Registered on',
          })}
        >
          {formatDate(
            intl.formatDate(
              referral &&
                new Date(
                  referral.registrationDate
                    ? referral.registrationDate
                    : referral.referralDate
                )
            )
          )}
        </LabeledRow>

        <ShouldRender
          when={Boolean(
            siterxOptinEnabled &&
              referral?.patient.languageCode === 'en-US'
          )}
        >
          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.MMR_status',
              defaultMessage: 'MRR status',
            })}
          >
            <ShouldRender when={Boolean(referral.siterxStatus)}>
              {referral.siterxStatus}
            </ShouldRender>
          </LabeledRow>
        </ShouldRender>

        <LabeledRow
          label={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.language',
            defaultMessage: 'Language',
          })}
        >
          {referral?.language.name}
        </LabeledRow>
      </LabeledContent.Table>
    </LabeledContent>
  );
};
