import { useEffect } from 'react';
import { Flex } from '@clariness/flex';
import { Services } from 'services';
import { PageLoader } from 'components/loaders';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';
import {
  SearchDropdown,
  SearchDropdownOptionInterface,
} from '@clariness/search-dropdown';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const ReferralTableStudies = () => {
  const {
    setFilterOptions,
    filterTags,
    setFilterTags,
    localFilterOptions,
    setLocalFilterOptions,
    isFilterCleared,
  } = Store.useHomePageStore();

  const intl = useIntl();

  const { studies, isLoading } = Services.Studies.useGetUserStudies();

  const { user } = Store.useUser();

  useEffect(() => {
    if (studies && studies?.length > 0) {
      setFilterOptions((s) => ({
        ...s,
        studyIds: studies.map((s) => s.id as string),
      }));
    }
  }, [studies, setFilterOptions]);

  const hasLocalFilterOptions =
    localFilterOptions?.statuses?.length ||
    localFilterOptions?.patientName ||
    localFilterOptions?.selectedStudyIds ||
    isFilterCleared;

  useEffect(() => {
    if (!hasLocalFilterOptions) {
      setFilterOptions((s) => ({
        ...s,
        statuses: [
          ReferralStatus.NEW_REFERRAL,
          ReferralStatus.ATTEMPTING_TO_CONTACT,
          ReferralStatus.CONTACTED,
          ReferralStatus.APPOINTMENT_SCHEDULED,
          ReferralStatus.CONSENTED,
          ReferralStatus.WAITING_FOR_INFORMATION,
        ],
      }));
    }
  }, []);

  function onSelectStudy(selectedStudy: SearchDropdownOptionInterface) {
    if (selectedStudy.isChecked) {
      const selectedStudyIds = localFilterOptions?.selectedStudyIds || [];
      setLocalFilterOptions({
        ...localFilterOptions,
        selectedStudyIds: [...selectedStudyIds, selectedStudy.id],
      });

      const selectedStudies = filterTags?.selectedStudies || [];
      setFilterTags({
        ...filterTags,
        selectedStudies: [
          ...selectedStudies,
          {
            id: selectedStudy.id,
            name: selectedStudy.value,
          },
        ],
      });
    } else {
      const selectedStudyIds = localFilterOptions?.selectedStudyIds?.filter(
        (id: string) => !_.isEqual(id, selectedStudy.id)
      );
      setLocalFilterOptions({ ...localFilterOptions, selectedStudyIds });

      const selectedStudies = filterTags?.selectedStudies?.filter(
        (study) => !_.isEqual(study.id, selectedStudy.id)
      );
      setFilterTags({ ...filterTags, selectedStudies });
    }
    captureEventV2(
      Events.InvestigatorService.InvestigatorChangeStudyFilter({
        InvestigatorID: user?.id ?? '',
      })
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  const studyDropdownOptions: SearchDropdownOptionInterface[] = studies?.length
    ? studies.map((s) => ({
        id: s.id as string,
        label: s.name,
        value: s.name,
      }))
    : [];

  return (
    <Flex
      sx={{
        flexShrink: 0,
        alignItems: 'center',
        gap: '10px',
        fontSize: '18px',
        fontFamily: 'Noto Sans',
      }}
    >
      {!isLoading ? (
        <SearchDropdown
          dropDownOptionList={studyDropdownOptions}
          multiSelect
          onChange={(study) =>
            onSelectStudy(study as SearchDropdownOptionInterface)
          }
          showTooltip
          maxOptionsToShow={5}
          checkedIds={localFilterOptions?.selectedStudyIds}
          placeholder={
            localFilterOptions && localFilterOptions?.selectedStudyIds
              ? `${localFilterOptions?.selectedStudyIds?.length} ${intl.formatMessage(
                  {
                    id: 'is.main_page.referrals_table_label.selected_referrals',
                    defaultMessage: 'Selected',
                  }
                )}`
              : intl.formatMessage({
                  id: 'is.main_page.referrals_table_label.select',
                  defaultMessage: 'Select',
                })
          }
        />
      ) : (
        <PageLoader />
      )}
    </Flex>
  );
};
