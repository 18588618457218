import React from 'react';
import { Services } from 'services';
import { Box } from '@clariness/box';
import { Text } from '@clariness/text';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { formatDate, displayContentValue } from 'utils/format';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServiceError } from 'components/ServiceError';
import { ReferralActivityDto } from 'common/api';
import { usePatientDetailsContext } from '../../ReferralDetails';
import { displayActivityEvent } from './displayActivityEvent';

export const PatientDetailsActivity = () => {
  const { currentReferral } = usePatientDetailsContext();

  const { activity, isLoading, isError, refetch } =
    Services.Referrals.useGetReferralActivity(currentReferral?.id);

  const intl = useIntl();

  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.activity.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's activity."
        />
      </ServiceError>
    );
  }

  const getActivityEventCreatorName = (activityEvent: ReferralActivityDto) => {
    if (activityEvent.creatorEmail) {
      return displayContentValue(activityEvent.creatorEmail);
    }
    if (!activityEvent.createdBy?.startsWith('auth0')) {
      return displayContentValue(activityEvent.createdBy?.split('|')[1]);
    }
    return 'NA';
  };

  return (
    <React.Fragment>
      <Box
        as="table"
        sx={{
          width: '100%',
          height: 'fit-content',

          '& th': {
            textAlign: 'left',
          },

          '& th:not(:last-child), td:not(:last-child)': {},

          '& td': {
            pt: '10px',
            pb: '16px',
            color: '#535A5F',
          },
        }}
      >
        <Box as="tbody">
          {activity?.referralActivities?.map((activityEvent) => (
            <tr key={activityEvent.id}>
              <td
                width="100px"
                style={{
                  alignContent: 'baseline',
                }}
              >
                <Text variant="smallBold">
                  {formatDate(
                    intl.formatDate(
                      activityEvent.createdAt &&
                        new Date(activityEvent.createdAt)
                    )
                  )}
                </Text>
              </td>

              <td>
                <Text variant="small">
                  {displayActivityEvent(activityEvent.event, {
                    note: activityEvent.note,
                    referralStatus: activityEvent.referralStatus,
                    scheduledDate: activityEvent.appointmentDateTime,
                    timeZone: activity.timeZone,
                  })}
                </Text>
              </td>

              <td
                style={{
                  textAlign: 'right',
                }}
              >
                <Text variant="small">
                  {getActivityEventCreatorName(activityEvent)}
                </Text>
              </td>
            </tr>
          ))}
        </Box>
      </Box>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};
