/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuditHistoryLog = {
    action: AuditHistoryLog.action;
    loggedAt: string;
    user: string;
    version: number;
    data?: any;
}

export namespace AuditHistoryLog {

    export enum action {
        INSERT = 'INSERT',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE',
        MESSAGE_BUS = 'MESSAGE_BUS',
    }


}
