import posthog from 'posthog-js';
import { addToEventQueue } from './eventQueue';

export function captureEventV2<T extends { EventName?: string }>({
  EventName,
  ...properties
}: T): void {
  if (posthog.has_opted_out_capturing()) {
    addToEventQueue({ eventName: EventName, properties });
  } else {
    posthog.capture(EventName ?? '', properties);
  }
}
