/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Referral } from './Referral';

export type CcActivityEventDto = {
    eventType: CcActivityEventDto.eventType;
    referral: Referral;
    creatorEmail: string;
    callStatus?: CcActivityEventDto.callStatus;
    recipient: string;
    subject: string;
    body: string;
    callDuration: string;
    createdBy: string;
    createdAt: string;
    fromSite?: string;
    toSite?: string;
    startDate?: string;
    updateBy?: string;
    systemSelected?: boolean;
    callSid?: string;
    recordingSid?: string;
    recontactMark?: boolean;
    consentType: string;
}

export namespace CcActivityEventDto {

    export enum eventType {
        ALL = 'ALL',
        INBOUND_CALL = 'INBOUND_CALL',
        OUTBOUND_CALL = 'OUTBOUND_CALL',
        INBOUND_MESSAGE = 'INBOUND_MESSAGE',
        OUTBOUND_MESSAGE = 'OUTBOUND_MESSAGE',
        EMAIL = 'EMAIL',
        NOTE = 'NOTE',
        STATUS_CHANGE = 'STATUS_CHANGE',
        FORWARD_TO_SITE = 'FORWARD_TO_SITE',
        CALL_RECORDING = 'CALL_RECORDING',
        RECONTACT_MARK = 'RECONTACT_MARK',
        DEACTIVATE_CALLBACKTIME = 'DEACTIVATE_CALLBACKTIME',
    }

    export enum callStatus {
        OUTBOUND_CALL = 'OUTBOUND_CALL',
        NOT_REACHED = 'NOT_REACHED',
    }


}
