import React, { useEffect } from 'react';
import * as ReactIntl from 'react-intl';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { ProtocolInfoDto, ProtocolDto } from 'common/api';
import { LabeledRadioButton } from '@clariness/labeled-radio-button';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { DatePickerField } from '@clariness/date-picker-field';
import { formatDate } from 'utils/format';
import { isDate } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { ThemeUIStyleObject } from 'theme-ui';
import { ShouldRender } from 'components/ShouldRender';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';

export const UpdateProtocol: React.FC = () => {
  const intl = ReactIntl.useIntl();

  const {
    setConsentedDate,
    consentedDate,
    setSelectedStatus,
    selectedProtocol,
    protocolsData,
    setSelectedProtocol,
  } = useUpdatePatientStatusContext();

  useEffect(() => {
    if (
      ((!protocolsData?.protocolList ||
        protocolsData?.protocolList.length === 0) &&
        consentedDate) ||
      (protocolsData?.protocolList &&
        protocolsData?.protocolList.length > 0 &&
        consentedDate &&
        selectedProtocol?.id)
    ) {
      setSelectedStatus('CONSENTED');
    }
  }, [
    consentedDate,
    selectedProtocol,
    setSelectedStatus,
    protocolsData?.protocolList,
  ]);

  const inputLabelStyle: React.CSSProperties = {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontFamily: 'Noto Sans',
    color: 'var(--theme-ui-colors-text1)',
    margin: '2px 0',
  };

  const datepickerStyle: ThemeUIStyleObject = {
    '.react-datepicker__input-container > div': { gap: 0, padding: 0 },
    '.react-datepicker-popper': {
      zIndex: 100,
      background: 'var(--theme-ui-colors-hgl7)',
    },
    '.react-datepicker__input-container > div > span:nth-of-type(2)': {
      margin: '0 !important',
    },
    '.react-datepicker__input-container > div > span:first-of-type':
      inputLabelStyle as ThemeUIStyleObject,
    '.react-datepicker__input-container > div > span:nth-of-type(2) > input': {
      borderColor: 'var(--theme-ui-colors-text4)',
    },
  };

  return (
    <UpdateStatusLayout heading="">
      <ShouldRender
        when={Boolean(
          protocolsData?.protocolList && protocolsData?.protocolList.length > 0
        )}
      >
        <ShouldRender
          when={Boolean(
            protocolsData?.qualifiedStudyProtocols &&
              protocolsData?.qualifiedStudyProtocols?.length > 0
          )}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              border: '2px solid #296510',
              padding: '16px 0px 16px 5px',
              borderRadius: '8px',
              backgroundColor: 'primary-2',
              gap: '2',
            }}
          >
            <Text variant="pBold">
              <FormattedMessage
                id="is.qualified.study.protocols.message"
                defaultMessage="Based on screener answers eligible for:"
              />
            </Text>
            <Text variant="small">
              {protocolsData?.qualifiedStudyProtocols
                ?.map((protocol: ProtocolDto) => ` ${protocol.name}`)
                .join(',')}
            </Text>
          </Flex>
        </ShouldRender>

        <Flex
          sx={{
            flexDirection: 'column',
          }}
          gap={3}
        >
          <Text variant="p">
            <FormattedMessage
              id="is.update_patient_status_modal.protocol"
              defaultMessage="Protocol:"
            />
          </Text>

          {protocolsData?.protocolList &&
            protocolsData.protocolList.map((protocol: ProtocolInfoDto) => (
              <Box
                key={protocol.id}
                sx={{
                  marginBottom: '8px !important',
                }}
              >
                <LabeledRadioButton
                  label={`${protocol.internalId || '...'} - ${protocol.name}`}
                  id={protocol.id.toString()}
                  value={protocol.id}
                  checked={protocol.id === selectedProtocol?.id}
                  onChange={() => setSelectedProtocol(protocol)}
                />
              </Box>
            ))}
        </Flex>
      </ShouldRender>

      <Flex
        sx={{
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Text variant="p">
          <FormattedMessage
            id="is.update_patient_status_modal.signed_on"
            defaultMessage="Consent signed on:"
          />
        </Text>
        <Box
          sx={{
            ...datepickerStyle,
          }}
        >
          <DatePickerField
            position="bottom left"
            label={intl.formatMessage({
              id: 'is.update_patient_status.date_picker_label',
              defaultMessage: 'Date',
            })}
            placeholder={intl.formatMessage({
              id: 'is.update_patient_status.date_picker_placeholder',
              defaultMessage: 'Select date',
            })}
            selected={consentedDate}
            dateFormat={
              consentedDate && formatDate(intl.formatDate(consentedDate))
            }
            onChange={(date: Date) => {
              if (!isDate(date)) {
                return;
              }
              setConsentedDate(date);
            }}
          />
        </Box>
      </Flex>
    </UpdateStatusLayout>
  );
};
