import { useState, useEffect } from 'react';
import { Text } from '@clariness/clarikit-react';
import { Store } from 'store';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Svg } from './svg';
import { Box } from '@clariness/box';
import { Checkbox } from '@clariness/checkbox';
import { Services } from 'services';
import { StudySitePerformanceDto } from 'common/api/models/StudySitePerformanceDto';

interface Props {
  performanceData: StudySitePerformanceDto;
  studyName?: string,
}

export const ConsentedModal = ({ performanceData, studyName }: Props) => {
  const [showNotifications, setShowNotifications] = useState(true);
  const { user } = Store.useUser();

  const { updateShowNotification } =
    Services.SiteEmployees.useUpdateShowNotification(
      {
        onSuccess: (data) => {
          setShowNotifications(data.showNotification as boolean)
        }
      },
    );

  useEffect(() => {
    if (user?.id) {
      captureEventV2(
        Events.InvestigatorService.InvestigatorViewedPerformanceNotification({
          InvestigatorID: user?.id,
          NotificationType: 'CONSENTED',
        })
      );
    }
  }, []);

  const handleUpdateShowNotification = (value: boolean) => {
    if (user?.id) {
      updateShowNotification({
        siteEmployeeId: user?.id,
        showNotification: value,
      });
    };
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        width: '360px',
        marginTop: '16px',
      }}
    >
      <Svg />

      <Box marginTop="16px" marginBottom="16px">
        <Text as='h6' variant='headingXs' fontWeight='bold'>
          <FormattedMessage
            id="is.update_referral_status.consented_modal.header"
            defaultMessage="Patient Consented!"
          />
        </Text>
      </Box>

      <Text as='p' color="color-green-primary" fontWeight='bold'>
        <FormattedMessage
          id="is.update_referral_status.consented_modal.title"
          defaultMessage={"You have {patientNumbers} patients consented for {studyName}!"}
          values={{
            patientNumbers: performanceData.totalConsented,
            studyName: studyName
          }}
        />
      </Text>
      <br />

      <Text
        as="p"
      >
        <FormattedMessage
          id="is.update_referral_status.consented_modal.body"
          defaultMessage="Your commitment is making a difference. Together, we are advancing clinical research!"
        />
      </Text>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px'
        }}
      >
        <Checkbox
          checked={!showNotifications}
          onChange={(e) => handleUpdateShowNotification(!e.target.checked)}
          data-test-id="clinlife-notification-checkbox"
          sx={{
            margin: '4px 16px 0 0'
          }}
        />
        <Text as='span' variant='bodySm'>
          <FormattedMessage
            id="is.update_referral_status.modal.notification_checkbox"
            defaultMessage="I do not want to see any notifications from ClinLife anymore."
          />
        </Text>
      </Box>
    </Flex>
  );
};
