import React, { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';

type LabeledRowProps = {
  label: string | ReactNode;
};

export const LabeledRow = ({
  label,
  children,
}: PropsWithChildren<LabeledRowProps>) => {
  return (
    <Box as="tr">
      <Flex
        as="th"
        sx={{
          textAlign: 'left',
          pb: '15px',
          width: '150px',
          minWidth: '150px',
          flexDirection: 'row',
        }}
      >
        <Text variant="pBold">{label}</Text>
      </Flex>

      <Box as="td" sx={{ pl: '40px', pb: '15px' }}>
        <Text variant="p">{children}</Text>
      </Box>
    </Box>
  );
};
