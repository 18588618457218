import React from 'react';
import { Box } from '@clariness/box';

export const LabeledContentTable: React.FC = ({ children }) => {
  return (
    <Box
      as="table"
      sx={{
        width: 'fit-content',
        borderSpacing: '0px',
      }}
    >
      <tbody>{children}</tbody>
    </Box>
  );
};
