/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvestigatorUpdateDto = {
    id?: number;
    siteStudyId: number;
    siteId: string;
    studyId: string;
    siteEmployeeId: string;
    role: InvestigatorUpdateDto.role;
    contact: InvestigatorUpdateDto.contact;
    referralRecipient: boolean;
    weeklyReferralRecipient: boolean;
}

export namespace InvestigatorUpdateDto {

    export enum role {
        PRINCIPAL_INVESTIGATOR = 'Principal Investigator',
        STUDY_COORDINATOR = 'Study Coordinator',
        STUDY_NURSE = 'Study Nurse',
        CRA_EMPLOYEE = 'CRA Employee',
        OTHER = 'Other',
    }

    export enum contact {
        MAIN_CONTACT = 'Main Contact',
        CONTACT = 'Contact',
        NO_CONTACT = 'No Contact',
    }


}
