import React from 'react';
import { Text } from '@clariness/text';
import { FormattedMessage } from 'react-intl';

export const AuthPageLayoutFooter: React.FC = () => {
  return (
    <Text
      variant="small"
      sx={{
        mt: '35px',
        color: 'text3',
      }}
    >
      <FormattedMessage
        id="is.footer.text"
        defaultMessage="Copyright CLARINESS © 2021. All rights reserved."
      />
    </Text>
  );
};
