import React from 'react';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { Loader } from '@clariness/loader';

export const PageLoader: React.FC = ({ children }) => {
  return (
    <Flex
      data-testid="loader"
      sx={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255)',
        color: 'green.500',
        zIndex: 10,
      }}
    >
      <Loader size={35} />

      <Text variant="h1">{children}</Text>
    </Flex>
  );
};
