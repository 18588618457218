import React from 'react';
import { Services } from 'services';
import { Store } from 'store';
import { ShouldRender } from 'components/ShouldRender';
import { PageLoader } from 'components/loaders';

export const AuthGuard: React.FC = ({ children }) => {
  const { isLoading } = Services.Auth.useRefreshAccessToken();

  const { isAuthenticated } = Store.useUser();

  return (
    <React.Fragment>
      {isAuthenticated ? (
        children
      ) : (
        <ShouldRender when={isLoading}>
          <PageLoader />
        </ShouldRender>
      )}
    </React.Fragment>
  );
};
