import * as ReactQuery from 'react-query';
import { getReferralActivityAPI } from './api';

export const GET_REFERRAL_ACTIVITY_KEY = '@referrals/get-referral-activity';

export function useGetReferralActivity(id = '') {
  const { data, isLoading, isError, refetch } = ReactQuery.useQuery(
    [GET_REFERRAL_ACTIVITY_KEY, id],
    () => getReferralActivityAPI(id)
  );

  return {
    activity: data,
    isLoading,
    isError,
    refetch,
  };
}
