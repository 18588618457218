import {
  SEARCH_REFERRALS_KEY,
  useSearchReferrals,
} from './search-referrals/hook';
import { useUpdateReferralStatus } from './update-referral-status/hook';
import { GET_REFERRAL_KEY, useGetReferral } from './get-referral/hook';
import {
  GET_REFERRAL_ACTIVITY_KEY,
  useGetReferralActivity,
} from './get-referral-activity/hook';
import { useUpdateReferralNote } from './update-referral-note/hook';
import { useDownloadXls } from './download-xls/hook';
import { useGetPdf } from './get-pdf/hook';
import { useUpdateReferralProtocol } from './update-referral-protocol/hook';
import {
  GET_REFERRAL_PROTOCOL_KEY,
  useGetReferralProtocol,
} from './get-referral-protocol/hook';
import { useUpdateCallbackTime } from './update-callback-time/hook';
import {
  GET_REFERRAL_HISTORY_KEY,
  useGetReferralHistory,
} from './get-referral-history/hook';
import { useGetReferralSiteTimezone } from './get-referral-site-timezone/hook';
import { useUpdateProtocol801Participation } from './update-protocol-801-participation/hook';
import { useUpdateReferralSponsorPatientId } from './update-sponsor-patient-id/hook';
import { useGetScreener } from './get-screener/hook';

export const Referrals = {
  useSearchReferrals,
  useUpdateReferralStatus,
  useUpdateReferralNote,
  useGetReferral,
  useGetReferralActivity,
  useDownloadXls,
  useGetPdf,
  useUpdateReferralProtocol,
  useGetReferralProtocol,
  useUpdateCallbackTime,
  useGetReferralHistory,
  useGetReferralSiteTimezone,
  useUpdateProtocol801Participation,
  useUpdateReferralSponsorPatientId,
  useGetScreener,
  
  KEYS: {
    SEARCH_REFERRALS_KEY,
    GET_REFERRAL_KEY,
    GET_REFERRAL_ACTIVITY_KEY,
    GET_REFERRAL_PROTOCOL_KEY,
    GET_REFERRAL_HISTORY_KEY,
  },
};
