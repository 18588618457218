import { serviceRequest } from 'utils/http';
import { Service, MultipleInvestigatorNotificationUpdateDto } from 'common/api';

export function updateInvestigatorNotificationAPI(
  multipleInvestigatorNotificationUpdateDto: MultipleInvestigatorNotificationUpdateDto
) {
  return serviceRequest(() =>
    Service.investigatorControllerUpdateInvestigatorNotifications(
      multipleInvestigatorNotificationUpdateDto
    )
  );
}
