import React from 'react';
import { Flex } from '@clariness/flex';
import { StyleObject } from '@clariness/base';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  sx?: StyleObject;
  onSubmit: () => void;
}

export const Form: React.FC<FormProps> = ({ children, onSubmit, sx }) => (
  <Flex
    as="form"
    role="form"
    sx={sx}
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit();
    }}
  >
    {children}
  </Flex>
);
