import React, { useState } from 'react';
import { NavLink, NavLinkProps } from 'components/links';
import {
  NavBarElement,
  NavBarElementProps,
} from '../NavBarElement/NavBarElement';

type NavBarLinkProps = NavBarElementProps & NavLinkProps;

const activeStyles = {
  color: 'green.600',
};

export const NavBarLink: React.FC<NavBarLinkProps> = ({
  to,
  icon,
  children,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  const navBarStyles = isActive ? activeStyles : { color: 'text-primary' };

  return (
    <NavLink
      onClick={onClick}
      to={to}
      onActive={() => setIsActive(true)}
      onInActive={() => setIsActive(false)}
      variant="primary"
      sx={{
        color: 'text1',
        textDecoration: 'none',

        '&:hover': {
          color: 'text1',
        },
      }}
    >
      <NavBarElement sx={navBarStyles} icon={icon}>
        {children}
      </NavBarElement>
    </NavLink>
  );
};
