import { format, getMinutes, setMinutes } from 'date-fns';

import { CallbackTimeslotDto, SiteCallbackTimeslotDto, CreateSiteCallbackTimeslotDto } from '../api';

export class UserAvailabilityInfo {
  public static readonly keysToTranslate = [
    ['is.patient_details_modal.contact_details.availability.in_day'],
    ['is.patient_details_modal.contact_details.availability.in_any_day'],
    ['is.patient_details_modal.contact_details.availability.in_timeslot'],
    ['is.patient_details_modal.contact_details.availability.anytime'],
  ];

  private static convertTZ(date: string | Date, tzString: string): Date {
    if (!tzString) {
      return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString(
          'en-US'
        )
      );
    }
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString }
      )
    );
  }

  private static get userTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static stringify(options: Partial<UserAvailabilityInfo>): string {
    const {
      date,
      timeslot,
      siteTimeZone: existedSiteTimeZone,
      userTimeZone: existedUserTimeZone,
    } = options || {};
    const userTimeZone = existedUserTimeZone
      ? existedUserTimeZone
      : UserAvailabilityInfo.userTimeZone;

    const siteTimeZone = existedSiteTimeZone
      ? existedSiteTimeZone
      : userTimeZone;

    const encoded = encodeURIComponent(
      JSON.stringify({
        date,
        timeslot: timeslot || '',
        userTimeZone,
        siteTimeZone,
      } as UserAvailabilityInfo)
    );
    return encoded;
  }

  static parse(availability: string): UserAvailabilityInfo {
    if (!availability) {
      return;
    }
    try {
      const data = JSON.parse(
        decodeURIComponent(availability)
      ) as UserAvailabilityInfo;
      if (data.date && data.userTimeZone) {
        data.date = UserAvailabilityInfo.convertTZ(
          data.date,
          data.userTimeZone || UserAvailabilityInfo.userTimeZone
        );
      }
      return new UserAvailabilityInfo(data);
      // eslint-disable-next-line
    } catch (error) { }
  }

  public static displayMessageForIS(availability: string, intl: any) {
    const obj = UserAvailabilityInfo.parse(availability);
    if (obj && Object.keys(obj).length > 0) {
      let msg = '';
      let dateMsg: any;

      try {
        dateMsg = format(new Date(obj.date), 'dd.MM.y');
        // eslint-disable-next-line
      } catch (error) { }

      if (!!obj.date && !!dateMsg) {
        msg = dateMsg;
      } else {
        dateMsg = `${intl?.formatMessage({
          id: 'is.patient_details_modal.contact_details.availability.in_any_day',
          defaultMessage: 'in any day',
        })}...`;
        msg = `${msg}${dateMsg}`;
      }

      if (obj.timeslot) {
        msg = `${msg}\n${obj.getMessageTimeSlotForSite()}`;
      } else {
        msg = `${msg}\n${obj.getMessageAnytime(intl)}`;
      }
      return !!msg.trim() ? msg : availability;
    }
    return availability
      ? availability
      : `${intl?.formatMessage({
        id: 'is.patient_details_modal.contact_details.availability.in_any_day',
        defaultMessage: 'in any day',
      })}...`;
  }

  private constructor(options?: Partial<UserAvailabilityInfo>) {
    Object.keys(options || {}).forEach((key) => {
      this[key] = options[key];
    });
  }

  date?: string | Date;
  timeslot?: string;
  userTimeZone: string;
  siteTimeZone: string;

  getMessage(intl: any) {
    return UserAvailabilityInfo.displayMessageForIS(
      UserAvailabilityInfo.stringify(this),
      intl
    );
  }

  getMessageTimeSlotForSite() {
    const { timeslot, siteTimeZone, userTimeZone } = this;
    let { date } = this;

    const [fromTime, toTime] = (timeslot || '')
      .split('-')
      .map((t) => t.trim().split('.'));
    const [fromTimeHours, fromTimeMinutes] = (fromTime || []).map((f) =>
      Number(f)
    );
    const [toTimeHours, toTimeMinutes] = (toTime || []).map((f) => Number(f));

    const dateIsDefined = !!date;
    if (!dateIsDefined) {
      date = UserAvailabilityInfo.convertTZ(new Date(), userTimeZone);
    }

    let dateFrom = (typeof date === 'string' ? new Date(date) : date) as Date;
    if (timeslot) {
      dateFrom.setHours(fromTimeHours);
      dateFrom.setMinutes(fromTimeMinutes);
      dateFrom = UserAvailabilityInfo.convertTZ(dateFrom, siteTimeZone);
    }

    let dateTo = (typeof date === 'string' ? new Date(date) : date) as Date;
    if (timeslot) {
      dateTo.setHours(toTimeHours);
      dateTo.setMinutes(toTimeMinutes);
      dateTo = UserAvailabilityInfo.convertTZ(dateTo, siteTimeZone);
    }
    const from = dateFrom ? format(new Date(dateFrom), 'HH:mm') : '';
    const to = dateTo ? format(new Date(dateTo), 'HH:mm') : '';

    return ` ${from} - ${to}`;
  }

  getMessageAnytime(intl: any) {
    return `${intl.formatMessage({
      id: 'is.patient_details_modal.contact_details.availability.anytime',
      defaultMessage: 'anytime',
    })}...`;
  }

  static parseCallbackTimeslotISUser(
    callbackTimeslotDto?: CallbackTimeslotDto,
    intl?: any
  ) {
    if (
      !callbackTimeslotDto ||
      !callbackTimeslotDto.start ||
      !callbackTimeslotDto.end
    ) {
      return UserAvailabilityInfo.getNoTimeslotSelectedMessage(intl);
    }
    return UserAvailabilityInfo.formattedDisplayString(
      callbackTimeslotDto.start,
      callbackTimeslotDto.end
    );
  }

  static parseCallbackTimeslotPatientDetails(
    { start, end, status }: CallbackTimeslotDto,
    intl?: any
  ) {
    if (status === CallbackTimeslotDto.status.PENDING) {
      return `${intl.formatMessage({
        id: 'patient_details_modal.contact_details.schedule_new',
        defaultMessage: 'schedule new',
      })}`;
    }

    if (!start || !end) {
      return UserAvailabilityInfo.getNoTimeslotSelectedMessage(intl);
    }
    return UserAvailabilityInfo.formattedDisplayString(start, end);
  }

  static parseCallbackTimeslot(
    { start, end, status, siteSelected }: CallbackTimeslotDto,
    intl?: any
  ) {
    if (status === CallbackTimeslotDto.status.PENDING || siteSelected) {
      return `${intl.formatMessage({
        id: 'patient_details_modal.contact_details.schedule_new',
        defaultMessage: 'schedule new',
      })}`;
    }

    if (!start || !end) {
      return UserAvailabilityInfo.getNoTimeslotSelectedMessage(intl);
    }

    return this.formattedDisplayString(start, end);
  }

  static parseSiteCallbackTimeslot(
    { start, end }: SiteCallbackTimeslotDto,
    intl?: any
  ) {
    return this.formattedDisplayString(start, end);
  }

  static parseCallbackTimeslotToISOString(
    date?: Date,
    timeslot?: string
  ): CallbackTimeslotDto {
    if (!date || !timeslot) {
      return undefined;
    }
    const timeDate = new Date(timeslot);
    const callbackTime = {
      start: setMinutes(timeDate, getMinutes(timeDate)).toISOString(),
      end: setMinutes(timeDate, getMinutes(timeDate) + 30).toISOString(),
    };

    return callbackTime;
  }

  static parseSiteCallbackTimeslotToISOString(
    date?: Date,
    timeslot?: string
  ): CreateSiteCallbackTimeslotDto {
    if (!date || !timeslot) {
      return undefined;
    }
    const timeDate = new Date(timeslot);
    const callbackTime = {
      start: setMinutes(timeDate, getMinutes(timeDate)).toISOString(),
      end: setMinutes(timeDate, getMinutes(timeDate) + 30).toISOString(),
    };

    return callbackTime;
  }

  static parseSiteCallbackTimeslotForTimePicker(
    date?: Date,
    timeslot?: string
  ): CreateSiteCallbackTimeslotDto {
    if (!date || !timeslot) {
      return undefined;
    }
    const timeDate = new Date(timeslot);
    const callbackTime = {
      start: setMinutes(date, getMinutes(timeDate)).toISOString(),
      end: setMinutes(date, getMinutes(timeDate) + 30).toISOString(),
    };
    return callbackTime;
  }

  static formatDateTime(time: string, fullTime?: boolean, withTimeZone: boolean = true) {
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options: Intl.DateTimeFormatOptions = {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    if (fullTime) {
      options = {
        ...options,
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      };
    }
    const formattedTime = new Intl.DateTimeFormat('de-DE', options).format(
      new Date(time)
    );

    return withTimeZone ? `${formattedTime} - (${timeZone})` : formattedTime;
  }

  private static getNoTimeslotSelectedMessage(intl: any) {
    return `${intl.formatMessage({
      id: 'is.patient_details_modal.contact_details.availability.anytime',
      defaultMessage: 'anytime',
    })}`;
  }

  private static formattedDisplayString(start: string, end: string) {
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Intl.DateTimeFormat('de-DE', {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(start));
    const startDate = new Date(start).toLocaleTimeString('de-DE', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
    });
    const endDate = new Date(end).toLocaleTimeString('de-DE', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${date} - ${startDate} - ${endDate} (${timeZone})`;
  }

  static formatStartDate(start: string, addTimeZoneData: boolean) {
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Intl.DateTimeFormat('de-DE', {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(start));
    const startDate = new Date(start).toLocaleTimeString('de-DE', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
    });
    return addTimeZoneData ? `${date} - ${startDate} (${timeZone})` : `${date} - ${startDate}`;
  }

  static formatDateOnly(time: string) {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    const formattedTime = new Intl.DateTimeFormat('de-DE', options).format(
      new Date(time)
    );
    return formattedTime;
  }
    
}


