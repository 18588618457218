import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const waitingForInfromationMainStatuses = {
  [ReferralStatus.CONSENTED]: {
    title: (
      <FormattedMessage
        id="is.referral_status.consented.title"
        defaultMessage="Consented"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.consented.description"
        defaultMessage="Patient has signed ICF and is in screening."
      />
    ),
  },

  [ReferralStatus.DROPPED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped.title"
        defaultMessage="Dropped"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.dropped.description"
        defaultMessage="The patient will not be considered for the clinical study."
      />
    ),
  },
};
