import React from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { FormattedMessage } from 'react-intl';
import { TablePagination } from 'components/Table/components';
import { useTableContext } from 'components/Table';
import { OnChangeState } from 'components/Table/Table';
import { getLastRow } from './getLastRow';

type Props = {
  onPageChange?: (state: OnChangeState) => void;
};

export const TableFooter: React.FC<Props> = ({ onPageChange }: Props) => {
  const {
    rows,
    footerGroups,
    state,
    maxRowsPerPage,
    totalRows,
    originalColumns,
  } = useTableContext();

  const hasFooterColumns = originalColumns.filter(
    (originalColumn: any) => !!originalColumn.Footer
  ).length;

  return (
    <ShouldRender when={!!rows.length}>
      <tfoot>
        <ShouldRender when={hasFooterColumns}>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <Box
                  as="td"
                  sx={{
                    height: '49px',
                    backgroundColor: 'hgl7',
                  }}
                  {...column.getFooterProps()}
                >
                  {column.render('Footer')}
                </Box>
              ))}
            </tr>
          ))}
        </ShouldRender>

        <tr>
          <Box as="td" colSpan={1000} sx={{ p: '10px 15px' }}>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text
                sx={{
                  color: 'dark-grey',
                  fontSize: '15px',
                }}
              >
                <FormattedMessage
                  id="is.patient_table_footer.showing_rows_label"
                  defaultMessage="Showing {firstRow}-{lastRow} of {totalRows} patients"
                  values={{
                    firstRow: state.pageIndex * (maxRowsPerPage as number) + 1,
                    lastRow: getLastRow(
                      totalRows,
                      state.pageIndex,
                      maxRowsPerPage as number
                    ),
                    totalRows,
                  }}
                />
              </Text>

              <TablePagination onPageChange={onPageChange} />
            </Flex>
          </Box>
        </tr>
      </tfoot>
    </ShouldRender>
  );
};
