import { ReferralSearchParametersDto, Service } from 'common/api';
import { serviceRequest } from 'utils/http';

export function searchReferralsAPI(
  searchParameters: ReferralSearchParametersDto
) {
  return serviceRequest(() =>
    Service.referralControllerSearch(searchParameters)
  );
}
