import * as ReactQuery from 'react-query';
import { getTranslationsAPI } from './api';

const GET_TRANSLATIONS_KEY = '@translations/get-translations';

export function useGetTranslations() {
  const { data, isLoading, isError } = ReactQuery.useQuery(
    GET_TRANSLATIONS_KEY,
    getTranslationsAPI
  );

  return {
    translations: data,
    isLoading,
    isError,
  };
}
