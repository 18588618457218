/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SiteEmployeeDto } from './SiteEmployeeDto';
import type { StudyDto } from './StudyDto';

export type InvestigatorDto = {
    id: number;
    studySite: StudyDto;
    siteEmployee: SiteEmployeeDto;
    role: InvestigatorDto.role;
    contact: InvestigatorDto.contact;
    referralRecipient: boolean;
    mainContactUpdated?: boolean;
    weeklyReferralRecipient: boolean;
    studyName?: string;
    patientRecontactMarkRecipient: boolean;
}

export namespace InvestigatorDto {

    export enum role {
        PRINCIPAL_INVESTIGATOR = 'Principal Investigator',
        STUDY_COORDINATOR = 'Study Coordinator',
        STUDY_NURSE = 'Study Nurse',
        CRA_EMPLOYEE = 'CRA Employee',
        OTHER = 'Other',
    }

    export enum contact {
        MAIN_CONTACT = 'Main Contact',
        CONTACT = 'Contact',
        NO_CONTACT = 'No Contact',
    }


}
