import { AnswerValueType } from '../types/answer-value-type.enum';

export function calculateBMIScore(answerValues: any) {
  if (answerValues?.values?.length > 0) {
    const answerType = answerValues.values[0]?.type;
    if (
      answerType === AnswerValueType.HEIGHT_CM ||
      answerType === AnswerValueType.WEIGHT_KG
    ) {
      const weight = Number(
        answerValues.values.find((val) => val.type === AnswerValueType.WEIGHT_KG)?.value
      );
      //converting the height from centrimeters to meters
      const height =
        Number(
          answerValues.values.find((val) => val.type === AnswerValueType.HEIGHT_CM)
            ?.value
        ) / 100;
      return (weight / height / height)?.toFixed(2);
    } else {
      const weight = Number(
        answerValues.values.find((val) => val.type === AnswerValueType.WEIGHT_LB)?.value
      );
      //Converting the height from feet to inches
      const height = answerValues.values.find(
        (val) => val.type === AnswerValueType.HEIGHT_FT
      )?.value;
      const { valueInft, valueInInches } = getMeasurementInFtandInches(height);
      const heightInInches = valueInft * 12 + valueInInches;
      return ((weight / heightInInches / heightInInches) * 703)?.toFixed(2);
    }
  }
}

export function getMeasurementInFtandInches(answerValue: string) {
  const valueInft = answerValue?.split('\'')[0];
  const valueInInches = answerValue?.split('\'')[1]?.split('"')[0];
  return { valueInft: Number(valueInft), valueInInches: Number(valueInInches) };
}
