/* eslint-disable react/no-deprecated */
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/400-italic.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/noto-sans/700-italic.css';
import ReactDOM from 'react-dom';
import App from './App';

const renderApp = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();
