import { useEffect } from 'react';
import posthog from 'posthog-js';

export function useEventTracking() {
  useEffect(() => {
    const onCookiebotConsent = (event: Event) => {
      // eslint-disable-next-line
      // @ts-ignore
      const hasGivenTrackingConsent = event.target.Cookiebot.consent.statistics;

      if (hasGivenTrackingConsent) {
        posthog.opt_in_capturing();
      } else {
        posthog.opt_out_capturing();
      }
    };

    window.addEventListener('CookiebotOnLoad', onCookiebotConsent);

    return () => {
      window.removeEventListener('CookiebotOnLoad', onCookiebotConsent);
    };
  }, []);
}
