/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { StudySite } from './StudySite';

export type SccActivityEventDto = {
    event: SccActivityEventDto.event;
    date?: string;
    callType: SccActivityEventDto.callType;
    actionType: SccActivityEventDto.actionType;
    studySite: StudySite;
    note: string;
    userId: string;
    userEmail: string;
    reason?: SccActivityEventDto.reason;
    callStatus?: SccActivityEventDto.callStatus;
    details?: string;
    recipient: string;
    subject: string;
    body: string;
    createdAt: string;
    protocolName?: string;
}

export namespace SccActivityEventDto {

    export enum event {
        ALL = 'ALL',
        COMMUNICATION_EXCHANGE = 'COMMUNICATION_EXCHANGE',
        INBOUND_CALLS = 'INBOUND_CALLS',
        OUTBOUND_CALLS = 'OUTBOUND_CALLS',
        SCHEDULED_CALLS = 'SCHEDULED_CALLS',
        FLAGS = 'FLAGS',
        ESCALATIONS = 'ESCALATIONS',
        ESCALATIONS_CLOSE_OUT = 'ESCALATIONS_CLOSE_OUT',
        SITE_NOTES = 'SITE_NOTES',
        SITE_EMAILS = 'SITE_EMAILS',
    }

    export enum callType {
        OPT_IN = 'OPT_IN',
        TRAINING = 'TRAINING',
        FOLLOW_UP = 'FOLLOW_UP',
    }

    export enum actionType {
        OPT_IN = 'OPT_IN',
        OPT_OUT = 'OPT_OUT',
        TRAINING = 'TRAINING',
        BYEBYE = 'BYEBYE',
    }

    export enum reason {
        LEVEL_1_PATIENT_BACKLOG = 'LEVEL_1_PATIENT_BACKLOG',
        LEVEL_2_NO_RESPONSE_FROM_THE_SITE___PATIENT_BACKLOG = 'LEVEL_2_NO_RESPONSE_FROM_THE_SITE_&_PATIENT_BACKLOG',
        LEVEL_3_NO_RESPONSE_FROM_THE_SITE = 'LEVEL_3_NO_RESPONSE_FROM_THE_SITE',
    }

    export enum callStatus {
        OUTBOUND_CALL = 'OUTBOUND_CALL',
        NOT_REACHED = 'NOT_REACHED',
    }


}
