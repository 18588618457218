import React, { useEffect } from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { StyleObject } from '@clariness/base';
import { Link as StyledLink, LinkProps } from '@clariness/link';

export type NavLinkProps = LinkProps & {
  activeStyles?: StyleObject;
  onActive?: () => void;
  onInActive?: () => void;
};

export const NavLink: React.FC<NavLinkProps> = ({
  to,
  activeStyles,
  onActive,
  onInActive,
  sx,
  children,
  ...props
}) => {
  const location = ReactRouterDom.useLocation();
  const matchPath = ReactRouterDom.matchPath(location.pathname, {
    path: to,
    strict: false,
  });

  const navLinkStyles = matchPath ? activeStyles : {};

  useEffect(() => {
    if (onActive) {
      if (matchPath) {
        onActive();
      }
    }

    if (onInActive) {
      if (!matchPath) {
        onInActive();
      }
    }
  }, [matchPath, onActive, onInActive]);

  return (
    <StyledLink
      as={ReactRouterDom.Link}
      to={to}
      sx={{
        ...navLinkStyles,
        ...sx,
      }}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
