import { UpdatePatientStatusModal } from './UpdatePatientStatusModal';
import { PatientDetailsProtocolConfirmation } from './PatientDetailsProtocolConfirmation';
import { RecontactedPatientModal } from './RecontactedPatientModal';

export * from './ModalContainer/ModalContainer';

export const Modals = {
  UpdatePatientStatus: UpdatePatientStatusModal,
  ProtocolConfirmation: PatientDetailsProtocolConfirmation,
  RecontactedPatient: RecontactedPatientModal,
};
