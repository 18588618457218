/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReferralSearchSortDto = {
    column: ReferralSearchSortDto.column;
    asc?: boolean;
}

export namespace ReferralSearchSortDto {

    export enum column {
        ID = 'id',
        STUDY_ID = 'studyId',
        AVAILABILITY = 'availability',
        CALLBACK_TIME = 'callbackTime',
        PATIENT_INTERNAL_ID = 'patientInternalId',
        FIRST_NAME = 'firstName',
        LAST_NAME = 'lastName',
        REFERRAL_DATE = 'referralDate',
        REGISTRATION_DATE = 'registrationDate',
        STATUS = 'status',
        PATIENT_LANGUAGE = 'patientLanguage',
        LAST_UPDATE = 'lastUpdate',
    }


}
