import { SiteEmployeeDto } from 'common/api';

export const SiteEmployeeTitle = {
  PROF: {
    value: SiteEmployeeDto.title.PROF,
    label: 'Prof',
  },
  PROF_DR: {
    value: SiteEmployeeDto.title.PROF_DR,
    label: 'Prof Dr',
  },
  DR: {
    value: SiteEmployeeDto.title.DR,
    label: 'Dr',
  },
  MRS: {
    value: SiteEmployeeDto.title.MRS,
    label: 'Mrs',
  },
  MR: {
    value: SiteEmployeeDto.title.MR,
    label: 'Mr',
  },
  MS: {
    value: SiteEmployeeDto.title.MS,
    label: 'Ms',
  },
};

export type SiteEmployeeTitleType = keyof typeof SiteEmployeeTitle;
