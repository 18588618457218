import { IntlShape } from 'react-intl';

export function validateRequiredField(intl: IntlShape) {
  return {
    validate: (value: any) =>
      !!value.trim() ||
      intl.formatMessage({
        id: 'is.input.field_required',
        defaultMessage: 'This field is mandatory',
      }),
  };
}
