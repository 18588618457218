import { serviceRequest } from 'utils/http';
import { Service, Protocol801ParticipationUpdateDto } from 'common/api';

export function updateReferralProtocol801ParticipationAPI(
  protocol801ParticipationDto: Protocol801ParticipationUpdateDto
) {
  return serviceRequest(() =>
    Service.referralControllerAddProtocol801Participation(
      protocol801ParticipationDto
    )
  );
}
