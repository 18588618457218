import React from 'react';
import { Flex } from '@clariness/flex';
import { Image } from '@clariness/image';
import { Text } from '@clariness/text';
import tableNoResultsSrc from 'assets/images/table-no-results.png';
import { ShouldRender } from 'components/ShouldRender';
import { useTableContext } from 'components/Table';

type TableNoResultsProps = {
  title: string;
  description?: string;
};

export const TableNoResults = ({ title, description }: TableNoResultsProps) => {
  const { isLoading } = useTableContext();

  return (
    <ShouldRender when={!isLoading}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          m: 'auto',
          justifyContent: 'center',
          gap: '40px',
          color: '#707070',
        }}
      >
        <Image
          width="142px"
          src={tableNoResultsSrc}
          alt="Empty notebooks illustrating that no results have been found"
        />

        <Text
          variant="p"
          sx={{
            color: 'inherit',
          }}
        >
          {title}
        </Text>

        <ShouldRender when={Boolean(description)}>
          <Text variant="p" sx={{ color: 'inherit' }}>
            {description}
          </Text>
        </ShouldRender>
      </Flex>
    </ShouldRender>
  );
};
