import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { ServiceProvider } from 'services';
import { StoreProvider } from 'store';
import { ThemeProvider } from 'theming';
import { IntlProvider } from 'app/IntlProvider';

export const GlobalProvider: React.FC = ({ children }) => (
  <ReactRouterDom.BrowserRouter>
    <StoreProvider>
      <ServiceProvider>
        <IntlProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </IntlProvider>
      </ServiceProvider>
    </StoreProvider>
  </ReactRouterDom.BrowserRouter>
);
