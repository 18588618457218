import * as ReactQuery from 'react-query';
import { getStudySitePerformanceAPI } from './api';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { ReferralStatusType } from 'global/referralStatus';
import { ReferralStatus } from 'global/referralStatus';
import { AppointmentScheduledModal } from 'components/modals/AppointmentScheduledModal';
import { ConsentedModal } from 'components/modals/ConsentedModal';
import { ReferralDto } from 'common/api';

const GET_STUDY_SITE_PERFORMANCE_KEY =
  '@study-site-performance/get';

export function useGetStudySitePerformance(
  options?: InferMutationOptions<typeof getStudySitePerformanceAPI>,
  selectedStatus?: ReferralStatusType,
  referral?: ReferralDto,
  appointmentScheduleDate?: Date,
) {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    GET_STUDY_SITE_PERFORMANCE_KEY,
    getStudySitePerformanceAPI,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'info',
          description: selectedStatus === ReferralStatus.APPOINTMENT_SCHEDULED ?
            <AppointmentScheduledModal 
              performanceData={data}
              referral={referral}
              appointmentScheduleDate={appointmentScheduleDate}
            /> :
            selectedStatus === ReferralStatus.CONSENTED &&
            <ConsentedModal performanceData={data} studyName={referral?.study?.name}/>,
          dismissible: true,
          showIcon: false,
          fadeInAnimationProps: {
            '0%': {
              transform: 'translateX(44px)',
            },
            '75%': {
              transform: 'translateX(-3px)',
            },
            '100%': {
              transform: 'translateX(0)',
            },
          },
          sx: {
            position: 'fixed',
            right: '30px',
            backgroundColor: 'white',
            paddingTop: '16px',
            paddingBottom: '32px',
            paddingLeft: '32px',
            alignItems: 'flex-start',
          },
        });

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
      onError: (error, data, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.update_referral_protocol_error',
            defaultMessage:
              "lalay lay",
          }),
        });

        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
    }
  );

  return {
    getStudySitePerformanceAPI: mutate,
    isLoading,
    isError,
  };
}
