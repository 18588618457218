import React from 'react';
import { Text } from '@clariness/text';

export const AuthPageLayoutHeading: React.FC = ({ children }) => {
  return (
    <Text as="h3" variant="h3" sx={{ mb: '36px' }}>
      {children}
    </Text>
  );
};
