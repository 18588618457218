import React, { ReactNode, ReactNodeArray } from 'react';
import { Card } from '@clariness/card';
import { ErrorMessage } from '@clariness/error-message';
import { Flex } from '@clariness/flex';
import {
  AuthPageLayoutFooter,
  AuthPageLayoutForm,
  AuthPageLayoutHeading,
  AuthPageLayoutInfoLinks,
  AuthPageLayoutLogo,
  AuthPageLayoutParagraph,
} from './components';

type ErrorMessage = {
  title: string;
  description: string;
};

type AuthPageLayoutProps = {
  children: ReactNode | ReactNodeArray;
  error?: ErrorMessage | null;
};

export const AuthPageLayout = ({ error, children }: AuthPageLayoutProps) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'bg.100',
        minHeight: '100vh',
      }}
    >
      <Card
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '450px',
          textAlign: 'center',
        }}
      >
        {Boolean(error) && (
          <ErrorMessage title={error?.title} description={error?.description} />
        )}
        {children}
      </Card>
    </Flex>
  );
};

AuthPageLayout.Logo = AuthPageLayoutLogo;
AuthPageLayout.Heading = AuthPageLayoutHeading;
AuthPageLayout.Paragraph = AuthPageLayoutParagraph;
AuthPageLayout.Form = AuthPageLayoutForm;
AuthPageLayout.InfoLinks = AuthPageLayoutInfoLinks;
AuthPageLayout.Footer = AuthPageLayoutFooter;
