import { Modal } from '@clariness/modal';
import { Store } from 'store';
import * as ReactIntl from 'react-intl';
import { Text } from '@clariness/text';
import { Button } from '@clariness/button';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { ProtocolInfoDto, ReferralDto } from 'common/api';
import { Services } from 'services';

type UpdateProtocolModalProps = {
  referral: ReferralDto;
  protocol?: ProtocolInfoDto;
};

export const PatientDetailsProtocolConfirmation = ({
  referral,
  protocol,
}: UpdateProtocolModalProps) => {
  const { closeModal } = Store.useModal();
  const { updateReferralProtocolAPI, isLoading } =
    Services.Referrals.useUpdateReferralProtocol();

  const intl = ReactIntl.useIntl();

  const sendSelectedProtocol = () => {
    if (protocol) {
      updateReferralProtocolAPI({
        referralId: referral.id || '',
        protocol,
      });
      closeModal();
    }
  };

  return (
    <Modal
      sx={{
        width: '460px',
      }}
      onClose={closeModal}
    >
      <Modal.Header
        title={intl.formatMessage({
          id: 'is.update_protocol_modal.title',
          defaultMessage: 'Study Protocol Updated',
        })}
        sx={{
          height: '74px',
        }}
      />
      <Modal.Body>
        <Text
          variant="p"
          sx={{
            marginBottom: '46px',
          }}
        >
          <FormattedMessage
            id="is.update_protocol_modal.description"
            defaultMessage="Tha patient is assigned to protocol"
          />{' '}
          <br />
          {`${protocol?.internalId || '...'} - ${protocol?.name}`}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="secondary" onClick={closeModal}>
            <FormattedMessage
              id="is.update_protocol_modal.cancel_button"
              defaultMessage="Cancel"
            />
          </Button>

          <Button
            sx={{
              marginLeft: '24px',
            }}
            onClick={sendSelectedProtocol}
            loading={isLoading}
          >
            <FormattedMessage
              id="is.update_protocol_modal.continue_button"
              defaultMessage="Continue"
            />
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
