import { getUrlLocale } from 'utils/routing/getUrlLocale';

export function changeUrlLocale(newLocale: string) {
  const currentLocale = getUrlLocale();

  const { pathname } = window.location;
  const pathnameWithoutLocale = pathname.replace(`/${currentLocale}`, '');

  window.location.replace(`/${newLocale}${pathnameWithoutLocale}`);
}
