import * as ReactQuery from 'react-query';
import { useCallback } from 'react';
import { ReferralSearchFilterDto, ReferralSearchSortDto } from 'common/api';
import { InferQueryOptions } from 'services';
import { Store } from 'store';
import { searchReferralsAPI } from './api';

export const SEARCH_REFERRALS_KEY = '@referrals/search-referrals';

type SearchReferralsOptions = InferQueryOptions<typeof searchReferralsAPI> & {
  take?: number;
  filterOptions?: ReferralSearchFilterDto;
};

export function useSearchReferrals({
  take,
  ...options
}: SearchReferralsOptions) {
  const { filterOptions, paginationOptions, setPaginationOptions } =
    Store.useHomePageStore();

  const filter = options.filterOptions || filterOptions;
  const { data, isLoading, isError, isFetching, refetch } = ReactQuery.useQuery(
    [SEARCH_REFERRALS_KEY, filter, paginationOptions],
    () => searchReferralsAPI({ filter, pagination: paginationOptions }),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  const fetchReferrals = useCallback(
    (state) => {
      setPaginationOptions({
        take,
        skip: take ? take * state.pageIndex : undefined,
        sorting: [
          {
            column: ReferralSearchSortDto.column.LAST_UPDATE,
            asc: true,
          },
        ],
      });
    },
    [take, setPaginationOptions]
  );

  return {
    referrals: data,
    isLoading,
    isFetching,
    isError,
    fetchReferrals,
    refetch,
  };
}
