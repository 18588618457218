import React, { Dispatch, SetStateAction, useState } from 'react';
import { AuthPageLayout } from 'components/layout';
import { MultiStep } from 'components/MultiStep';
import { generateContext } from 'utils/context';
import { useQueryParams } from 'utils/routing';
import { RegisterForm, RegisterUserAgreement } from './components';

type RegisterContext = {
  token: string;
  email: string;
  password: string;
  requestEmail: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
};

export const [useRegisterContext, RegisterContextProvider] =
  generateContext<RegisterContext>();

export const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const queryParams = useQueryParams();

  return (
    <RegisterContextProvider
      value={{
        token: queryParams.get('token') || '',
        email,
        password,
        setEmail,
        setPassword,
        requestEmail: queryParams.get('email') || '',
      }}
    >
      <MultiStep>
        <AuthPageLayout>
          <MultiStep.Step>
            <RegisterForm />
          </MultiStep.Step>

          <MultiStep.Step>
            <RegisterUserAgreement />
          </MultiStep.Step>

          <AuthPageLayout.Footer />
        </AuthPageLayout>
      </MultiStep>
    </RegisterContextProvider>
  );
};
