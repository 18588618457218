import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export function useRedirect(path = '', shouldRedirect = false) {
  const history = useHistory();

  useEffect(() => {
    if (shouldRedirect) {
      history.push(path);
    }
  }, [history, path, shouldRedirect]);

  return {
    redirect: (redirectPath = path, data?: any) =>
      history.push(redirectPath, { data }),
  };
}
