import { imperialUnitsCountryCodes, milesMultiplicator } from '../constants/units';

export const isImperialUnit = (countryCode: string): boolean => imperialUnitsCountryCodes.includes(countryCode);

export const calculateDistance = (distanceInKm: number | undefined, countryCode: string | undefined): string => {
  const isImperial = isImperialUnit(countryCode);

  if (isImperial) {
    return `${distanceInKm ? (distanceInKm * milesMultiplicator).toFixed(2) : 0} mi`;
  };

  return `${distanceInKm ? distanceInKm.toFixed(2) : 0} km`;
};

export const calculateDistanceNumber = (distanceInKm: number | undefined, countryCode: string | undefined): number => {
  const isImperial = isImperialUnit(countryCode);

  if (isImperial) {
    return Math.round(distanceInKm * milesMultiplicator);
  };

  return Math.round(distanceInKm);
};
