import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { finishRegistrationAPI } from './api';

const FINISH_REGISTRATION_KEY = '@site-employees/finish-registration';

export function useFinishRegistration(
  options?: InferMutationOptions<typeof finishRegistrationAPI>
) {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading } = ReactQuery.useMutation(
    FINISH_REGISTRATION_KEY,
    finishRegistrationAPI,
    {
      ...options,
      onError: (error, variables, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.failed_to_register',
            defaultMessage:
              'Unfortunately, an error occurred while we tried to register you. Please try again.',
          }),
        });

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },
    }
  );

  return {
    finishRegistration: mutate,
    isLoading,
  };
}
