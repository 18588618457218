import * as ReactQuery from 'react-query';
import { updateReferralStatusAPI } from 'services/referrals/update-referral-status/api';
import { InferMutationOptions, Services } from 'services';
import { ReferralInfoDto, ReferralSearchResultDto } from 'common/api';
import { Store } from 'store';
import * as ReactIntl from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

const UPDATE_REFERRAL_STATUS_KEY = '@referrals/update-referral-status';

export function useUpdateReferralStatus(
  options: InferMutationOptions<typeof updateReferralStatusAPI>,
  refetchHistory: () => void,
  refetchProtocol: () => void
) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();
  const { user } = Store.useUser();

  const intl = ReactIntl.useIntl();

  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    UPDATE_REFERRAL_STATUS_KEY,
    updateReferralStatusAPI,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        captureEventV2(
          Events.InvestigatorService.InvestigatorUpdatedPatientStatus({
            InvestigatorID: user?.id ?? '',
            StatusName: data.status,
            PatientID: data.id ?? '',
          })
        );
        queryClient.setQueriesData(
          Services.Referrals.KEYS.SEARCH_REFERRALS_KEY,
          (currentData) => {
            const referrals = currentData as ReferralSearchResultDto;
            if (referrals) {
              const referralIndex = referrals.results.findIndex(
                (referral) => referral.id === variables.id
              );

              if (referralIndex !== -1) {
                const referral = referrals.results[referralIndex];
                referrals.results[referralIndex] = {
                  ...referral,
                  status: ReferralInfoDto.status[variables.status],
                  appointmentDateTime:
                    variables.status === ReferralStatus.APPOINTMENT_SCHEDULED
                      ? variables.appointmentDate
                      : undefined,
                  isVisited: variables.isVisited,
                  consentedDate:
                    variables.consentedDate || data?.consentedDate || '',
                };
              }
              return referrals;
            }

            return referrals;
          }
        );

        queryClient.setQueriesData(
          [Services.Referrals.KEYS.GET_REFERRAL_KEY, variables.id],
          (currentData: any) => ({
            ...currentData,
            status: ReferralInfoDto.status[variables.status],
            appointmentDateTime:
              variables.status === ReferralStatus.APPOINTMENT_SCHEDULED
                ? variables.appointmentDate
                : undefined,
            isVisited: variables.isVisited,
            consentedDate: variables.consentedDate || data?.consentedDate || '',
          })
        );

        queryClient.resetQueries([
          Services.Referrals.KEYS.GET_REFERRAL_ACTIVITY_KEY,
          variables.id,
        ]);

        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.update_referral_status.success_notification_description',
            defaultMessage: 'Status updated',
          }),
        });

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        refetchHistory();
        refetchProtocol();
      },
      onError: (error, data, context) => {
        const errorMessage = error.isTranslationFromServer
          ? { id: error.translationKey, defaultMessage: error.message }
          : {
              id: 'server.errors.update_referral_status_error',
              defaultMessage:
                "Unfortunately, something failed when updating the patient's status. Please, try again.",
            };
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: errorMessage.id,
            defaultMessage: errorMessage.defaultMessage,
          }),
        });

        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
    }
  );

  return {
    updateStatus: mutate,
    isLoading,
    isError,
  };
}
