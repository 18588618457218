//eslint-disable-next-line
//@ts-nocheck

import React, { ReactNode } from 'react';

export function mapChildrenDeep(
  children: ReactNode,
  fn: (child: ReactNode) => ReactNode
): ReactNode {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: mapChildrenDeep(child.props.children, fn),
      });
    }

    return fn(child);
  });
}
