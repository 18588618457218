import React from 'react';
import { Flex } from '@clariness/flex';

export const MainPageLayout: React.FC = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {children}
    </Flex>
  );
};
