/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';

export type SiteInfoInvestigatorDto = {
    id?: string;
    firstName: string;
    lastName: string;
    position?: string;
    email: string;
    phone?: string;
    warmTransferPhone?: string;
    phone2?: string;
    speciality?: string;
    languageCode: string;
    emailIsVerified?: boolean;
    emailVerifyToken?: string;
    emailVerifyTokenExpirationDate?: string;
    language?: Language;
    title?: SiteInfoInvestigatorDto.title;
    roles: Array<'Principal Investigator' | 'Study Coordinator' | 'Study Nurse' | 'CRA Employee' | 'Other'>;
    contacts: Array<'Main Contact' | 'Contact' | 'No Contact'>;
}

export namespace SiteInfoInvestigatorDto {

    export enum title {
        PROF = 'PROF',
        PROF_DR = 'PROF_DR',
        DR = 'DR',
        MRS = 'MRS',
        MR = 'MR',
        MS = 'MS',
    }


}
