/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SiteEscalationDTO = {
    id?: number;
    date: string;
    level?: SiteEscalationDTO.level;
    details?: string;
    active?: boolean;
    studySiteId: number;
    createdBy?: string;
    updatedBy?: string;
    escalationGroup?: string;
    closeEscalation?: boolean;
}

export namespace SiteEscalationDTO {

    export enum level {
        LEVEL_1_PATIENT_BACKLOG = 'LEVEL_1_PATIENT_BACKLOG',
        LEVEL_2_NO_RESPONSE_FROM_THE_SITE___PATIENT_BACKLOG = 'LEVEL_2_NO_RESPONSE_FROM_THE_SITE_&_PATIENT_BACKLOG',
        LEVEL_3_NO_RESPONSE_FROM_THE_SITE = 'LEVEL_3_NO_RESPONSE_FROM_THE_SITE',
    }


}
