/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SiteUpdateDto = {
    id?: string;
    name: string;
    type: SiteUpdateDto.type;
    department?: string;
    street?: string;
    zipCode: string;
    city: string;
    state: string;
    countryCode: string;
    externalId?: string;
    email?: string;
    website?: string;
    phone?: string;
    parentSiteId?: string;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    timeZone: any;
    externalName?: string;
    externalAddress?: string;
    siteAvailability?: string;
    lat: string;
    long: string;
}

export namespace SiteUpdateDto {

    export enum type {
        UNIVERSITY = 'UNIVERSITY',
        HOSPITAL = 'HOSPITAL',
        VIRTUAL = 'VIRTUAL',
        OTHER = 'OTHER',
        SMO = 'SMO',
        PROFESSIONAL_RESEARCH_SITE = 'PROFESSIONAL_RESEARCH_SITE',
        DOCTORS_PRACTICE = 'DOCTORS_PRACTICE',
    }


}
