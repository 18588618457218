import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import React from 'react';
import { paginationPageStyles } from '../paginationPageStyles';

type PaginationPageProps = {
  pageNumber: number;
  isActive?: boolean;
  onClick?: () => void;
};

export const PaginationPage: React.FC<PaginationPageProps> = ({
  pageNumber,
  isActive,
  onClick,
}) => {
  const activePageStyles = isActive
    ? {
        color: 'primary1',
        backgroundColor: 'hgl7',
        borderColor: 'primary1',

        '&:hover': {
          backgroundColor: 'hgl7',
        },
      }
    : {};

  return (
    <Button variant="base" disabled={isActive} onClick={onClick}>
      <Flex
        sx={{
          ...paginationPageStyles,
          backgroundColor: 'hgl7',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'hgl5',
          fontWeight: 400,

          '&:hover': {
            backgroundColor: 'hgl5',
          },

          ...activePageStyles,
        }}
      >
        {pageNumber}
      </Flex>
    </Button>
  );
};
