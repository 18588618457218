import { FC } from 'react';
import ReactDOM from 'react-dom';
import { ProductUpdateList } from 'components/ProductUpdates/components/ProductUpdateList';
import { ProductUpdateDto } from 'common/api';

type ProductUpdatesProps = {
  productUpdates: ProductUpdateDto[];
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};
export const ProductUpdates: FC<ProductUpdatesProps> = ({
  productUpdates,
  open,
  setOpen,
  refetch,
}) => {
  return (
    document.getElementById('root') &&
    ReactDOM.createPortal(
      <ProductUpdateList
        productUpdates={productUpdates}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />,
      document.getElementById('root') as HTMLElement
    )
  );
};
