import moment from 'moment-timezone';

export const convertDateToTargetTimeZone = (
  date: Date,
  targetZoneName: string
): Date => {
  const currentOffset = -new Date().getTimezoneOffset(); // Timezone offset of the current user in minutes (sign-reversed)
  const targetOffset = moment.tz(targetZoneName).utcOffset();
  const offset = (currentOffset - targetOffset) * 6e4; //offset in milliseconds
  return new Date(date.getTime() + offset);
};
