import React, { useEffect, useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage } from 'react-intl';
import { Box } from '@clariness/box';
import { Button } from '@clariness/button';
import { Dropdown } from '@clariness/dropdown';
import { Services } from 'services';
import { Store } from 'store';
import { changeUrlLocale } from 'utils/routing';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

type Language = {
  label: string;
  value: string;
};

export const AccountLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  const { languages } = Services.SiteEmployees.useGetLanguages();

  const { user } = Store.useUser();

  useEffect(() => {
    if (languages.length) {
      const userLanguage = languages.find(
        (language) => language.code === user?.languageCode
      );
      if (userLanguage) {
        setSelectedLanguage({
          label: userLanguage.name,
          value: userLanguage.code,
        });
      }
    }
  }, [languages, user?.languageCode]);

  const { updateCurrentSiteEmployee, isLoading } =
    Services.SiteEmployees.useUpdateCurrentSiteEmployee({
      onSuccess: (error, data) => {
        captureEventV2(
          Events.InvestigatorService.InvestigatorChangeLanguage({
            InvestigatorID: user?.id ?? '',
          })
        );
        changeUrlLocale(data.languageCode);
      },
    });

  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_language.heading"
          defaultMessage="Language"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Paragraph>
        <FormattedMessage
          id="is.account_language.main_paragraph"
          defaultMessage="You can update your language settings here."
        />
      </AccountPageLayout.Paragraph>

      <AccountPageLayout.Form
        onSubmit={() =>
          updateCurrentSiteEmployee({ languageCode: selectedLanguage?.value })
        }
      >
        <Box sx={{ width: '270px' }}>
          <Dropdown
            value={selectedLanguage?.value}
            onChange={(language) =>
              setSelectedLanguage({ value: language, label: language })
            }
            data-test-id="account-language-select"
          >
            {languages.map((language) => (
              <Dropdown.Option key={language.code} value={language.code}>
                {language.name}
              </Dropdown.Option>
            ))}
          </Dropdown>
        </Box>

        <Button
          type="submit"
          disabled={
            !selectedLanguage || selectedLanguage?.value === user?.languageCode
          }
          loading={isLoading}
          data-test-id="language-save-button"
        >
          <FormattedMessage
            id="is.account_language.save_language_button"
            defaultMessage="Save"
          />
        </Button>
      </AccountPageLayout.Form>
    </AccountPageLayout>
  );
};
