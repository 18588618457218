/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SitePerformanceActivityDto = {
    id?: number;
    date?: string;
    action?: SitePerformanceActivityDto.action;
    responsiblePerson: string;
    active: boolean;
}

export namespace SitePerformanceActivityDto {

    export enum action {
        OPT_IN = 'OPT_IN',
        OPT_OUT = 'OPT_OUT',
        TRAINING = 'TRAINING',
        BYEBYE = 'BYEBYE',
    }


}
