import * as _ from 'lodash-es';
import { getUrlLocale } from 'utils/routing';

const appendPath = (...paths: (string | number | undefined)[]) => {
  const urlLocale = getUrlLocale();
  return _.join([urlLocale && `/${urlLocale}`, ...paths], '/');
};

const BASE_URL = {
  getPath: (): string => appendPath(''),
};

const HOME = {
  getPath: (): string => appendPath('referrals'),
};

const REFERRAL_DETAILS = {
  getPath: (id: string): string => appendPath('referral', id),
};

const LOGIN = {
  getPath: (): string => appendPath('login'),
};

const REGISTER = {
  getPath: (): string => appendPath('register'),
};

const ACCOUNT = {
  path: 'account',
  getPath: (): string => appendPath(ACCOUNT.path),
};

const ACCOUNT_DETAILS = {
  getPath: (): string => appendPath(ACCOUNT.path, 'details'),
};

const ACCOUNT_LANGUAGE = {
  getPath: (): string => appendPath(ACCOUNT.path, 'language'),
};

const FORGOT_PASSWORD = {
  getPath: (): string => appendPath('forgot-password'),
};

const CHANGE_PASSWORD = {
  getPath: (): string => appendPath('change-password'),
};

const ACCOUNT_NOTIFICATION = {
  getPath: (): string => appendPath(ACCOUNT.path, 'notification'),
};

export const Routing = {
  BASE_URL,
  HOME,
  REFERRAL_DETAILS,
  LOGIN,
  REGISTER,
  ACCOUNT,
  ACCOUNT_DETAILS,
  ACCOUNT_LANGUAGE,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  ACCOUNT_NOTIFICATION,
};
