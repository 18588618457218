/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvestigatorDto } from './InvestigatorDto';
import type { ProtocolDto } from './ProtocolDto';

export type SiteCallbackTimeslotDto = {
    id?: number;
    studySiteId?: number;
    start?: string;
    end?: string;
    status?: SiteCallbackTimeslotDto.status;
    callType?: SiteCallbackTimeslotDto.callType;
    investigator?: InvestigatorDto;
    protocol?: ProtocolDto;
}

export namespace SiteCallbackTimeslotDto {

    export enum status {
        INACTIVE = 'INACTIVE',
        ACTIVE = 'ACTIVE',
    }

    export enum callType {
        OPT_IN = 'OPT_IN',
        TRAINING = 'TRAINING',
        FOLLOW_UP = 'FOLLOW_UP',
    }


}
