export const breakpoints = ['1366px'];

export const breakpointsToArrayIndex: Record<keyof Breakpoints, number> = {
  _: 0, // 0px
  sm: 1, // 1366px
};

export interface Breakpoints {
  _?: any;
  sm?: any;
}
