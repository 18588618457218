/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PatientDto } from './PatientDto';
import type { PatientScreenerDto } from './PatientScreenerDto';
import type { ScreenerDto } from './ScreenerDto';

export type ReferralMbDto = {
    id?: string;
    status: ReferralMbDto.status;
    siteId: string;
    studyId: string;
    /**
     * Optimistic concurrency check
     */
    sourceVersion?: number;
    consentText: string;
    domain: string;
    /**
     * Which language to use for email
     */
    languageCode: string;
    screener: ScreenerDto;
    patientScreener: PatientScreenerDto;
    patient: PatientDto;
    referralDate?: string;
    consentDateTime: string;
    registrationDate: string;
    postalCode?: string;
    locationBasedOnPostalCode?: string;
    distanceToPatientInKM?: number;
    yearOfBirth?: number;
    gender?: ReferralMbDto.gender;
    /**
     * Device ID from Posthog
     */
    deviceId?: string;
}

export namespace ReferralMbDto {

    export enum status {
        NEW_REFERRAL = 'NEW_REFERRAL',
        ATTEMPTING_TO_CONTACT = 'ATTEMPTING_TO_CONTACT',
        CONTACTED = 'CONTACTED',
        CONTACTED__MEDICAL_RECORDS = 'CONTACTED__MEDICAL_RECORDS',
        CONTACTED__WASHOUT_PERIOD = 'CONTACTED__WASHOUT_PERIOD',
        CONTACTED__PATIENT_AVAILABILITY = 'CONTACTED__PATIENT_AVAILABILITY',
        CONTACTED__CALLBACK = 'CONTACTED__CALLBACK',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        WAITING_FOR_INFORMATION = 'WAITING_FOR_INFORMATION',
        WAITING_FOR_INFORMATION__MEDICAL_RECORD = 'WAITING_FOR_INFORMATION__MEDICAL_RECORD',
        WAITING_FOR_INFORMATION__WASHOUT_PERIOD = 'WAITING_FOR_INFORMATION__WASHOUT_PERIOD',
        WAITING_FOR_INFORMATION__WAITING_FOR_ICF = 'WAITING_FOR_INFORMATION__WAITING_FOR_ICF',
        CONSENTED = 'CONSENTED',
        RANDOMIZED = 'RANDOMIZED',
        SCREENING_FAILURE = 'SCREENING_FAILURE',
        DROPPED = 'DROPPED',
        DROPPED__UNABLE_TO_REACH = 'DROPPED__UNABLE_TO_REACH',
        DROPPED__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED__NOT_INTERESTED = 'DROPPED__NOT_INTERESTED',
        DROPPED__BAD_OR_DUPLICATE_PROFILE = 'DROPPED__BAD_OR_DUPLICATE_PROFILE',
        DROPPED__TOO_FAR_AWAY = 'DROPPED__TOO_FAR_AWAY',
        DROPPED__OTHER = 'DROPPED__OTHER',
        DROPPED__NO_SHOW = 'DROPPED__NO_SHOW',
        IN_CALL_CENTER = 'IN_CALL_CENTER',
        ON_HOLD_IN_CALL_CENTER = 'ON_HOLD_IN_CALL_CENTER',
        DROPPED_BY_CC = 'DROPPED_BY_CC',
        DROPPED_BY_CC__UNABLE_TO_REACH = 'DROPPED_BY_CC__UNABLE_TO_REACH',
        DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED_BY_CC__NOT_INTERESTED = 'DROPPED_BY_CC__NOT_INTERESTED',
        DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE = 'DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE',
        DROPPED_BY_CC__TOO_FAR_AWAY = 'DROPPED_BY_CC__TOO_FAR_AWAY',
        DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT = 'DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT',
        DROPPED_BY_CC__OTHER = 'DROPPED_BY_CC__OTHER',
        RELOCATION = 'RELOCATION',
    }

    export enum gender {
        M = 'M',
        F = 'F',
        U = 'U',
    }


}
