import React from 'react';
import { createStoreProvider } from 'utils/context';
import * as _ from 'lodash-es';
import { NotificationProps } from '@clariness/notification';

function useNotificationsState() {
  const [notifications, setNotifications] = React.useState<NotificationProps[]>(
    []
  );

  return {
    notifications,
    showNotification: (notification: Omit<NotificationProps, 'id'>) =>
      setNotifications((notifications) => [
        ...notifications,
        {
          ...notification,
          id: _.uniqueId('notification'),
        },
      ]),
    removeNotification: (id: string) =>
      setNotifications((s) =>
        s.filter((notification) => notification.id !== id)
      ),
  };
}

export const [useNotifications, NotificationsProvider] = createStoreProvider(
  useNotificationsState
);
