import React from 'react';
import { ModalProvider } from './modals';
import { NotificationsProvider } from './notifications';
import { HomePageStoreProvider } from './homePage';
import { UserProvider } from './user';

export const StoreProvider: React.FC = ({ children }) => (
  <UserProvider>
    <ModalProvider>
      <NotificationsProvider>
        <HomePageStoreProvider>{children}</HomePageStoreProvider>
      </NotificationsProvider>
    </ModalProvider>
  </UserProvider>
);
