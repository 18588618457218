import React, { PropsWithChildren } from 'react';
import { Flex } from '@clariness/flex';
import {
  AccountPageLayoutForm,
  AccountPageLayoutHeading,
  AccountPageLayoutParagraph,
} from './components';

export const AccountPageLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '654px',
      }}
    >
      {children}
    </Flex>
  );
};

AccountPageLayout.Heading = AccountPageLayoutHeading;
AccountPageLayout.Paragraph = AccountPageLayoutParagraph;
AccountPageLayout.Form = AccountPageLayoutForm;
