import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { getCurrentSiteEmployeeAPI } from './api';

const GET_CURRENT_SITE_EMPLOYEE_KEY =
  '@site-employees/get-current-site-employee';

export function useGetCurrentSiteEmployee({
  enabled,
  onSuccess,
  onError,
}: ReactQuery.UseQueryOptions) {
  const { setUser } = Store.useUser();

  const { isLoading, isError } = ReactQuery.useQuery(
    GET_CURRENT_SITE_EMPLOYEE_KEY,
    getCurrentSiteEmployeeAPI,
    {
      onSuccess: (data) => {
        setUser(data);

        if (onSuccess) {
          onSuccess(data);
        }
      },
      enabled,
      onError: (error) => {
        if (onError) {
          onError(error);
        }
      },
    }
  );

  return {
    isLoading,
    isError,
  };
}
