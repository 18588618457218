/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CallbackTimeslotDto = {
    id?: number;
    start?: string;
    end?: string;
    status?: CallbackTimeslotDto.status;
    systemSelected?: boolean;
    siteSelected?: boolean;
    updatedBy?: string;
}

export namespace CallbackTimeslotDto {

    export enum status {
        INACTIVE = 'INACTIVE',
        ACTIVE = 'ACTIVE',
        PENDING = 'PENDING',
    }


}
