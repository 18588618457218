import {AnswerValueDto, QuestionDto, ReScreenerQuestionDto} from '../api';
import { AnswerValueType } from '../types/answer-value-type.enum';

export function getAnswerValue(question: QuestionDto | ReScreenerQuestionDto | any, answer: AnswerValueDto) {
  if (question.type === QuestionDto.type.BMI) {
    switch (answer.type) {
    case AnswerValueType.WEIGHT_LB:
      return [answer.value, '(lbs)'].join(' ');
    case AnswerValueType.WEIGHT_KG:
      return [answer.value, '(kg)'].join(' ');
    case AnswerValueType.HEIGHT_CM:
      return [answer.value, '(cm)'].join(' ');
    case AnswerValueType.HEIGHT_FT:
      return answer.value;
    }
  }
  return answer.value
      || question.choices?.find(choice => choice.id === answer.questionChoiceId)?.choice
      || question.reScreenerQuestionChoices?.find(choice => choice.id === answer.questionChoiceId)?.choice;
}