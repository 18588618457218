import React from 'react';
import { Store } from 'store';
import { ArrowIcon } from '@clariness/icon';
import { Button } from '@clariness/button';
import { FormattedMessage } from 'react-intl';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { Box } from '@clariness/box';
import { usePatientDetailsContext } from '../../ReferralDetails';

export const PatientDetailsFooter: React.FC = () => {
  const { currentReferral } = usePatientDetailsContext();

  const { openModal } = Store.useModal();

  const { redirect } = useRedirect();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: '4',
        bottom: '0',
        width: '100%',
        left: 0,
        height: '112px',
        background: 'white',
        borderTop: '2px solid #D9D9D9',
        px: '110px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        size="medium"
        variant="tertiary"
        onClick={() => {
          redirect(Routing.HOME.getPath());
        }}
        icon={<ArrowIcon direction="left" />}
      >
        <FormattedMessage
          id="is.patient_details.back_to_patient_list"
          defaultMessage="Back to Patient List"
        />
      </Button>
      <Button
        size="medium"
        onClick={() => {
          openModal({
            modalType: 'UpdatePatientStatus',
            modalProps: {
              id: currentReferral?.id || '',
              status: currentReferral?.status,
              appointmentDateTime: currentReferral?.appointmentDateTime,
              referralDate: currentReferral?.referralDate,
              currentConsentedDate: currentReferral?.consentedDate,
              isVisited: currentReferral?.isVisited,
            },
          });
        }}
      >
        <FormattedMessage
          id="is.patient_details_modal.footer.update_status_button"
          defaultMessage="Update Status"
        />
      </Button>
    </Box>
  );
};
