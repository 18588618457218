import React from 'react';
import { Button, ButtonProps } from '@clariness/button';

type SidebarNavButtonProps = ButtonProps;

export const SidebarNavButton = ({
  children,
  sx,
  ...props
}: SidebarNavButtonProps) => {
  return (
    <Button
      variant="tertiary"
      size="medium"
      sx={{
        px: '34px',
        mb: '32px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
