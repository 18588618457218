/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSiteCallbackTimeslotDto = {
    id?: number;
    start?: string;
    end?: string;
    status?: CreateSiteCallbackTimeslotDto.status;
    callType?: CreateSiteCallbackTimeslotDto.callType;
    investigator?: string;
}

export namespace CreateSiteCallbackTimeslotDto {

    export enum status {
        INACTIVE = 'INACTIVE',
        ACTIVE = 'ACTIVE',
    }

    export enum callType {
        OPT_IN = 'OPT_IN',
        TRAINING = 'TRAINING',
        FOLLOW_UP = 'FOLLOW_UP',
    }


}
