import React from 'react';
import {
  ReferralActivity,
  ReferralActivityType,
} from 'global/referralActivity';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { FormattedMessage } from 'react-intl';
import { parseReferralStatus, referralStatusMapper } from 'utils/format';
import { Text } from '@clariness/text';

type EventDetails = {
  note?: string;
  referralStatus?: ReferralStatusType;
  scheduledDate?: string;
  timeZone: any;
};

export function displayActivityEvent(
  event: ReferralActivityType,
  eventDetails: EventDetails
) {
  switch (event) {
    case ReferralActivity.STATUS_CHANGE: {
      const status = eventDetails.referralStatus;
      const parentStatus = parseReferralStatus(status);

      const scheduledDate = new Date(eventDetails.scheduledDate || '');

      return status === ReferralStatus.APPOINTMENT_SCHEDULED ? (
        <div style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage
            id="is.patient_details_modal.activity.appointment_scheduled_status_update"
            defaultMessage={`Status update - { parentStatus } { status }
                 Scheduled (on { date } at {time} )`}
            values={{
              parentStatus:
                parentStatus && parentStatus !== status ? (
                  <React.Fragment>
                    {referralStatusMapper[parentStatus].description}:
                  </React.Fragment>
                ) : (
                  ''
                ),
              status: eventDetails.referralStatus
                ? referralStatusMapper[eventDetails.referralStatus].description
                : '',
              date: `${scheduledDate.toLocaleDateString('de-DE', {
                timeZone: eventDetails.timeZone?.zoneName,
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })} `,
              time: `${scheduledDate.toLocaleTimeString('de-DE', {
                timeZone: eventDetails.timeZone?.zoneName,
                hour: 'numeric',
                minute: 'numeric',
              })} ${eventDetails.timeZone?.zoneName}`,
            }}
          />
        </div>
      ) : (
        <FormattedMessage
          id="is.patient_details_modal.activity.status_update"
          defaultMessage="Status update - { parentStatus } { status }"
          values={{
            parentStatus:
              parentStatus && parentStatus !== status ? (
                <React.Fragment>
                  {referralStatusMapper[parentStatus].description}:
                </React.Fragment>
              ) : (
                ''
              ),
            status: eventDetails.referralStatus
              ? referralStatusMapper[eventDetails.referralStatus]?.description
              : '',
          }}
        />
      );
    }
    case ReferralActivity.NOTE_CHANGE: {
      return eventDetails.note ? (
        <FormattedMessage
          id="is.patient_details_modal.activity.note_edit"
          defaultMessage="Note edited: <p>{ note }</p>"
          values={{
            note: eventDetails.note,
            p: (message: string) => (
              <Text
                sx={{
                  color: 'inherit',
                  fontStyle: 'italic',
                }}
              >
                {message}
              </Text>
            ),
          }}
        />
      ) : (
        <Text
          sx={{
            color: 'inherit',
            textDecoration: 'line-through',
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.activity.note_deleted"
            defaultMessage="Note deleted"
          />
        </Text>
      );
    }
    case ReferralActivity.FORWARD_TO_SITE: {
      return (
        <FormattedMessage
          id="is.patient_details_modal.activity.forward_to_site"
          defaultMessage="Referral forwarded to site"
        />
      );
    }
    default:
      return null;
  }
}
