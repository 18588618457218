import { useModal } from './modals';
import { useUser } from './user';
import { useNotifications } from './notifications';
import { useHomePageStore } from './homePage';

export * from './StoreProvider';

export const Store = {
  useModal,
  useUser,
  useNotifications,
  useHomePageStore,
};
