import * as ReactQuery from 'react-query';
import { InferMutationOptions, Services } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { updateReferralProtocol801ParticipationAPI } from './api';

const UPDATE_PROTOCOL_801_KEY = '@referrals/update-protocol-801-participation';

export function useUpdateProtocol801Participation(
  options?: InferMutationOptions<
    typeof updateReferralProtocol801ParticipationAPI
  >
) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    UPDATE_PROTOCOL_801_KEY,
    updateReferralProtocol801ParticipationAPI,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueriesData(
          [Services.Referrals.KEYS.GET_REFERRAL_KEY, variables],
          (currentData: any) => ({
            ...currentData,
          })
        );

        queryClient.resetQueries([
          Services.Referrals.KEYS.GET_REFERRAL_ACTIVITY_KEY,
          variables,
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
      onError: (error, data, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.update_referral_note_error',
            defaultMessage:
              "Unfortunately, something failed when updating the patient's notes. Please, try again.",
          }),
        });

        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
    }
  );

  return {
    Protocol801Participation: mutate,
    isLoading,
    isError,
  };
}
