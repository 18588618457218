import React, { Dispatch, SetStateAction, useState } from 'react';
import { createStoreProvider } from 'utils/context';
import {
  ReferralSearchFilterDto,
  ReferralSearchPaginationDto,
} from 'common/api';
import { MAX_REFERRALS_ROWS } from 'pages/Home/Home';
import * as ReactTable from 'react-table';

type SelectedStudy = {
  id: string;
  name: string;
};

type FilterTags = {
  selectedStudies: SelectedStudy[];
};

function useHomePageContext(): {
  localFilterOptions: ReferralSearchFilterDto;
  filterOptions: ReferralSearchFilterDto;
  filterTags: FilterTags;
  defaultPaginationOptions: ReferralSearchPaginationDto;
  selectedRows: ReactTable.Row[];
  unSelectedRowIds: string[];
  selectedRowIds: string[];
  paginationOptions: ReferralSearchPaginationDto;
  setSelectedRows: Dispatch<SetStateAction<ReactTable.Row[]>>;
  setUnselectedRowIds: Dispatch<SetStateAction<string[]>>;
  setselectedRowIds: Dispatch<SetStateAction<string[]>>;
  setFilterOptions: Dispatch<SetStateAction<ReferralSearchFilterDto>>;
  setFilterTags: Dispatch<SetStateAction<FilterTags>>;
  setPaginationOptions: Dispatch<SetStateAction<ReferralSearchPaginationDto>>;
  setLocalFilterOptions: Dispatch<SetStateAction<ReferralSearchFilterDto>>;
  isFilterCleared: boolean;
  setIsFilterCleared: Dispatch<SetStateAction<boolean>>;
} {
  const defaultPaginationOptions = {
    take: MAX_REFERRALS_ROWS,
    skip: 0,
    sorting: [],
  };

  const [localFilterOptions, setLocalFilterOptions] =
    React.useState<ReferralSearchFilterDto>({});
  const [selectedRows, setSelectedRows] = useState<ReactTable.Row[]>([]);
  const [unSelectedRowIds, setUnselectedRowIds] = useState<string[]>([]);
  const [selectedRowIds, setselectedRowIds] = useState<string[]>([]);
  const [filterTags, setFilterTags] = useState<FilterTags>({
    selectedStudies: [],
  });
  const [filterOptions, setFilterOptions] = useState<ReferralSearchFilterDto>(
    {}
  );
  const [paginationOptions, setPaginationOptions] =
    useState<ReferralSearchPaginationDto>(defaultPaginationOptions);
  const [isFilterCleared, setIsFilterCleared] = useState<boolean>(false);

  return {
    localFilterOptions,
    setLocalFilterOptions,
    selectedRows,
    setSelectedRows,
    unSelectedRowIds,
    setUnselectedRowIds,
    selectedRowIds,
    setselectedRowIds,
    filterTags,
    setFilterTags,
    filterOptions,
    setFilterOptions,
    paginationOptions,
    setPaginationOptions,
    defaultPaginationOptions,
    isFilterCleared,
    setIsFilterCleared,
  };
}

export const [useHomePageStore, HomePageStoreProvider] =
  createStoreProvider(useHomePageContext);
