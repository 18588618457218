import React from 'react';
import { NavLink, NavLinkProps } from 'components/links';

type SidebarNavLinkProps = NavLinkProps;

export const SidebarNavLink = ({
  children,
  sx,
  activeStyles,
  ...props
}: SidebarNavLinkProps) => {
  return (
    <NavLink
      activeStyles={{
        backgroundColor: 'hgl7',
        fontWeight: 700,
        ...activeStyles,
      }}
      sx={{
        width: '100%',
        py: '18px',
        px: '34px',
        textDecoration: 'none',

        '&, &:active, &:visited': {
          color: 'text1',
        },

        '&:hover, &:focus': {
          backgroundColor: 'hgl7',
          textDecoration: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </NavLink>
  );
};
