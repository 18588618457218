import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const screenFailureMainStatuses = {
  [ReferralStatus.RANDOMIZED]: {
    title: (
      <FormattedMessage
        id="is.referral_status.icf_signed_randomized.title"
        defaultMessage="Randomized"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.icf_signed_randomized.description"
        defaultMessage="Patient has been accepted into the clinical study."
      />
    ),
  },
};
