import { useIntl } from 'react-intl';
import { Protocol801ParticipationUpdateDto } from 'common/api';

export function FormatStatus(
  status: Protocol801ParticipationUpdateDto.outcome
) {
  const intl = useIntl();

  switch (status) {
    case Protocol801ParticipationUpdateDto.outcome.CONSENTED_RANDOMIZED:
      return `${intl.formatMessage({
        id: 'is.referral_status.consented',
        defaultMessage: 'Consented',
      })} - ${intl.formatMessage({
        id: 'is.referral_status.icf_signed_randomized',
        defaultMessage: 'Randomized',
      })}`;
    case Protocol801ParticipationUpdateDto.outcome.CONSENTED_SCREEN_FAILIURE:
      return `${intl.formatMessage({
        id: 'is.referral_status.consented',
        defaultMessage: 'Consented',
      })} - ${intl.formatMessage({
        id: 'is.referral_status.icf_signed_screening_failure',
        defaultMessage: 'Screening Failure',
      })}`;
    case Protocol801ParticipationUpdateDto.outcome.DID_NOT_CONSENT:
      return `${intl.formatMessage({
        id: 'is.referral_status.did_not_consent',
        defaultMessage: 'Did-Not-Consent',
      })}`;
    default:
      return '';
  }
}
