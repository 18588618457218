import { postRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { getRefreshToken } from 'utils/auth';

type RefreshTokenResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
};

export async function refreshAccessTokenAPI(): Promise<RefreshTokenResponse> {
  return postRequest(`${Environment.AUTH0_API}/oauth/token`, {
    grant_type: 'refresh_token',
    client_id: Environment.AUTH0_CLIENT,
    refresh_token: getRefreshToken(),
  });
}
