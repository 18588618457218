import React, { useCallback } from 'react';
import { createStoreProvider } from 'utils/context';
import { ModalState, ModalTypes } from 'store/modals/types';

function useModalState() {
  const initialState = {
    modalType: null,
    modalProps: undefined,
  };

  const [modalState, setModalState] =
    React.useState<ModalState<ModalTypes>>(initialState);

  const openModal = useCallback(
    <T extends ModalTypes>(modalOptions: ModalState<T>) => {
      setModalState(modalOptions);
    },
    []
  );

  return {
    modalType: modalState.modalType,
    modalProps: modalState.modalProps,
    openModal,
    closeModal: () => setModalState(initialState),
  };
}

export const [useModal, ModalProvider] = createStoreProvider(useModalState);
