// context/SocketContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { logout } from 'utils/auth';
import { Environment } from 'global/environment';

interface ISocketContext {
  socket: Socket | null;
  message: any;
}

const SocketContext = createContext<ISocketContext>({
  socket: null,
  message: null,
});

export const useSocket = () => {
  return useContext(SocketContext);
};

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [message] = useState<any>(null);

  useEffect(() => {
    const socketIo = io(`${Environment.BACKEND_API}`, {
      transports: ['websocket'],
    });

    setSocket(socketIo);

    socketIo.on('connect', () => {
      console.log('Connected to socket server');
    });

    socketIo.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    socketIo.on('logoutUser', (data) => {
      console.log('Received data:', data);
      logout();
    });

    return () => {
      socketIo.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, message }}>
      {children}
    </SocketContext.Provider>
  );
};
