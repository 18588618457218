import { serviceRequest } from 'utils/http';
import { CallbackTimeslotDto, Service } from 'common/api';

type UpdateCallbackTime = {
  referralId: string;
  callbackTimeslotDto: CallbackTimeslotDto;
};

export function updateCallbackTimeslotAPI(
  updateCallbackTimeDto: UpdateCallbackTime
) {
  return serviceRequest(() =>
    Service.callbackTimeslotControllerCreateCallbackTimeslot(
      updateCallbackTimeDto.referralId,
      {
        ...updateCallbackTimeDto.callbackTimeslotDto,
        siteSelected: true,
        systemSelected: false,
      }
    )
  );
}
