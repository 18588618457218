/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ReScreenerQuestionChoiceDto } from './ReScreenerQuestionChoiceDto';

export type ReScreenerQuestionDto = {
    id: number;
    question: string;
    order: number;
    pageNo: number;
    type: ReScreenerQuestionDto.type;
    visibleToInvestigators: boolean;
    reScreenerQuestionChoices: Array<ReScreenerQuestionChoiceDto>;
}

export namespace ReScreenerQuestionDto {

    export enum type {
        TEXT = 'TEXT',
        NUMBER = 'NUMBER',
        POSTAL_CODE = 'POSTAL_CODE',
        GENDER = 'GENDER',
        CHECKBOX_LIST = 'CHECKBOX_LIST',
        RADIO_LIST = 'RADIO_LIST',
        YEAR_OF_BIRTH = 'YEAR_OF_BIRTH',
        DATE_OF_BIRTH = 'DATE_OF_BIRTH',
        BSA_SCORE = 'BSA_SCORE',
        BMI = 'BMI',
        MIN_AMOUNT_ANSWERS = 'MIN_AMOUNT_ANSWERS',
    }


}
