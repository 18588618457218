import React from 'react';
import { Box } from '@clariness/box';
import { StyleObject } from '@clariness/base';

type DividerProps = {
  sx?: StyleObject;
};

export const Divider: React.FC<DividerProps> = ({ sx }) => {
  return (
    <Box
      sx={{
        width: '1px',
        backgroundColor: 'hgl2',
        ...sx,
      }}
    />
  );
};
