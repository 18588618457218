import * as ReactQuery from 'react-query';
import { getReferralHistoryAPI } from './api';

export const GET_REFERRAL_HISTORY_KEY =
  '@referrals/get-referral-history-activity';

export function useGetReferralHistory(id = '') {
  const { data, isFetching, isError, refetch } = ReactQuery.useQuery(
    [GET_REFERRAL_HISTORY_KEY, id],
    () => getReferralHistoryAPI(id)
  );

  return {
    history: data,
    isFetching,
    isError,
    refetch,
  };
}
