import React from 'react';
import { IconProps } from '@clariness/icon';
import { Icon } from '@clariness/clarikit-react';
import { BellIcon } from '@clariness/clarikit-icons';
import { Box } from 'theme-ui';

type BellIconProps = IconProps & {
  counter?: number;
};
export const Bell = ({counter = 0}: BellIconProps) => {
  return (
    <Box sx={{ position: 'relative' }} ml={1} mt={2}>
      <Icon source={BellIcon} />
      <Box
        sx={{
          backgroundColor: 'red-alert',
          color: 'text-inverted',
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          position: 'absolute',
          right: '-3px',
          top: '-5px',
          fontSize: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {counter}
      </Box>
    </Box>
  );
};
