/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CallbackTimeslotDto } from './CallbackTimeslotDto';
import type { LanguageDto } from './LanguageDto';
import type { PatientInfoDto } from './PatientInfoDto';

export type ReferralInfoDto = {
    id?: string;
    studyId?: string;
    status: ReferralInfoDto.status;
    referralDate: string;
    registrationDate: string;
    appointmentDateTime?: string;
    patient: PatientInfoDto;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    studyName?: string;
    callbackTime?: CallbackTimeslotDto;
    lockDate: string;
    lockedBy: string;
    language?: LanguageDto;
    consentedDate: string;
    isVisited: boolean;
    siteTimeZone?: any;
    lastStatusUpdateDate: string;
    trackingId?: number;
    lastUpdate?: string;
    recontactMark?: boolean;
}

export namespace ReferralInfoDto {

    export enum status {
        NEW_REFERRAL = 'NEW_REFERRAL',
        ATTEMPTING_TO_CONTACT = 'ATTEMPTING_TO_CONTACT',
        CONTACTED = 'CONTACTED',
        CONTACTED__MEDICAL_RECORDS = 'CONTACTED__MEDICAL_RECORDS',
        CONTACTED__WASHOUT_PERIOD = 'CONTACTED__WASHOUT_PERIOD',
        CONTACTED__PATIENT_AVAILABILITY = 'CONTACTED__PATIENT_AVAILABILITY',
        CONTACTED__CALLBACK = 'CONTACTED__CALLBACK',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        WAITING_FOR_INFORMATION = 'WAITING_FOR_INFORMATION',
        WAITING_FOR_INFORMATION__MEDICAL_RECORD = 'WAITING_FOR_INFORMATION__MEDICAL_RECORD',
        WAITING_FOR_INFORMATION__WASHOUT_PERIOD = 'WAITING_FOR_INFORMATION__WASHOUT_PERIOD',
        WAITING_FOR_INFORMATION__WAITING_FOR_ICF = 'WAITING_FOR_INFORMATION__WAITING_FOR_ICF',
        CONSENTED = 'CONSENTED',
        RANDOMIZED = 'RANDOMIZED',
        SCREENING_FAILURE = 'SCREENING_FAILURE',
        DROPPED = 'DROPPED',
        DROPPED__UNABLE_TO_REACH = 'DROPPED__UNABLE_TO_REACH',
        DROPPED__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED__NOT_INTERESTED = 'DROPPED__NOT_INTERESTED',
        DROPPED__BAD_OR_DUPLICATE_PROFILE = 'DROPPED__BAD_OR_DUPLICATE_PROFILE',
        DROPPED__TOO_FAR_AWAY = 'DROPPED__TOO_FAR_AWAY',
        DROPPED__OTHER = 'DROPPED__OTHER',
        DROPPED__NO_SHOW = 'DROPPED__NO_SHOW',
        IN_CALL_CENTER = 'IN_CALL_CENTER',
        ON_HOLD_IN_CALL_CENTER = 'ON_HOLD_IN_CALL_CENTER',
        DROPPED_BY_CC = 'DROPPED_BY_CC',
        DROPPED_BY_CC__UNABLE_TO_REACH = 'DROPPED_BY_CC__UNABLE_TO_REACH',
        DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED_BY_CC__NOT_INTERESTED = 'DROPPED_BY_CC__NOT_INTERESTED',
        DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE = 'DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE',
        DROPPED_BY_CC__TOO_FAR_AWAY = 'DROPPED_BY_CC__TOO_FAR_AWAY',
        DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT = 'DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT',
        DROPPED_BY_CC__OTHER = 'DROPPED_BY_CC__OTHER',
        RELOCATION = 'RELOCATION',
    }


}
