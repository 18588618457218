import { Properties } from 'posthog-js';
import { captureEventV2 } from './captureEvent';

let EVENT_QUEUE = [] as Event[];

type Event = {
  eventName?: string;
  properties?: Properties;
};

export function addToEventQueue(event: Event): void {
  EVENT_QUEUE.push(event);
}

export function flushEventQueue(): void {
  EVENT_QUEUE.forEach((event) =>
    captureEventV2({ EventName: event.eventName, ...event.properties })
  );
  EVENT_QUEUE = [];
}
