/**
 * Function which calculates which pages to be shown in the Table Pagination based on current selected page
 * @param currentPageIndex current selected page
 * @param pageCount total number of pages
 * @return array containing values as page indexes and values with isCollapsed: true to show either page numbers or ...
 * @example 1 ... 3 (4) 5 ... 8
 */
export function getPaginationPages(
  currentPageIndex: number,
  pageCount: number
) {
  const paginationPages = [];

  const firstPageIndex = 0;
  const prevPageIndex = currentPageIndex - 1;
  const nextPageIndex = currentPageIndex + 1;
  const lastPageIndex = pageCount - 1;

  // always show first page
  paginationPages.push({ key: firstPageIndex, pageIndex: firstPageIndex });

  // show ... if there is more than 1 page before current page
  if (currentPageIndex - 2 > firstPageIndex) {
    paginationPages.push({
      key: 'previousCollapsedPages',
      isCollapsed: true,
      pageIndex: Infinity,
    });
  }

  // if there is a page before current page, show it
  if (prevPageIndex > firstPageIndex) {
    paginationPages.push({ key: prevPageIndex, pageIndex: prevPageIndex });
  }

  // show current selected page
  if (currentPageIndex > firstPageIndex) {
    paginationPages.push({
      key: currentPageIndex,
      pageIndex: currentPageIndex,
    });
  }

  // if there is a page after current page, show it
  if (nextPageIndex < lastPageIndex) {
    paginationPages.push({ key: nextPageIndex, pageIndex: nextPageIndex });
  }

  // show ... if there is more than 1 page after current page
  if (currentPageIndex + 2 < lastPageIndex) {
    paginationPages.push({
      key: 'nextCollapsedPages',
      isCollapsed: true,
      pageIndex: Infinity,
    });
  }

  // always show last page
  if (
    !paginationPages.find(
      (paginationPage) => paginationPage.pageIndex === lastPageIndex
    )
  ) {
    paginationPages.push({ key: lastPageIndex, pageIndex: lastPageIndex });
  }

  return paginationPages;
}
