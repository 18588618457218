import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { updateShowNotification } from './api';

const UPDATE_SHOW_NOTIFICATION_KEY = '@site-employees/update-show-notification';

export function useUpdateShowNotification(
  options?: InferMutationOptions<typeof updateShowNotification>
) {
  const { showNotification } = Store.useNotifications();
  const intl = useIntl();
  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    UPDATE_SHOW_NOTIFICATION_KEY,
    updateShowNotification,
    {
      ...options,
      onError: (error, variables, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id:' server.errors.update_account_details',
            defaultMessage: 'Unfortunately, an error occurred while we tried to save your updates. Please try again.',
          }),
        });

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },

      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'server.success.update',
            defaultMessage: 'Data saved successfully.',
          }),
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );

  return {
    updateShowNotification: mutate,
    isLoading,
    isError,
  };
}
