/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReferralUpdateDto = {
    id?: string;
    ageAtTimeOfRegister?: number;
    gender?: ReferralUpdateDto.gender;
    firstName?: string;
    lastName?: string;
    availability?: string;
}

export namespace ReferralUpdateDto {

    export enum gender {
        M = 'M',
        F = 'F',
        U = 'U',
    }


}
