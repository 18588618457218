import React from 'react';
import { Link as StyledLink, LinkProps } from '@clariness/link';

export type ExternalLinkProps = LinkProps;

export const ExternalLink = ({ children, to, ...props }: ExternalLinkProps) => {
  return (
    <StyledLink
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      sx={{
        color: 'text1',
        textDecoration: 'none',

        '&:hover': {
          color: 'text1',
        },
      }}
    >
      {children}
    </StyledLink>
  );
};
