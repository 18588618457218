/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { QuestionChoiceDto } from './QuestionChoiceDto';
import type { ScreenerMediaAssetDto } from './ScreenerMediaAssetDto';

export type QuestionDto = {
    id?: number;
    question: string;
    comment?: string;
    order: number;
    pageNo: number;
    type: QuestionDto.type;
    isOffline: boolean;
    visibleToInvestigators: boolean;
    choices: Array<QuestionChoiceDto>;
    mediaAsset?: ScreenerMediaAssetDto;
}

export namespace QuestionDto {

    export enum type {
        TEXT = 'TEXT',
        NUMBER = 'NUMBER',
        POSTAL_CODE = 'POSTAL_CODE',
        GENDER = 'GENDER',
        CHECKBOX_LIST = 'CHECKBOX_LIST',
        RADIO_LIST = 'RADIO_LIST',
        YEAR_OF_BIRTH = 'YEAR_OF_BIRTH',
        DATE_OF_BIRTH = 'DATE_OF_BIRTH',
        BSA_SCORE = 'BSA_SCORE',
        BMI = 'BMI',
    }


}
