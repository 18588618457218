/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StudySiteStatusDto = {
    siteId: string;
    status: StudySiteStatusDto.status;
}

export namespace StudySiteStatusDto {

    export enum status {
        SETUP = 'SETUP',
        CANCELED = 'CANCELED',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        PAUSED = 'PAUSED',
        CLOSEOUT = 'CLOSEOUT',
        COMPLETED = 'COMPLETED',
        REMOVED = 'REMOVED',
    }


}
