import {
  AnswerDto,
  AnswerValueDto,
  PatientReScreenerAnswerDto,
  PatientReScreenerAnswerValueDto,
  PatientReScreenerDto,
  PatientScreenerDto,
  QuestionDto,
  ReferralDto,
  ReferralInvestigatorDto,
  ReScreenerQuestionDto
} from '../api';

type PatientScreenerUnionType = PatientScreenerDto | PatientReScreenerDto;
type AnswerUnionType = AnswerDto | PatientReScreenerAnswerDto;
type AnswerValueUnionType = AnswerValueDto | PatientReScreenerAnswerValueDto;

export function getOfflineScreenerAnswer(relevantOfflineScreener: PatientScreenerUnionType, question: QuestionDto | ReScreenerQuestionDto)
    : AnswerUnionType | undefined {
  const answers: AnswerUnionType[] = relevantOfflineScreener.answers;
  return answers?.find(answer => answer.questionId === question.id);
}

export function getRelevantOfflineScreener(referral: ReferralInvestigatorDto | ReferralDto): PatientScreenerUnionType {
  if (referral.offlineScreener !== null) {
    return referral.offlineScreener;
  } else if (referral.reScreener) {
    return referral.patientReScreener;
  }
  return referral.patientScreener;
}

export function hasBsaScoreQuestions(questions: QuestionDto[] | ReScreenerQuestionDto[]): boolean {
  const onlineBsaScoreQuestionIndex = questions
    .findIndex(question => question.type === QuestionDto.type.BSA_SCORE);
  return onlineBsaScoreQuestionIndex && onlineBsaScoreQuestionIndex > -1;
}

export function mapBSAOnlineQuestionToScore(
  question: QuestionDto,
  referral?: ReferralInvestigatorDto | ReferralDto
): number {
  if (undefined === referral) {
    return 0;
  }
  let result;
  const bsaScoreAnswers: AnswerValueDto[] | undefined =
    referral.patientScreener.answers?.find(
      answer => answer.questionId === question.id
                && question.type === QuestionDto.type.BSA_SCORE,
    )?.values;

  if (!bsaScoreAnswers?.length) {
    return 0;
  }

  try {
    result = bsaScoreAnswers[0].value ? Number(bsaScoreAnswers[0].value) : 0;
  } catch (e) {
    return 0;
  }
  return result;
}

export function mapBSAOfflineQuestionToScore(
  question: QuestionDto | ReScreenerQuestionDto,
  referral?: ReferralInvestigatorDto | ReferralDto
): number {
  if (undefined === referral) {
    return 0;
  }
  let result;
  const relevantOfflineScreener = getRelevantOfflineScreener(referral);
  const answers: AnswerUnionType[] | undefined = relevantOfflineScreener?.answers as AnswerUnionType[];
  const bsaScoreAnswers: AnswerValueUnionType[] | undefined = answers?.find(
    answer => answer.questionId === question.id
          && question.type === QuestionDto.type.BSA_SCORE,
  )?.values;

  if (!bsaScoreAnswers?.length) {
    return 0;
  }

  try {
    result = bsaScoreAnswers[0].value ? Number(bsaScoreAnswers[0].value) : 0;
  } catch (e) {
    return 0;
  }
  return result;
}