import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { Text } from '@clariness/text';
import React from 'react';

type StatusDotProps = {
  title?: string;
  color: string;
};

export const StatusDot: React.FC<StatusDotProps> = ({ title, color }) => (
  <Flex
    sx={{
      alignItems: 'center',
      gap: '8px',
    }}
  >
    <Box
      data-testid="status-dot"
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
        flexShrink: 0,
      }}
    />

    {title && (
      <Text variant="p" ml="0 !important">
        {title}
      </Text>
    )}
  </Flex>
);
