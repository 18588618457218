import React from 'react';
import {
  NavBarElement,
  NavBarElementProps,
} from 'app/Header/components/NavBar/components/NavBarElement/NavBarElement';
import { ExternalLink, ExternalLinkProps } from 'components/links';

type NavBarExternalLinkProps = NavBarElementProps &
  ExternalLinkProps & {
    onClick?: () => void;
  };

export const NavBarExternalLink = ({
  to,
  icon,
  onClick,
  children,
}: NavBarExternalLinkProps) => {
  return (
    <ExternalLink onClick={onClick} to={to} variant="primary">
      <NavBarElement icon={icon}>{children}</NavBarElement>
    </ExternalLink>
  );
};
