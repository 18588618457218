import { ReferralActivityDto } from 'common/api';

export const ReferralActivity = {
  STATUS_CHANGE: ReferralActivityDto.event.STATUS_CHANGE,
  NOTE_CHANGE: ReferralActivityDto.event.NOTE_CHANGE,
  FORWARD_TO_SITE: ReferralActivityDto.event.FORWARD_TO_SITE,
  RELOCATED: ReferralActivityDto.event.RELOCATED,
};

export type ReferralActivityType = keyof typeof ReferralActivity;
