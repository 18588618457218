import { serviceRequest } from 'utils/http';
import { ChangeForgottenPasswordDto, Service } from 'common/api';

export function changeForgottenPasswordAPI(
  bodyRequest: ChangeForgottenPasswordDto
) {
  return serviceRequest(() =>
    Service.siteEmployeeControllerChangeForgottenPassword(bodyRequest)
  );
}
