import React, { useState, useEffect } from 'react';
import { Flex } from '@clariness/flex';
import { Logo } from 'components/Logo';
import { FormattedMessage } from 'react-intl';
import { Icons } from 'components/icons';
import { logout } from 'utils/auth';
import { Routing } from 'global/routing';
import { ExternalLinks } from 'global/externalLinks';
import { Store } from 'store';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { ProductUpdates } from 'components/ProductUpdates';
import { Services } from 'services';
import { useSocket } from 'utils/websocket/WebsocketContext';
import { NavBar } from './components';
import { Icon } from '@clariness/clarikit-react';
import { QuestionmarkIcon, UserIcon, ExportIcon } from '@clariness/clarikit-icons';

export const Header: React.FC = () => {
  const { user } = Store.useUser();
  const [productUpdatesOpen, setProductUpdatesOpen] = useState(false);
  const { productUpdates, refetch } =
    Services.ProductUpdates.useGetSiteEmployeeProductUpdates(user?.id);
  const unreadCount = productUpdates
    .filter((pu) => !pu.read)
    .reduce((acc) => acc + 1, 0);
  const { socket, message } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.emit('joinDomain', { email: user?.email });
    }
    if (message) {
      console.log('New message:', message);
    }
  }, [user, socket, message]);

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '15px',
        px: window?.innerWidth < 1366 ? '80px' : '110px',
        boxShadow: '1px 0 2px rgba(0, 0, 0, 0.6)',
      }}
    >
      <Logo />

      <NavBar>
        <NavBar.ExternalLink
          onClick={() =>
            captureEventV2(
              Events.InvestigatorService.InvestigatorAskHelp({
                InvestigatorID: user?.id ?? '',
              })
            )
          }
          to={ExternalLinks.HELP}
          icon={<Icon source={QuestionmarkIcon} size='small'/>}
        >
          <FormattedMessage id="is.nav_bar.help" defaultMessage="Help" />
        </NavBar.ExternalLink>

        <NavBar.Divider />

        <NavBar.Link
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorChecksAccount({
                InvestigatorID: user?.id ?? '',
              })
            );
          }}
          to={Routing.ACCOUNT.getPath()}
          icon={<Icon source={UserIcon} size='small'/>}
        >
          <FormattedMessage id="is.nav_bar.account" defaultMessage="Account" />
        </NavBar.Link>

        <NavBar.Divider />

        <NavBar.Button
          onClick={() => setProductUpdatesOpen(!productUpdatesOpen)}
          icon={<Icons.Bell counter={unreadCount} />}
        />

        <NavBar.Divider />

        <NavBar.Button
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorLogout({
                InvestigatorID: user?.id ?? '',
              })
            );
            logout();
          }}
          icon={<Icon source={ExportIcon} size='small' />}
        >
          <FormattedMessage id="is.nav_bar.logout" defaultMessage="Logout" />
        </NavBar.Button>
      </NavBar>
      <ProductUpdates
        open={productUpdatesOpen}
        setOpen={setProductUpdatesOpen}
        productUpdates={productUpdates}
        refetch={refetch}
      />
    </Flex>
  );
};
