import { Flex } from '@clariness/flex';
import React from 'react';
import { paginationPageStyles } from '../paginationPageStyles';

export const CollapsedPaginationPages = () => {
  return (
    <Flex
      sx={{
        ...paginationPageStyles,
        backgroundColor: 'bg.0',
      }}
    >
      ...
    </Flex>
  );
};
