import { SupportedCountries } from 'global/supportedCountries';

/**
 * @returns urlLocale from the url if it's supported
 */
export function getUrlLocale() {
  return SupportedCountries.find((locale) =>
    window.location.pathname.includes(locale)
  );
}
