import React from 'react';
import { Form, FormProps } from 'components/form-elements';

type AccountPageLayoutFormProps = FormProps;

export const AccountPageLayoutForm: React.FC<AccountPageLayoutFormProps> = ({
  children,
  ...props
}) => {
  return (
    <Form
      sx={{
        flexDirection: 'column',
        gap: '34px',
        mt: '34px',
        width: '100%',
      }}
      {...props}
    >
      {children}
    </Form>
  );
};
