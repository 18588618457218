import React from 'react';
import { Flex } from '@clariness/flex';
import { Loader } from '@clariness/loader';

export const BoxLoader: React.FC = () => {
  return (
    <Flex
      data-testid="loader"
      sx={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        color: 'green.500',
      }}
    >
      <Loader />
    </Flex>
  );
};
