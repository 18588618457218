import * as ReactQuery from 'react-query';
import { getReferralSiteTimezoneAPI } from './api';

export const GET_REFERRAL_SITE_TIME_ZONE_KEY =
  '@referrals/get-referral-site-timezone-activity';

export function useGetReferralSiteTimezone(id = '') {
  const { data, isFetching, isError, refetch } = ReactQuery.useQuery(
    [GET_REFERRAL_SITE_TIME_ZONE_KEY, id],
    () => getReferralSiteTimezoneAPI(id)
  );

  return {
    timezoneData: data,
    isFetching,
    isError,
    refetch,
  };
}
