import { AxiosError } from 'axios';
import { ApiError } from 'common/api';
import { ErrorType } from './types';

const genericErrorMessage = 'Something Failed. Try again?';

export function handleHttpError(error: AxiosError): ErrorType {
  return {
    statusCode: error?.response?.status || 500,
    message: genericErrorMessage,
  };
}

export function handleServiceError(error: ApiError): ErrorType {
  return {
    statusCode: error.status || 500,
    message: error.body?.message || genericErrorMessage,
    translationKey:
      error.body?.translationKey || 'server.errors.generic_error_message',
    isTranslationFromServer: Boolean(error.body?.translationKey),
  };
}
