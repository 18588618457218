/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SitePerformancePossibleOptionsDto = {
    option?: SitePerformancePossibleOptionsDto.option;
}

export namespace SitePerformancePossibleOptionsDto {

    export enum option {
        OPT_IN = 'OPT_IN',
        OPT_OUT = 'OPT_OUT',
        TRAINING = 'TRAINING',
        BYEBYE = 'BYEBYE',
    }


}
