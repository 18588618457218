import React, { useEffect } from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { Flex } from '@clariness/flex';
import { Icons } from 'components/icons';
import { useTableContext } from 'components/Table';
import { OnChangeState } from 'components/Table/Table';
import { getPaginationPages } from './getPaginationPages';
import { CollapsedPaginationPages, PaginationPage } from './components';

type Props = {
  onPageChange?: (state: OnChangeState) => void;
};

export const TablePagination: React.FC<Props> = ({ onPageChange }: Props) => {
  const {
    state,
    pageCount,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    gotoPage,
    initialState,
  } = useTableContext();

  const paginationPages = getPaginationPages(initialState.pageIndex, pageCount);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(state);
    }
  }, [onPageChange, state]);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '8px',
        color: 'text1',
      }}
    >
      <ShouldRender when={canPreviousPage}>
        <Icons.Arrow as="button" direction="left" onClick={previousPage} />
      </ShouldRender>

      {paginationPages.map((paginationPage) =>
        paginationPage.isCollapsed ? (
          <CollapsedPaginationPages key={paginationPage.key} />
        ) : (
          <PaginationPage
            key={paginationPage.key}
            isActive={initialState?.pageIndex === paginationPage.pageIndex}
            pageNumber={paginationPage.pageIndex + 1}
            onClick={() => gotoPage(paginationPage.pageIndex)}
          />
        )
      )}

      <ShouldRender when={canNextPage}>
        <Icons.Arrow as="button" onClick={nextPage} />
      </ShouldRender>
    </Flex>
  );
};
