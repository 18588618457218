import { UserAvailabilityInfo } from 'common/types/registration.types';
import { IntlShape } from 'react-intl';
import { CallbackTimeslotDto } from 'common/api';

export function displayContentValue(
  value: string | undefined,
  intl?: IntlShape
) {
  return value
    ? UserAvailabilityInfo.displayMessageForIS(value || '', intl) || '-'
    : 'NA';
}

export function displayCallbackTime(
  value?: CallbackTimeslotDto,
  intl?: IntlShape
) {
  return UserAvailabilityInfo.parseCallbackTimeslotISUser(value, intl);
}

export function displayContentValueFormatted(
  value: string | undefined,
  intl: IntlShape
) {
  return value
    ? UserAvailabilityInfo.formatStartDate(value || '', true)
    : `${intl.formatMessage({
        id: 'is.patient_details_modal.contact_details.availability.anytime',
        defaultMessage: 'anytime',
      })}`;
}
