/**
 * @return lastRow - index of the last visible row on the page
 */
export const getLastRow = (
  totalRows: number,
  pageIndex: number,
  rowsPerPage: number
) => {
  if (totalRows === -1) return (pageIndex + 1) * rowsPerPage;
  return rowsPerPage === -1
    ? totalRows
    : Math.min(totalRows, (pageIndex + 1) * rowsPerPage);
};
