/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvestigatorRoleDto = {
    id: number;
    role: InvestigatorRoleDto.role;
}

export namespace InvestigatorRoleDto {

    export enum role {
        PRINCIPAL_INVESTIGATOR = 'Principal Investigator',
        STUDY_COORDINATOR = 'Study Coordinator',
        STUDY_NURSE = 'Study Nurse',
        CRA_EMPLOYEE = 'CRA Employee',
        OTHER = 'Other',
    }


}
