import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'services';
import { getScreenerAPI } from './api';

export const GET_SCREENER_KEY = '@referrals/get-screener';

export function useGetScreener(id = '', options?: InferQueryOptions<typeof getScreenerAPI>) {
  const {
    data,
    isLoading,
    isError,
    refetch,
  } = ReactQuery.useQuery([GET_SCREENER_KEY, id], () => getScreenerAPI(id), {
    ...options,
    enabled: options?.enabled || Boolean(id),
  });

  return {
    screener: data,
    isLoading,
    isError,
    refetch,
  };
}
