import React, { PropsWithChildren } from 'react';
import { Box } from '@clariness/box';
import { useTableContext } from 'components/Table';

type TableBodyProps = {
  onClick: (row: any) => void;
};

export const TableBody = ({ onClick }: PropsWithChildren<TableBodyProps>) => {
  const { getTableBodyProps, rows, prepareRow } = useTableContext();

  return (
    <Box
      {...getTableBodyProps()}
      as="tbody"
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'hgl4',
      }}
    >
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Box
            {...row.getRowProps()}
            as="tr"
            sx={{
              backgroundColor: 'hgl7',

              '&:nth-of-type(even)': {
                backgroundColor: 'hgl5',
              },
            }}
          >
            {row.cells.map((cell) => (
              <Box
                {...cell.getCellProps()}
                as="td"
                sx={{ p: '16px', color: 'text1' }}
              >
                {cell.render('Cell', { onClick })}
              </Box>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};
