export const Svg = () => {
  return (
    <svg width="110" height="120" viewBox="0 0 110 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.567 119.696C70.9691 119.696 83.4549 118.897 83.4549 117.911C83.4549 116.925 70.9691 116.126 55.567 116.126C40.1648 116.126 27.679 116.925 27.679 117.911C27.679 118.897 40.1648 119.696 55.567 119.696Z" fill="#E6EFE1" />
      <path d="M96.4656 73.9122C103.585 51.0125 90.7924 26.6774 67.8928 19.5581C44.9932 12.4388 20.6581 25.2313 13.5388 48.1309C6.41951 71.0306 19.212 95.3657 42.1116 102.485C65.0112 109.604 89.3464 96.8118 96.4656 73.9122Z" fill="#E6EFE1" />
      <path d="M73.1507 89.169L55.2517 95.07C58.6117 91.3251 56.9877 79.957 56.8617 78.886C56.8617 78.886 56.8617 78.879 56.8617 78.872C56.8197 78.529 56.7707 78.179 56.7077 77.843C56.4417 76.24 55.9797 74.679 55.2937 73.153L60.0817 71.571C60.2077 72.145 60.3547 72.698 60.5367 73.251C60.9357 74.511 61.4607 75.715 62.0837 76.856C62.1327 76.94 62.1817 77.024 62.2307 77.108C62.2307 77.122 62.2447 77.129 62.2587 77.143C62.7837 78.067 68.2157 88.161 73.1437 89.169H73.1507Z" fill="#398717" />
      <path d="M77.1414 87.8423L51.2678 96.3741L55.6214 109.577L81.495 101.045L77.1414 87.8423Z" fill="#A7A7A7" />
      <path d="M48.5948 44.7331L26.7338 51.9431C24.4728 52.6921 23.2408 55.1351 23.9898 57.3961L24.8158 59.8951C27.5738 68.2601 34.5318 74.0771 42.5818 75.7851C43.5688 75.9951 44.5838 76.1351 45.5988 76.2191C47.3348 76.3591 49.0988 76.3101 50.8698 76.0511C52.2348 75.8551 53.5998 75.5401 54.9508 75.0921L58.2478 74.0071C59.3538 73.6431 59.9558 72.4461 59.5918 71.3471L51.2618 46.0701C50.8978 44.9711 49.7008 44.3691 48.5948 44.7331ZM55.2658 70.8291L53.7118 71.3401C52.3468 71.7881 50.9748 72.0821 49.6028 72.2221C48.1398 72.3761 46.6908 72.3761 45.2558 72.2151C43.8348 72.0541 42.4418 71.7391 41.0908 71.2841C35.3648 69.3381 30.5908 64.8301 28.5538 58.6561L27.7278 56.1571C27.6648 55.9541 27.7698 55.7511 27.9588 55.6881L48.0768 49.0521L55.2588 70.8291H55.2658Z" fill="#398717" />
      <g>
        <path d="M34.9518 49.2271L36.1908 52.972L30.8498 54.7361L30.7588 54.638C29.8698 53.665 29.3308 52.426 29.2258 51.117L34.9518 49.2271Z" fill="#95CC81" />
      </g>
      <g>
        <path d="M49.6028 72.229L50.8628 76.051C49.0918 76.31 47.3278 76.359 45.5918 76.219C43.6458 75.057 42.0778 73.342 41.0908 71.291C42.4418 71.746 43.8348 72.061 45.2558 72.222C46.6838 72.383 48.1328 72.39 49.6028 72.229Z" fill="#95CC81" />
      </g>
      <path d="M47.1808 47.414L55.5108 72.691C55.8748 73.79 57.0718 74.392 58.1778 74.028L61.4748 72.943C62.8328 72.495 64.1138 71.942 65.3248 71.284C66.8998 70.437 68.3488 69.429 69.6578 68.281C70.4278 67.609 71.1558 66.895 71.8278 66.139C77.2808 59.979 79.4158 51.166 76.6578 42.801L75.8318 40.302C75.0828 38.041 72.6398 36.802 70.3788 37.551L48.5178 44.761C47.4118 45.125 46.8098 46.322 47.1738 47.421L47.1808 47.414ZM51.5068 47.925L71.6248 41.289C71.8208 41.226 72.0238 41.331 72.0938 41.527L72.9198 44.026C74.9568 50.2 73.7948 56.668 70.3508 61.631C69.5388 62.8 68.6078 63.885 67.5578 64.858C66.5008 65.838 65.3388 66.706 64.0718 67.448C62.8818 68.148 61.6078 68.729 60.2428 69.184L58.6888 69.695L51.5068 47.918V47.925Z" fill="#398717" />
      <g>
        <path d="M62.1677 40.2531L63.4067 43.9981L68.7477 42.2341L68.7617 42.1011C68.8947 40.7921 68.5937 39.4761 67.9007 38.3561L62.1747 40.2461L62.1677 40.2531Z" fill="#95CC81" />
      </g>
      <g>
        <path d="M64.0718 67.4551L65.3318 71.2771C66.9068 70.4301 68.3558 69.4221 69.6648 68.2741C70.5398 66.1811 70.7778 63.8711 70.3508 61.6381C69.5388 62.8071 68.6078 63.8921 67.5578 64.8651C66.5008 65.8451 65.3388 66.7131 64.0718 67.4551Z" fill="#95CC81" />
      </g>
      <g>
        <path d="M60.6068 81.273C61.7758 80.888 62.8748 80.412 63.8688 79.873C63.0008 78.48 62.4198 77.409 62.2658 77.136C62.2588 77.129 62.2448 77.115 62.2378 77.101C62.1888 77.017 62.1398 76.933 62.0908 76.849C61.4678 75.708 60.9428 74.504 60.5438 73.244C60.3618 72.691 60.2148 72.138 60.0888 71.564L55.3008 73.146C55.9798 74.665 56.4418 76.233 56.7148 77.829C56.7778 78.172 56.8198 78.522 56.8688 78.865V78.879C56.9108 79.194 57.0788 80.419 57.2118 82.071C58.3178 81.91 59.4588 81.644 60.6138 81.266L60.6068 81.273Z" fill="#95CC81" />
      </g>
      <path d="M65.2128 34.646V34.66C66.3258 38.174 72.1148 57.704 67.8938 69.674C67.0748 72.019 65.8638 74.07 64.1628 75.652C64.1628 75.652 64.1558 75.659 64.1488 75.666C63.5748 76.212 62.9378 76.688 62.2378 77.108C61.5588 77.528 60.8168 77.878 60.0118 78.165C59.8928 78.207 59.7738 78.256 59.6478 78.298C59.5078 78.34 59.3818 78.389 59.2488 78.424C58.5488 78.634 57.8628 78.774 57.1768 78.851C57.1628 78.851 57.1558 78.851 57.1418 78.851C57.0508 78.865 56.9528 78.872 56.8618 78.879C53.6348 79.18 50.6248 78.13 47.8528 76.24C37.3738 69.114 30.4158 49.997 29.2258 46.518V46.504C29.2748 46.364 30.2618 45.93 31.9278 45.293C33.0758 44.852 34.5528 44.313 36.2818 43.704C39.2708 42.64 42.9878 41.366 47.0478 40.029C50.2678 38.965 53.3058 37.992 55.9378 37.18C59.2278 36.151 61.8878 35.374 63.5048 34.947C64.5198 34.688 65.1218 34.576 65.2128 34.632V34.646Z" fill="#76BC58" />
      <path d="M65.2198 34.6531C65.2198 34.6531 65.2198 34.6671 65.2198 34.6601C65.1778 34.7651 64.6248 35.0311 63.6588 35.4161C62.1258 36.0391 59.5568 36.9771 56.3508 38.0971C53.7258 39.0211 50.6738 40.0501 47.4048 41.1281C43.3238 42.4721 39.5508 43.6691 36.5128 44.5931C34.7908 45.1181 33.3068 45.5521 32.1308 45.8881C30.4018 46.3641 29.3588 46.6091 29.2328 46.5251C29.2328 46.5321 29.2328 46.5251 29.2258 46.5251C29.2188 46.5251 29.2258 46.5111 29.2258 46.5181C29.2818 46.3781 30.2618 45.9441 31.9278 45.3071C33.0758 44.8661 34.5528 44.3271 36.2818 43.7181C39.2708 42.6541 42.9878 41.3801 47.0478 40.0431C50.2678 38.9791 53.3058 38.0061 55.9378 37.1941C59.2278 36.1651 61.8878 35.3881 63.5048 34.9611C64.5198 34.7021 65.1218 34.5901 65.2128 34.6461C65.2128 34.6391 65.2128 34.6461 65.2198 34.6461V34.6531Z" fill="#296510" />
      <path d="M83.8887 101.65L84.1477 102.434L54.3207 112.269L54.0617 111.485C53.9987 111.296 53.9637 111.1 53.9637 110.918C53.9427 110.064 54.4677 109.273 55.3147 108.993L81.3967 100.397C82.2367 100.117 83.1397 100.439 83.6297 101.139C83.7347 101.293 83.8257 101.468 83.8887 101.657V101.65Z" fill="#A7A7A7" />
      <path opacity="0.12" d="M83.8889 101.65L83.9589 101.853L54.1319 111.688L54.0619 111.485C53.9989 111.296 53.9639 111.1 53.9639 110.918L83.6299 101.139C83.7349 101.293 83.8259 101.468 83.8889 101.657V101.65Z" fill="#F3F3F3" />
      <path opacity="0.42" d="M74.7606 90.4827L54.7305 97.0876L57.8565 106.568L77.8866 99.9626L74.7606 90.4827Z" fill="#F3F3F3" />
      <g opacity="0.42">
        <path d="M67.4808 64.1371C66.4798 66.5591 65.0658 68.7011 63.1128 70.3811C63.1058 70.3811 63.0988 70.3951 63.0918 70.3951C62.4268 70.9691 61.6988 71.4801 60.9148 71.9351C60.1448 72.3831 59.3048 72.7751 58.3948 73.0971C58.2618 73.1461 58.1218 73.2021 57.9818 73.2441C57.8278 73.2931 57.6808 73.3421 57.5268 73.3841C56.7358 73.6221 55.9658 73.7901 55.2028 73.8881C55.1888 73.8881 55.1818 73.8881 55.1678 73.8881C55.0628 73.9091 54.9578 73.9161 54.8528 73.9301C51.2408 74.3501 47.9158 73.3841 44.8918 71.5431C42.7078 70.2201 40.6778 68.4491 38.8228 66.4191C41.4128 70.3251 44.4508 73.9161 47.8598 76.2401C50.6318 78.1301 53.6418 79.1801 56.8688 78.8791C56.9598 78.8721 57.0578 78.8651 57.1488 78.8511C57.1628 78.8511 57.1698 78.8511 57.1838 78.8511C57.8628 78.7741 58.5558 78.6341 59.2558 78.4241C59.3888 78.3891 59.5218 78.3471 59.6548 78.2981C59.7808 78.2561 59.8998 78.2071 60.0188 78.1651C60.8238 77.8781 61.5658 77.5211 62.2448 77.1081C62.9378 76.6951 63.5748 76.2121 64.1558 75.6661C64.1558 75.6661 64.1628 75.6591 64.1698 75.6521C65.8708 74.0631 67.0748 72.0121 67.9008 69.6741C69.2798 65.7681 69.5878 61.0501 69.3428 56.3461C69.0558 59.1041 68.4748 61.7571 67.4878 64.1371H67.4808Z" fill="#95CC81" />
      </g>
      <path d="M33.314 29.473C33.314 29.473 31.466 28.08 30.99 25.994C30.633 24.447 31.529 22.501 31.599 22.417L29.121 21.927C29.121 21.927 28.386 25.217 29.625 27.401C30.003 28.073 31.109 28.99 31.823 29.34C32.607 29.718 33.314 29.48 33.314 29.48V29.473Z" fill="#707070" />
      <path d="M37.619 27.961L39.873 30.537C39.873 30.537 33.496 32.231 29.856 27.723C29.856 27.723 35.225 29.536 37.619 27.954V27.961Z" fill="#A7A7A7" />
      <path d="M55.3499 30.537C55.3499 30.537 57.7439 27.093 61.4119 29.529L63.6449 27.394C63.6449 27.394 61.5589 25.812 56.7429 28.654C53.5929 30.516 55.3499 30.537 55.3499 30.537Z" fill="#398717" />
      <path d="M52.9909 33.9109C52.9909 33.9109 52.5149 29.9559 59.1369 27.4919C59.1369 27.4919 56.7429 28.6539 56.0079 32.5949L52.9909 33.9179V33.9109Z" fill="#76BC58" />
      <path d="M46.2358 13.989C46.2358 13.989 48.1888 12.757 48.3988 11.259C48.3988 11.259 50.3028 11.364 50.7158 11.98C50.7158 11.98 49.8408 14.451 48.8118 14.913C48.8118 14.913 46.4948 15.067 46.2358 13.989Z" fill="#76BC58" />
      <path d="M20.399 22.7111C20.399 22.7111 18.313 21.3951 18.096 19.7991C18.096 19.7991 16.066 19.9111 15.625 20.5691C15.625 20.5691 16.556 23.2081 17.655 23.6981C17.655 23.6981 20.126 23.8661 20.399 22.7111Z" fill="#76BC58" />
      <path d="M31.5849 2.23604C31.5849 2.23604 30.2059 1.36104 30.0589 0.311035C30.0589 0.311035 28.7149 0.381036 28.4209 0.822035C28.4209 0.822035 29.0369 2.56504 29.7649 2.89404C29.7649 2.89404 31.4029 3.00604 31.5849 2.24304V2.23604Z" fill="#76BC58" />
    </svg>
  );
}