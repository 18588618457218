import { serviceRequest } from 'utils/http';
import { Service } from 'common/api';

type GetStudySitePerformance = {
  studyId: string,
  siteId: string,
}

export function getStudySitePerformanceAPI({studyId, siteId}: GetStudySitePerformance) {
  return serviceRequest(() => Service.studySitePerformanceControllerGet(studyId, siteId));
}
