type reasonType = {
  id: string;
  reason: string;
};

type droppedReasonsType = {
  languageCode: string[];
  studyId: string;
  reasons: Array<reasonType>;
};

const droppedReasons: Array<droppedReasonsType> = [
  {
    languageCode: ['pl-PL'],
    studyId: '9975666a-f80b-48ed-b4b3-589d650c8b14',
    reasons: [
      {
        id: '1',
        reason: 'Brak leczenia profilaktycznego',
      },
      {
        id: '2',
        reason: 'Podjęcie tylko jednego rodzaju leczenia profilaktycznego',
      },
      {
        id: '3',
        reason: 'Przyjęcie tylko jednego tryptanu',
      },
      {
        id: '4',
        reason: 'Stosowanie innych leków niedozwolonych w badaniu',
      },
      {
        id: '5',
        reason: 'Zbyt wiele/zbyt mało migren w miesiącu',
      },
      {
        id: '6',
        reason: 'Migreny trwają mniej niż 4 godziny',
      },
      {
        id: '7',
        reason: `Inne choroby współistniejące (nowotwór w ciągu ostatnich 5 lat,
       przewlekłe problemy żołądkowe lub operacja bariatryczna)`,
      },
      {
        id: '8',
        reason: 'Niewystarczająca dokumentacja objawów, leczenia lub diagnozy',
      },
      {
        id: '9',
        reason: 'Inne',
      },
    ],
  },
  {
    languageCode: ['en-CA', 'en-GB', 'en-AU'],
    studyId: '588ba6c2-3502-47cc-ad49-e1e5991c74f4',
    reasons: [
      {
        id: '1',
        reason: 'Insufficient face vitiligo ',
      },
      {
        id: '2',
        reason: 'Insufficient body vitiligo',
      },
      {
        id: '3',
        reason: 'No formal vitiligo diagnosis',
      },
      {
        id: '4',
        reason: 'Lacks required vitiligo type (non-segmental)',
      },
      {
        id: '5',
        reason: 'Lacks required skin type',
      },
      {
        id: '6',
        reason: 'Competing or prohibited condition',
      },
      {
        id: '7',
        reason: 'Use or history of prohibited meds/treatments',
      },
      {
        id: '8',
        reason: 'Other medical reason',
      },
    ],
  },
];

export function getDroppedReasons(languageCode?: string, studyId?: string) {
  return droppedReasons.filter(
    (droppedReason) =>
      languageCode &&
      droppedReason.languageCode.includes(languageCode) &&
      droppedReason.studyId === studyId
  )[0]?.reasons;
}
