import { serviceRequest } from 'utils/http';
import { Service, ProtocolInfoDto } from 'common/api';
import { ReferralStatusType } from 'global/referralStatus';

type UpdateReferralStatus = {
  id?: string;
  status: ReferralStatusType;
  appointmentDate?: string;
  protocolInfoDto?: ProtocolInfoDto;
  consentedDate?: string;
  isVisited: boolean;
};

export function updateReferralStatusAPI({
  id,
  status,
  appointmentDate,
  consentedDate,
  protocolInfoDto,
  isVisited,
}: UpdateReferralStatus) {
  return serviceRequest(() =>
    Service.referralControllerChangeStatusAsCurrentUser(id || '', {
      referralStatus: status as any,
      appointmentDateTime: appointmentDate,
      protocolInfoDto,
      consentedDate,
      isVisited,
    })
  );
}
