import React, { PropsWithChildren } from 'react';
import { Text } from '@clariness/text';

export const AuthPageLayoutParagraph = ({
  children,
}: PropsWithChildren<unknown>) => {
  return (
    <Text
      variant="p"
      sx={{
        mb: '34px',
        fontWeight: 300,
      }}
    >
      {children}
    </Text>
  );
};
