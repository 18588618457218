 
export const Svg = () => {
  return <svg width="131" height="130" viewBox="0 0 131 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117.696 73.2987C124.721 46.8603 108.983 19.7329 82.5445 12.7081C56.1061 5.68326 28.9788 21.4211 21.9539 47.8595C14.9291 74.2979 30.6669 101.425 57.1053 108.45C83.5437 115.475 110.671 99.7371 117.696 73.2987Z" fill="#E6EFE1" />
    <path d="M123.275 125.886C123.279 124.119 95.8444 122.625 61.9978 122.548C28.1511 122.471 0.709662 123.841 0.705653 125.608C0.701644 127.374 28.1366 128.869 61.9832 128.946C95.8299 129.022 123.271 127.653 123.275 125.886Z" fill="#E6EFE1" />
    <path d="M102.404 31.5H52.004V123.627H102.404V31.5Z" fill="white" />
    <path d="M100.255 33.1172H54.216V123.627H100.255V33.1172Z" fill="#E6EFE1" />
    <path d="M92.443 77.756C92.443 76.629 93.353 75.719 94.48 75.719C95.607 75.719 96.517 76.629 96.517 77.756C96.517 78.883 95.607 79.793 94.48 79.793C93.353 79.793 92.443 78.883 92.443 77.756Z" fill="white" />
    <path d="M68.629 123.613C68.629 123.613 69.063 123.025 69.182 122.409C69.35 121.576 69.147 120.715 69.147 120.715L72.101 121.086C72.101 121.086 72.087 121.786 72.157 122.269C72.227 122.752 72.5 123.669 72.5 123.669C72.5 123.669 71.485 124.719 70.554 124.558C69.623 124.397 68.622 123.62 68.622 123.62L68.629 123.613Z" fill="#FFC173" />
    <path d="M82.258 122.402C82.258 122.402 82.335 121.821 82.349 121.191C82.363 120.561 81.516 119.546 81.516 119.546L84.358 118.65C84.358 118.65 84.596 119.483 84.862 119.889C85.128 120.295 85.807 120.834 85.807 120.834C85.807 120.834 85.324 122.213 84.407 122.451C83.49 122.689 82.258 122.402 82.258 122.402Z" fill="#FFC173" />
    <path d="M36.569 55.6641L44.479 55.7061L40.489 69.9161L32.88 68.3341L36.569 55.6641Z" fill="white" />
    <path d="M40.426 64.288C40.426 64.288 40.454 64.12 40.657 63.896C40.79 63.658 41.098 62.93 41.112 62.769C41.161 62.244 41.518 62.426 41.574 62.888C41.588 62.986 41.525 63.231 41.525 63.343C41.966 63.518 42.407 63.154 42.519 62.678C42.575 62.447 42.652 62.223 42.687 62.076C42.736 61.866 43.247 62.216 43.261 62.58C43.282 63.168 43.275 64.75 43.128 65.002C42.883 65.429 41.455 65.863 40.727 65.961L39.859 66.031L39.355 64.631L40.426 64.295V64.288Z" fill="#7A3B15" />
    <path d="M13.749 121.443C13.749 121.443 13.98 125.146 14.694 125.195C14.792 125.202 18.327 125.202 18.327 125.202L22.842 125.174C22.842 125.174 22.814 124.089 21.491 123.858C20.175 123.627 17.963 122.703 17.165 120.96C16.325 119.126 13.749 121.443 13.749 121.443Z" fill="#2C3E50" />
    <path d="M13.483 121.702C13.483 121.702 14.855 122.164 15.926 122.164C17.123 122.164 18.208 121.842 18.208 121.842C18.208 121.842 18.467 119.343 19.888 113.841C20.63 110.957 21.694 107.247 23.213 102.641C27.399 89.9009 28.673 82.4529 28.785 81.7459C28.785 81.7109 28.792 81.6899 28.792 81.6899H28.778L15.912 76.0339C15.912 76.0339 17.501 93.5899 15.471 100.324C15.184 101.269 14.946 102.361 14.736 103.551C13.455 110.873 13.483 121.702 13.483 121.702Z" fill="#2C3E50" />
    <g>
      <path d="M14.736 103.551L19.888 113.841C20.63 110.957 21.694 107.247 23.213 102.641C27.399 89.9009 28.673 82.4529 28.785 81.7459C28.785 81.7249 28.785 81.7039 28.785 81.6829L15.919 76.0339C15.919 76.0339 17.508 93.5899 15.478 100.324C15.191 101.269 14.953 102.361 14.743 103.551H14.736Z" fill="#2C3E50" />
    </g>
    <path d="M23.794 121.443C23.794 121.443 24.025 125.146 24.739 125.195C24.837 125.202 28.372 125.202 28.372 125.202L32.887 125.174C32.887 125.174 32.859 124.089 31.536 123.858C30.22 123.627 28.008 122.703 27.21 120.96C26.37 119.126 23.794 121.443 23.794 121.443Z" fill="#2C3E50" />
    <path d="M31.095 76.4401C31.095 76.4401 31.263 87.8151 29.296 101.78C27.329 115.745 27.994 121.674 27.994 121.674C27.994 121.674 26.643 122.115 26.097 122.157C24.704 122.269 23.283 121.828 23.283 121.828C23.283 121.828 20.469 107.856 21.274 100.863C22.079 93.8701 17.417 76.8741 17.417 76.8741L31.102 76.4331L31.095 76.4401Z" fill="#2C3E50" />
    <path d="M21.708 49.4131L23.829 51.5411L27.518 51.6181C27.532 51.3241 27.546 50.0851 27.553 48.9651C27.553 48.9021 27.553 48.8321 27.553 48.7691C27.56 47.8171 27.567 46.9981 27.567 46.9981L24.557 44.8421L23.437 44.0371C23.437 44.0371 23.318 45.0731 23.304 45.2201C22.996 47.8521 21.708 49.4201 21.708 49.4201V49.4131Z" fill="#7A3B15" />
    <path d="M31.844 76.9371C31.795 82.9431 31.389 96.9851 31.095 97.2721C27.966 100.289 14.211 96.4461 13.483 93.1211C12.755 89.7961 16.255 50.4771 21.596 48.9091C22.702 48.5871 28.631 50.4981 28.631 50.4981C28.631 50.4981 29.562 52.9271 30.171 56.0281C30.395 57.1761 31.137 65.3661 31.564 70.8401C31.676 72.2961 31.774 73.5631 31.823 74.4521C31.844 74.8161 31.851 75.7121 31.837 76.9441L31.844 76.9371Z" fill="#76BC58" />
    <g opacity="0.57">
      <path d="M23.542 45.3111C24.011 46.6971 26.503 48.3351 27.553 48.9581C27.553 48.8951 27.553 48.8251 27.553 48.7621C27.56 47.8101 27.567 46.9911 27.567 46.9911L24.557 44.8351C23.829 44.6741 23.346 44.7441 23.542 45.3111Z" fill="#7A3B15" />
    </g>
    <path d="M28.869 47.047C28.869 47.047 23.178 47.6 22.884 44.044C22.597 40.495 21.666 38.171 25.292 37.674C28.918 37.17 29.73 38.472 30.115 39.634C30.5 40.796 30.339 46.816 28.869 47.047Z" fill="#7A3B15" />
    <path d="M29.975 39.2701C29.975 39.2701 28.82 43.0921 28.05 42.7561C27.28 42.4201 26.573 42.1401 26.447 43.3371C26.321 44.5341 27.154 46.123 24.627 45.815C22.1 45.507 19.874 38.3601 20.805 37.2961C23.5 34.2091 33.699 35.6091 29.968 39.2771L29.975 39.2701Z" fill="#2C3E50" />
    <path d="M27.182 48.993C27.112 48.965 23.115 47.173 22.653 47.327C22.191 47.481 21.603 48.909 21.603 48.909L28.638 50.498C28.638 50.498 28.085 49.371 27.189 48.993H27.182Z" fill="#95CC81" />
    <path d="M31.844 76.9371C18.796 68.0961 19.853 55.9441 19.853 55.9441C24.942 58.8141 29.996 65.6181 31.564 70.8401C31.676 72.2961 31.774 73.5631 31.823 74.4521C31.844 74.8161 31.851 75.7121 31.837 76.9441L31.844 76.9371Z" fill="#398717" />
    <path d="M25.117 52.8991C25.117 52.8991 28.799 65.6881 30.297 65.6881C31.291 65.6881 39.992 64.2461 39.992 64.2461L40.797 66.1291C40.797 66.1291 31.102 72.5131 27.581 71.2321C22.002 69.1952 18.635 59.3111 19.86 55.9441C21.085 52.5771 22.765 51.4431 25.117 52.8991Z" fill="#76BC58" />
    <path d="M73.508 42.3712C71.912 41.5452 70.631 42.0632 70.631 42.0632C65.738 41.4892 64.31 47.1942 64.38 49.1542C64.443 51.1142 64.667 53.2282 62.483 55.7762C56.169 63.1402 69.392 63.4692 69.392 63.4692C69.504 57.5822 76.56 59.7312 76.252 60.3262C85.296 62.4962 77.148 44.2542 73.515 42.3712H73.508Z" fill="#2C3E50" />
    <path d="M54.636 66.9061C54.636 66.9061 53.775 65.5971 53.082 65.5691C52.389 65.5411 51.108 65.1841 51.206 65.4431C51.311 65.7021 52.102 65.7231 52.179 65.8771C52.256 66.0311 50.996 66.2621 50.639 66.2901C50.282 66.3181 49.435 66.2901 49.456 66.4721C49.484 66.6541 50.982 67.3961 52.046 67.2911C53.103 67.1861 54.412 67.5991 54.412 67.5991L54.629 66.9061H54.636Z" fill="#FFC173" />
    <path d="M67.075 67.333C67.075 67.333 66.606 68.257 65.759 69.321C64.737 70.602 63.162 72.079 61.181 72.373C60.236 72.513 59.312 72.31 58.458 71.932C56.057 70.868 54.244 68.411 54.244 68.411C53.817 67.256 54.517 66.472 54.517 66.472C54.517 66.472 59.368 67.564 59.83 67.333C60.292 67.102 62.987 59.332 63.631 58.359C64.275 57.386 66.459 56.875 66.459 56.875L67.068 67.333H67.075Z" fill="#A7A7A7" />
    <path d="M65.759 69.321C64.737 70.602 63.162 72.079 61.181 72.373C60.236 72.513 59.312 72.31 58.458 71.932C58.549 71.946 60.95 72.24 61.909 70.532C62.882 68.782 63.939 66.01 65.388 66.934C66.011 67.333 65.983 68.327 65.752 69.321H65.759Z" fill="#707070" />
    <path d="M85.555 120.33C85.555 120.33 87.158 121.324 86.696 122.22C86.234 123.116 85.695 123.823 84.953 124.971C84.309 125.972 83.665 126.623 83.021 126.623H79.92C79.92 126.623 79.444 125.797 80.599 125.545C81.754 125.293 81.992 124.824 82.118 123.515C82.244 122.206 82.272 121.499 82.272 121.499C82.272 121.499 83.231 121.807 84.127 121.541C85.023 121.275 85.555 120.33 85.555 120.33Z" fill="#A7A7A7" />
    <path d="M72.36 122.829C72.36 122.829 73.179 126.021 72.535 126.343L64.212 126.329C64.212 126.329 63.953 125.083 65.29 124.866C66.627 124.656 68.349 124.418 68.895 122.773C68.895 122.773 69.672 122.976 70.61 123.011C71.926 123.06 72.087 122.906 72.367 122.836L72.36 122.829Z" fill="#A7A7A7" />
    <path d="M78.583 79.2192C76.868 75.3762 75.825 74.2772 76.021 73.0872C76.168 72.1982 75.566 56.5462 75.566 56.5462C75.363 56.4832 74.957 56.4272 74.733 56.3782C74.033 56.2242 73.452 55.7272 73.207 55.0482C72.913 54.2292 72.682 53.0252 72.689 51.5132L72.472 51.5552L68.783 52.2832C68.783 52.2832 68.867 53.4452 68.867 54.5372C68.867 54.5442 68.867 54.5582 68.867 54.5652C68.867 55.5172 68.251 56.3502 67.334 56.6162C66.865 56.7492 66.466 56.8682 66.466 56.8682C66.466 56.8682 65.115 60.7322 64.856 64.7082C64.597 68.6772 67.32 70.4062 64.835 78.3442C62.301 86.4502 79.528 81.3402 78.583 79.2192Z" fill="#FFC173" />
    <g opacity="0.51">
      <path d="M68.783 52.29C68.783 52.29 68.867 53.452 68.867 54.544C70.932 54.488 72.024 52.605 72.472 51.562L68.783 52.29Z" fill="#FFC173" />
    </g>
    <path d="M68.09 53.3261C68.09 53.3261 72.976 53.4101 72.976 50.3651C72.976 47.3201 73.613 45.2831 70.491 45.1081C67.376 44.9261 66.767 46.0881 66.515 47.1031C66.27 48.1181 66.816 53.2281 68.083 53.3261H68.09Z" fill="#FFC173" />
    <path d="M68.272 45.0311C68.272 45.0311 69.609 48.6011 71.66 49.1261C73.711 49.6511 74.6 49.1051 74.6 49.1051C74.6 49.1051 73.025 47.8521 72.703 45.6751C72.703 45.6751 69.28 43.3371 68.272 45.0311Z" fill="#2C3E50" />
    <path d="M68.608 45.318C68.608 45.318 67.614 45.689 67.208 46.41C66.809 47.117 66.466 48.055 66.55 49.203C66.55 49.203 65.542 46.774 66.676 45.402C67.873 43.946 68.608 45.311 68.608 45.311V45.318Z" fill="#2C3E50" />
    <path d="M69.154 51.4431C69.154 51.4431 68.818 51.8841 68.146 51.8561C67.6 51.8281 67.397 51.3591 67.397 51.3591C67.397 51.3591 68.391 51.1211 69.161 51.4431H69.154Z" fill="white" />
    <path d="M77.918 63.4691C77.918 63.4691 77.897 65.7861 77.778 67.8861C77.694 69.2791 77.568 70.5741 77.365 71.0291C76.861 72.1841 76.301 72.3731 76.301 72.3731L71.933 73.4441L70.4 73.8221L65.556 72.3731C65.556 72.3731 63.351 66.2131 64.233 62.7481C65.122 59.2831 66.473 56.8751 66.473 56.8751C66.473 56.8751 70.428 60.7461 75.573 56.5461L77.918 63.4691Z" fill="#A7A7A7" />
    <path d="M77.771 67.8862C77.687 69.2792 77.561 70.5742 77.358 71.0292C76.854 72.1842 76.294 72.3732 76.294 72.3732L71.926 73.4442L70.029 72.8842C70.029 72.8842 76.364 71.9252 75.664 66.4722L77.771 67.8792V67.8862Z" fill="#707070" />
    <path d="M65.857 73.311C65.857 73.311 63.428 78.967 63.302 83.321C63.232 85.869 68.769 120.379 68.958 121.548C68.958 121.548 69.525 121.737 70.631 121.786C71.758 121.835 72.514 121.506 72.514 121.506C72.514 121.506 74.67 97.818 73.515 89.264C72.36 80.71 72.346 73.311 72.346 73.311H65.857Z" fill="#398717" />
    <path d="M76.021 73.0872C76.021 73.0872 79.682 77.9942 80.025 81.3192C80.368 84.6372 80.557 96.2992 81.285 99.5332C82.013 102.767 84.68 108.997 84.68 119.091C84.68 119.091 84.421 119.714 83.546 119.98C82.447 120.309 81.621 120.239 81.621 120.239C79.808 116.606 75.475 107.751 72.941 101.633C70.407 95.5152 66.893 86.4572 66.956 81.8232C67.019 77.1892 67.537 73.8292 67.537 73.8292L76.014 73.0942L76.021 73.0872Z" fill="#76BC58" />
    <path d="M65.472 72.1562C65.472 72.1562 66.438 72.9192 68.349 72.9542C70.631 73.0032 76.294 72.3732 76.294 72.3732C76.294 72.3732 76.546 73.0732 76.532 73.8152C76.532 73.8152 69.616 75.5582 65.612 74.0672C65.612 74.0672 65.094 73.0942 65.472 72.1492V72.1562Z" fill="#F3F3F3" />
    <path d="M67.803 74.928C67.229 74.928 66.76 74.396 66.76 73.745C66.76 73.094 67.229 72.562 67.803 72.562C68.377 72.562 68.846 73.094 68.846 73.745C68.846 74.396 68.377 74.928 67.803 74.928ZM67.803 72.912C67.418 72.912 67.103 73.283 67.103 73.745C67.103 74.207 67.418 74.578 67.803 74.578C68.188 74.578 68.503 74.207 68.503 73.745C68.503 73.283 68.188 72.912 67.803 72.912Z" fill="#A7A7A7" />
    <path d="M83.8308 78.7813L72.9441 81.1292L74.2589 87.226L85.1456 84.8782L83.8308 78.7813Z" fill="#F3F3F3" />
    <path d="M72.941 81.1301C72.941 81.1301 75.986 83.6781 79.367 82.7891C82.748 81.9001 83.826 78.7781 83.826 78.7781L72.941 81.1231V81.1301Z" fill="#E6EFE1" />
    <path d="M74.46 81.3402C74.124 81.3402 73.858 81.0672 73.858 80.7382C73.858 80.4092 74.131 80.1362 74.46 80.1362C74.789 80.1362 75.062 80.4092 75.062 80.7382C75.062 81.0672 74.789 81.3402 74.46 81.3402ZM74.46 80.3322C74.236 80.3322 74.054 80.5142 74.054 80.7382C74.054 80.9622 74.236 81.1442 74.46 81.1442C74.684 81.1442 74.866 80.9622 74.866 80.7382C74.866 80.5142 74.684 80.3322 74.46 80.3322Z" fill="#F3F3F3" />
    <path d="M83.322 81.5011C83.322 81.5011 83.322 82.3831 83.497 82.8101C83.665 83.2371 84.169 83.8041 84.337 83.8041C84.505 83.8041 84.183 82.9151 84.267 82.9361C84.351 82.9571 84.757 84.4131 85.051 84.4481C85.345 84.4831 85.611 82.8101 85.457 82.1521C85.303 81.4941 84.638 80.7031 84.638 80.7031L83.329 81.4941L83.322 81.5011Z" fill="#FFC173" />
    <path d="M75.566 56.5461C75.566 56.5461 78.268 56.9101 79.724 59.7801C80.851 61.9921 81.621 68.9571 82.412 71.1481C83.168 73.2481 84.218 77.3011 84.799 80.5491C84.841 80.8011 84.708 81.0531 84.47 81.1441L83.476 81.5361C83.259 81.6201 83.021 81.5361 82.902 81.3401C82.02 79.8561 78.541 73.9201 77.33 70.4901C75.916 66.4791 73.277 63.9801 73.277 63.9801L75.573 56.5391L75.566 56.5461Z" fill="#A7A7A7" />
    <path d="M74.264 80.2621C74.264 80.1641 73.627 70.2311 73.753 64.6521C73.816 61.7191 74.271 59.6541 74.635 58.4361C75.034 57.1061 75.636 56.5671 75.657 56.5391L75.86 56.6021C75.86 56.6021 74.18 59.1361 74.061 64.6591C73.942 70.2311 74.572 80.1431 74.572 80.2411L74.264 80.2621Z" fill="#F3F3F3" />
  </svg>
}