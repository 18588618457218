import { serviceRequest } from 'utils/http';
import { Service, SponsorPatientIDDto } from 'common/api';

export function updateReferralSponsorPatientIdAPI(
  sponsorPatientIDDto: SponsorPatientIDDto
) {
  return serviceRequest(() =>
    Service.referralControllerAddSponsorPatientId(sponsorPatientIDDto)
  );
}
