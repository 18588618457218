import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const mainReferralStatuses = {
  [ReferralStatus.NEW_REFERRAL]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.new_referral.title"
        defaultMessage="New Referral"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.new_referral.description"
        defaultMessage="Default - there is a new patient referral."
      />
    ),
  },

  [ReferralStatus.NEW_REFERRAL]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.new_referral.title"
        defaultMessage="New Referral"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.new_referral.description"
        defaultMessage="Default - there is a new patient referral."
      />
    ),
  },
  [ReferralStatus.ATTEMPTING_TO_CONTACT]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.attempting_to_contact.title"
        defaultMessage="Attempting to Contact"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.attempting_to_contact.description"
        defaultMessage="I am trying to contact the patient but haven't reached them yet."
      />
    ),
  },

  [ReferralStatus.RANDOMIZED]: {
    title: (
      <FormattedMessage
        id="is.referral_status.icf_signed_randomized.title"
        defaultMessage="Randomized"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.icf_signed_randomized.description"
        defaultMessage="Patient has been accepted into the clinical study."
      />
    ),
  },

  [ReferralStatus.APPOINTMENT_SCHEDULED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.contacted_appointment_scheduled.title"
        defaultMessage="Appointment scheduled"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.contacted_appointment_scheduled.description"
        defaultMessage="I have scheduled an appointment with the patient."
      />
    ),
  },

  [ReferralStatus.CONTACTED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.contacted.title"
        defaultMessage="Contacted"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.contacted.description"
        defaultMessage="I have contacted the patient."
      />
    ),
  },

  [ReferralStatus.CONSENTED]: {
    title: (
      <FormattedMessage
        id="is.referral_status.consented.title"
        defaultMessage="Consented"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.consented.description"
        defaultMessage="Patient has signed ICF and is in screening."
      />
    ),
  },

  [ReferralStatus.DROPPED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped.title"
        defaultMessage="Dropped"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.dropped.description"
        defaultMessage="The patient will not be considered for the clinical study."
      />
    ),
  },

  [ReferralStatus.DROPPED__UNABLE_TO_REACH]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_unable_to_reach.title"
        defaultMessage="Unable to reach"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_unable_to_reach.description"
        defaultMessage="I have been unable to reach the patient."
      />
    ),
  },
  [ReferralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_failed_in_exclusion_criteria.title"
        defaultMessage="Failed In-/Exclusion Criteria"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_failed_in_exclusion_criteria.description"
        defaultMessage="The patient has failed the exclusion criteria."
      />
    ),
  },
  [ReferralStatus.DROPPED__NOT_INTERESTED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_not_interested.title"
        defaultMessage="Not interested"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_not_interested.description"
        defaultMessage="The patient is not interested."
      />
    ),
  },
  [ReferralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_bad_or_duplicate_profile.title"
        defaultMessage="Bad or duplicate profile"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_bad_or_duplicate_profile.description"
        defaultMessage="The patient had a bad / duplicate profile."
      />
    ),
  },
  [ReferralStatus.DROPPED__TOO_FAR_AWAY]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_too_far_away.title"
        defaultMessage="Too far away"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_too_far_away.description"
        defaultMessage="The patient is too far away from the site."
      />
    ),
  },
  [ReferralStatus.DROPPED__NO_SHOW]: {
    title: (
      <FormattedMessage
        id="is.referral_status.dropped_no_show.title"
        defaultMessage="No show"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_no_show.description"
        defaultMessage="Patient missed their scheduled appointment."
      />
    ),
  },
  [ReferralStatus.DROPPED__OTHER]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped_other.title"
        defaultMessage="Other"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_otherdescription"
        defaultMessage="Any other reason."
      />
    ),
  },

  [ReferralStatus.WAITING_FOR_INFORMATION]: {
    title: (
      <FormattedMessage
        id="is.referral_status.waiting_information.title"
        defaultMessage="Waiting for information"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.waiting_information.description"
        defaultMessage="Patient visit was completed and waiting for patient to share information."
      />
    ),
  },

  [ReferralStatus.SCREENING_FAILURE]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.icf_signed_screening_failure.title"
        defaultMessage="Screening failure"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.screening_failure.description"
        defaultMessage="Patient did not meet one
         or more criteria after the screening visit.
         Results are formally confirmed by site."
      />
    ),
  },
};
