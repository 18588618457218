import React from 'react';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { RadioButton } from '@clariness/radio-button';
import { FormattedMessage } from 'react-intl';
import { Store } from 'store';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';
import { getDroppedReasons } from './studiesDroppedReasons';

export const DroppedReason: React.FC = () => {
  const { user } = Store.useUser();
  const { setSelectedDroppedReason, referral } =
    useUpdatePatientStatusContext();

  return (
    <UpdateStatusLayout heading="">
      <Flex
        gap={3}
        sx={{
          alignItems: 'center',
          height: '30px',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            border: '2px solid',
            borderRadius: '8px',
            padding: '16px 10px',
            flexShrink: 0,
            width: '100%',
            cursor: 'pointer',
            alignItems: 'center',
            gap: '5px',
            marginTop: '10px',
            borderColor: 'primary2',
            backgroundColor: 'primary-2',
          }}
        >
          <Text variant="pBold">
            <FormattedMessage
              id="is.update_referral_status.dropped_failed_in_exclusion_criteria.title"
              defaultMessage="Failed In-/Exclusion Criteria"
            />
          </Text>

          <Text variant="p">
            <FormattedMessage
              id="is.update_referral_status.dropped_failed_in_exclusion_criteria.description"
              defaultMessage="The patient has failed the exclusion criteria."
            />
          </Text>
        </Flex>
      </Flex>
      {getDroppedReasons(user?.languageCode, referral?.study?.id)?.map(
        (droppedReason) => (
          <Flex
            key={droppedReason.id}
            gap={3}
            sx={{
              alignItems: 'center',
              height: '30px',
              marginTop: '20px',
            }}
          >
            <RadioButton
              name="droppedReason"
              id={droppedReason.id}
              value={droppedReason.reason}
              onChange={(e) => {
                setSelectedDroppedReason(e.target.value);
              }}
            />
            <Text variant="p">{droppedReason.reason}</Text>
          </Flex>
        )
      )}
    </UpdateStatusLayout>
  );
};
