import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { parseBase64ToBlob } from 'utils/format';
import { downloadFile } from 'utils/document';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { downloadXlsAPI } from './api';

const DOWNLOAD_XLS_KEY = '@referrals/download-xls';

export function useDownloadXls() {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading } = ReactQuery.useMutation(
    DOWNLOAD_XLS_KEY,
    downloadXlsAPI,
    {
      onError: () => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.download_referral_xls_error',
            defaultMessage:
              'Unfortunately, something failed when exporting the referrals. Please, try again.',
          }),
        });
        captureEventV2(
          Events.InvestigatorService.InvestigatorBulkExport({
            InvestigatorID: 'huhu',
          })
        );
      },
      onSuccess: (data) => {
        const blob = parseBase64ToBlob(data, 'application/vnd.ms-excel');
        const blobUrl = URL.createObjectURL(blob);
        captureEventV2(
          Events.InvestigatorService.InvestigatorBulkExport({
            InvestigatorID: 'huhu',
          })
        );
        downloadFile(blobUrl, 'referrals.xls');
      },
    }
  );

  return {
    downloadXls: mutate,
    isLoading,
  };
}
