import React, { PropsWithChildren } from 'react';
import { Flex } from '@clariness/flex';
import { SidebarNavButton, SidebarNavLink } from './components';

export const Sidebar = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '298px',
        height: '100%',
        mt: '1px',
        py: '41px',
        backgroundColor: 'hgl6',
      }}
    >
      {children}
    </Flex>
  );
};

Sidebar.NavButton = SidebarNavButton;
Sidebar.NavLink = SidebarNavLink;
