import { FC } from 'react';
import { Text } from '@clariness/text';
import { Flex } from '@clariness/flex';
import { DiscardIcon } from '@clariness/icon';
import { ProductUpdateListItem } from 'components/ProductUpdates';
import { ProductUpdateDto } from 'common/api';
import { FormattedMessage } from 'react-intl';

type ProductUpdateListProps = {
  productUpdates: ProductUpdateDto[];
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};
export const ProductUpdateList: FC<ProductUpdateListProps> = ({
  productUpdates,
  open,
  setOpen,
  refetch,
}) => {
  if (!open) return null;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        gap: '24px',
        position: 'absolute',
        right: '32px',
        top: '104px',
        width: '465px',
        backgroundColor: 'hgl7',
        border: '0.5px solid',
        borderColor: 'hgl4',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        zIndex: 1000,
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Text variant="h4">
          <FormattedMessage id="is.whats_new" defaultMessage="What's new?" />
        </Text>
        <DiscardIcon
          onClick={() => setOpen(false)}
          sx={{ cursor: 'pointer' }}
        />
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '24px',
          height: '75vh',
          overflowY: 'auto',
          padding: '10px',
        }}
      >
        {productUpdates.map((productUpdate) => (
          <ProductUpdateListItem
            key={productUpdate.lokaliseKey}
            id={productUpdate.id ?? ''}
            lokaliseKey={productUpdate.lokaliseKey}
            fallbackTranslation={productUpdate.fallbackTranslation}
            read={productUpdate.read}
            refetch={refetch}
          />
        ))}
      </Flex>
    </Flex>
  );
};
