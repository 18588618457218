/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Protocol801ParticipationUpdateDto = {
    id?: string;
    outcome: Protocol801ParticipationUpdateDto.outcome;
    date: string;
    referralId: string;
}

export namespace Protocol801ParticipationUpdateDto {

    export enum outcome {
        CONSENTED_RANDOMIZED = 'CONSENTED_RANDOMIZED',
        CONSENTED_SCREEN_FAILIURE = 'CONSENTED_SCREEN_FAILIURE',
        DID_NOT_CONSENT = 'DID_NOT_CONSENT',
    }


}
