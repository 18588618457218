/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvestigatorDto } from './InvestigatorDto';

export type StudyCallbackTimeslotDto = {
    id?: number;
    start?: string;
    end?: string;
    status?: StudyCallbackTimeslotDto.status;
    callType?: StudyCallbackTimeslotDto.callType;
    investigator?: InvestigatorDto;
}

export namespace StudyCallbackTimeslotDto {

    export enum status {
        INACTIVE = 'INACTIVE',
        ACTIVE = 'ACTIVE',
    }

    export enum callType {
        OPT_IN = 'OPT_IN',
        TRAINING = 'TRAINING',
        FOLLOW_UP = 'FOLLOW_UP',
    }


}
