/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StudyExternalIdDto = {
    id?: number;
    externalId: string;
    externalIdType: StudyExternalIdDto.externalIdType;
}

export namespace StudyExternalIdDto {

    export enum externalIdType {
        CLINICAL_TRIALS_GOV_ID = 'CLINICAL_TRIALS.GOV_ID',
        EUDRACT_ID = 'EUDRACT_ID',
        DRKS_ID = 'DRKS_ID',
        CLIENT_STUDY_ID = 'CLIENT_STUDY_ID',
        CLIENT_STUDY_ACRONYM = 'CLIENT_STUDY_ACRONYM',
    }


}
