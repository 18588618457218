/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LanguageDto } from './LanguageDto';
import type { ProtocolDto } from './ProtocolDto';
import type { StudyExternalIdDto } from './StudyExternalIdDto';
import type { StudyIndicationDto } from './StudyIndicationDto';
import type { StudySiteStatusDto } from './StudySiteStatusDto';

export type StudyDto = {
    id?: string;
    name: string;
    studyType?: StudyDto.studyType;
    studyStatus: StudyDto.studyStatus;
    sites: Array<StudySiteStatusDto>;
    indications: Array<StudyIndicationDto>;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    countriesCodes: Array<string>;
    /**
     * Optimistic concurrency check
     */
    sourceVersion?: number;
    externalIds: Array<StudyExternalIdDto>;
    protocols: Array<ProtocolDto>;
    hasCallCenter?: boolean;
    language: Array<LanguageDto>;
    trackingId?: number;
}

export namespace StudyDto {

    export enum studyType {
        SUBSCRIPTION = 'SUBSCRIPTION',
        SUBSCRIPTION_VIRTUAL = 'SUBSCRIPTION_VIRTUAL',
        ACTIVE_RECRUITMENT = 'ACTIVE_RECRUITMENT',
        SURVEY = 'SURVEY',
    }

    export enum studyStatus {
        ACTIVE = 'ACTIVE',
        SETUP = 'SETUP',
        PAUSED = 'PAUSED',
        CLOSEOUT = 'CLOSEOUT',
        COMPLETED = 'COMPLETED',
    }


}
