import React, { useState } from 'react';
import { Services } from 'services';
import { BoxLoader } from 'components/loaders';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex } from '@clariness/flex';
import { TextField } from '@clariness/clarikit-react';
import { ServiceError } from 'components/ServiceError';
import { Button } from '@clariness/button';
import { Box } from '@clariness/box';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';
import { captureEventV2 } from 'utils/events/captureEvent';
import { validateString } from 'utils/validation/ValidateString';
import { usePatientDetailsContext } from '../../ReferralDetails';

export const PatientDetailsNotes: React.FC = () => {
  const { currentReferral } = usePatientDetailsContext();
  const { referral, isLoading, isError, refetch } =
    Services.Referrals.useGetReferral(currentReferral?.id);
  const { showNotification } = Store.useNotifications();
  const [note, setNote] = useState<string>('');
  const { user } = Store.useUser();
  const intl = useIntl();

  const { updateReferralNote, isLoading: isSavingReferralNote } =
    Services.Referrals.useUpdateReferralNote({
      onSuccess: () => setNote(''),
    });

  const handleSaveClick = () => {
    captureEventV2(
      Events.InvestigatorService.InvestigatorAddedNote({
        InvestigatorID: user?.id || '',
        SiteID: currentReferral?.site?.id || '',
        StudyID: currentReferral?.study?.id || '',
      })
    );
    if (validateString(note)) {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.update_referral_note_error',
          defaultMessage:
            "Unfortunately, something failed when updating the patient's notes. Please, try again.",
        }),
      });
      return;
    }

    updateReferralNote({ id: referral?.id, note });
  };

  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.notes.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's notes."
        />
      </ServiceError>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '120px',
        width: '85%',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <TextField
          label=""
          value={note || ''}
          placeholder={intl.formatMessage({
            id: 'is.patient_details_modal.notes.placeholder',
            defaultMessage: 'You can add your notes here.',
          })}
          onChange={(value) => setNote(value)}
          autoComplete="off"
        />
      </Flex>

      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '20px',
          py: '20px',
        }}
      >
        <Button
          size="small"
          variant="secondary"
          disabled={note === referral?.note}
          loading={isSavingReferralNote}
          onClick={() => handleSaveClick()}
        >
          <FormattedMessage
            id="is.patient_details_modal.notes.save_button"
            defaultMessage="Save"
          />
        </Button>
      </Flex>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </Box>
  );
};
