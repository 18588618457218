import * as ReactQuery from 'react-query';
import { InferMutationOptions, Services } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { updateReferralSponsorPatientIdAPI } from './api';

const UPDATE_SPONSOR_PATIENT_ID_KEY = '@referrals/update-sponsor-patient-id';

export function useUpdateReferralSponsorPatientId(
  options?: InferMutationOptions<typeof updateReferralSponsorPatientIdAPI>
) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    UPDATE_SPONSOR_PATIENT_ID_KEY,
    updateReferralSponsorPatientIdAPI,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueriesData(
          [Services.Referrals.KEYS.GET_REFERRAL_KEY, variables.referralId],
          (currentData: any) => ({
            ...currentData,
            sponsorPatientID: variables.sponsorPatientID,
          })
        );
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'server.success.update',
            defaultMessage: 'Data saved successfully.',
          }),
        });
      },
      onError: (error, data, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.update_account_details',
            defaultMessage:
              'Unfortunately, an error occurred while we tried to save your updates. Please try again.',
          }),
        });

        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
    }
  );

  return {
    updateReferralSponsorPatientIdAPI: mutate,
    isLoading,
    isError,
  };
}
